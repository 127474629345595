import React from "react";
import {EditScriptStepState} from "../../data-types";
import {Box, IconButton, Typography, Paper, Grid, Tooltip, FormControlLabel, Checkbox} from "@mui/material";
import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from "@mui/lab";
import {DataFormatting} from "../../util";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface EditScriptBuilderStepProps {
    step: EditScriptStepState;
    removeByPosition: (newPosition: number) => void;
    totalSteps: number;
    swapPosition: (oldPosition: number, delta: number) => void;
    toggleStepSkippable: (newPosition: number) => void;
}

export function EditScriptBuilderStep({step, removeByPosition, totalSteps, swapPosition, toggleStepSkippable}: EditScriptBuilderStepProps) {


    const p = step.newPosition === null ? NaN : step.newPosition;
    if (isNaN(p)) {
        return <></>
    } else {
        let img = "";
        if (step.video) {
            let imageSrc = step.video.sources.find(s => s.type === "IMAGE/JPG");
            img = imageSrc?.src ?? "";
        }

        return (
            <TimelineItem position={p % 2 === 0 ? "left" : "right"}>
                <TimelineOppositeContent>
                    <Typography variant="h6" sx={{mb: 5}}>
                        {DataFormatting.intToOrdinal(p)}
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tooltip
                                title="Remove"
                            >
                                <IconButton
                                    onClick={() => removeByPosition(p)}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Move Up">
                            <span>
                                <IconButton
                                    disabled={p === 1}
                                    onClick={() => swapPosition(p, -1)}
                                >
                                    <ArrowUpwardIcon />
                                </IconButton>
                            </span>
                            </Tooltip>
                            <Tooltip title="Move Down">
                            <span>
                                <IconButton
                                    disabled={p === totalSteps}
                                    onClick={() => swapPosition(p, 1)}
                                >
                                    <ArrowDownwardIcon />
                                </IconButton>
                            </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip
                                title="Checking this will make this prompt optional to complete."
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!step.isSkippable}
                                            disabled={!step.isDecisionScriptStep}
                                            onChange={() => toggleStepSkippable(p)}
                                            name="isSkippable"
                                        />
                                    }
                                    label="Required?"
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{my: 5}}>
                    <Paper elevation={1} sx={{p: 2}}>
                        <Grid container direction={p % 2 === 0 ? "row" : "row-reverse"}>
                            {step.isDecisionScriptStep ?
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" noWrap sx={{mb: 1}}>
                                            Optional Prompt
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Users will be able to select their own video prompt at this stage.
                                        </Typography>
                                    </Grid>
                                </> :
                                <>
                                    <Grid item xs={12} sm={6} order={{xs: 2, sm: 1}}>
                                        <Typography variant="subtitle1" noWrap sx={{mb: 1}}>
                                            {step.video?.name ?? "Optional Prompt"}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {`Created by:  ${step.video?.createdBy ?? "N/A"}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} order={{xs: 1, sm: 2}}>
                                        <Box
                                            sx={{width: "80%"}}
                                            component="img"
                                            alt="video-placeholder"
                                            src={img}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }
}