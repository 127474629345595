import React from "react";
import {Paper, Container, Grid, Typography, Avatar, Button} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useParams} from "react-router-dom";
import {LoadingSpinner} from "../../components";
import {useAvatarUpload, useReviewGroupDetail} from "../../hooks";
import {userColDefs, candidateColDefs} from "./TableColDefs";

export function AdminReviewGroupsDetail() {
    const {reviewGroupId} = useParams();
    const {avatarUrl, handleAvatarChange} = useAvatarUpload(`/api/avatar/${reviewGroupId}/createReviewGroupAvatar.json`, "reviewGroup");
    const {loading, reviewGroupDetail} = useReviewGroupDetail(reviewGroupId);

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <Container sx={{mb: 10}}>
            <Grid container sx={{mb: 3}}>
                <Grid item xs={0} sm={2} />
                <Paper component={Grid} item xs={12} sm={8} container sx={{p: 3}} alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="h4">
                            {reviewGroupDetail.groupName}
                        </Typography>
                    </Grid>
                    <Grid container item xs={6} alignItems="center" direction="column">
                        <Avatar
                            src={avatarUrl !== "" ? avatarUrl : `data:image/jpeg;base64,${reviewGroupDetail.groupAvatar}`}
                            sx={{height: 100, width: 100}}
                            imgProps={{alt: "group avatar"}}
                        />
                        {reviewGroupId ?
                            <Button
                                variant="contained"
                                component="label"
                                color="primary"
                                size="small"
                                sx={{mt: 3}}
                            >
                                Change Avatar
                                <input
                                    style={{display: "none"}}
                                    accept="image/*"
                                    type="file"
                                    id="review-group-avatar"
                                    onChange={handleAvatarChange}
                                />
                            </Button> :
                            null
                        }
                    </Grid>
                </Paper>
                <Grid item xs={0} sm={2} />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" sx={{textAlign: 'center', mb: 4}}>
                        {`Users (count:  ${reviewGroupDetail.countUsers})`}
                    </Typography>
                    <DataGrid
                        columns={userColDefs}
                        rows={reviewGroupDetail.groupUsers}
                        getRowId={(row) => row.userId}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: {
                                    hideToolbar: true,
                                    pageStyle: ".MuiDataGrid-main {color: #000;}"
                                }
                            }
                        }}
                        initialState={{pagination: {paginationModel: {pageSize: 10}}}}
                        pageSizeOptions={[10, 25, 100]}
                        autoHeight={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" sx={{textAlign: 'center', mb: 4}}>
                        {`Candidates (count:  ${reviewGroupDetail.countCandidates})`}
                    </Typography>
                    <DataGrid
                        columns={candidateColDefs}
                        rows={reviewGroupDetail.groupCandidates}
                        getRowId={(row) => row.candidateId}
                        initialState={{pagination: {paginationModel: {pageSize: 10}}}}
                        pageSizeOptions={[10, 25, 100]}
                        autoHeight={true}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}