import React from "react";
import {Container, Divider, Grid, Paper, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {useAdminOptionalScriptDetail} from "../../hooks";
import {LoadingSpinner, ErrorComponent} from "../../components";
import {SxProps} from "@mui/material/styles";
import {OptionalStepCard} from "./OptionalStepCard";
import {InstitutionGrouping} from "./InstitutionGrouping"

export function AdminOptionalScriptDetail() {

    const {roundId, scriptStepId} = useParams();
    const {
        requestStatus,
        optionalScriptStepDetail
    } = useAdminOptionalScriptDetail(roundId, scriptStepId);

    const metaStyle: SxProps = {py: 3, textAlign: "center", minHeight: 110};

    // group prompts by institutionId, name, prompts
    const institutionGrouping: InstitutionGrouping[] = [];
    for (const p of optionalScriptStepDetail.prompts) {
        // find grouping
        let ig = institutionGrouping.find(c => c.institutionId === p.institutionId);
        if (ig) {
            ig.prompts.push(p);
        } else {
            institutionGrouping.push({institutionId: p.institutionId, institutionName: p.institutionName, prompts: [p]});
        }
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Optional Prompt Detail
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {`Event name:  ${optionalScriptStepDetail.competitionName}`}
                        </Typography>
                        <Typography variant="h6">
                            {`Round #${optionalScriptStepDetail.roundNumber}`}
                        </Typography>
                        {optionalScriptStepDetail.scriptId === -1 || optionalScriptStepDetail.scriptId === undefined ?
                            <Typography variant="h6">
                                {`Script:  ${optionalScriptStepDetail.scriptName}`}
                            </Typography> :
                            <Typography variant="h6" component={Link} to={`/admin/scripts/detail/${optionalScriptStepDetail.scriptId}`} sx={{color: 'white'}}>
                                {`Script:  ${optionalScriptStepDetail.scriptName}`}
                            </Typography>
                        }
                    </Grid>
                    <Grid container item xs={12} spacing={2} alignItems="center" sx={{mb: 5}}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={1} sx={metaStyle}>
                                <Typography variant="h6">
                                    {optionalScriptStepDetail.prompts.length}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Prompts
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={1} sx={metaStyle}>
                                <Typography variant="h6">
                                    {`${optionalScriptStepDetail.completedResponses} / ${optionalScriptStepDetail.dueResponses}`}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Completed Responses
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {institutionGrouping.map((c, i) => <OptionalStepCard key={i} institutionGrouping={c} roundId={roundId} />)}
            </Container>
        )
    }
}