import React, {useState} from "react";
import {
    Grid,
    Box,
    Button,
    Container,
    Typography,
    TextField,
    FormGroup,
    Tooltip,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import {useRecord, useVideoPromptCreate} from "../../hooks/";
import AlarmIcon from "@mui/icons-material/Alarm";
import {SubmitResponseModal, Webcam} from "../../components";
import {VideoSource} from "../../data-types";
import {toast} from "react-toastify";
import {formatError, DataFormatting} from "../../util";
import {useNavigate} from "react-router-dom";

export function AdminRecordVideoPrompt() {

    const navigate = useNavigate();
    const {
        time,
        startRecording,
        stopRecording,
        resetRecording,
        isRecording,
        isDataAvailable,
        recordingData
    } = useRecord();
    const {
        promptState,
        handleTextChange,
        handleCheckboxChange,
        createVideoPrompt,
        uploadProgress
    } = useVideoPromptCreate();
    const [recordedVideoSource, setRecordedVideoSource] = useState<VideoSource>({src: "", type: ""});
    const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);

    const setRecordingSource = () => {
        if (recordedVideoSource.src !== "") {
            URL.revokeObjectURL(recordedVideoSource.src);
        }
        const mimeType = DataFormatting.getRecordingMimeType();
        const video = new Blob(recordingData.current, {type: mimeType});
        const url = URL.createObjectURL(video);
        setRecordedVideoSource({src: url, type: mimeType.split(";")[0]});
    }

    const handleSubmit = async () => {
        try {
            const mimeType = DataFormatting.getRecordingMimeType();
            await createVideoPrompt(promptState, {
                recordingData: recordingData.current,
                isRecording: false,
                isStopped: true,
                time: time,
                mimeType: mimeType});
            navigate("/admin/videoPrompts");
        } catch(e) {
            console.log(e);
            toast.error(formatError(e));
        }
    }

    return (
        <Container sx={{mb: 10}}>
            <Grid container spacing={2}>
                <Grid item xs={0} sm={0} md={2} />
                <Grid item xs={12} sm={12} md={8}>
                    <Box component="div" sx={{textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", p: 3}}>
                        <AlarmIcon />
                        <Typography variant="body1" display="inline" sx={{ml: 1}}>
                            {"Recording Length:  " + DataFormatting.secondsToTimeString(time)}
                        </Typography>
                    </Box>
                    <Webcam
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                        resetRecording={resetRecording}
                        isRecording={isRecording}
                        isDataAvailable={isDataAvailable}
                    />
                </Grid>
                <Grid item xs={0} sm={0} md={2} />
                <Grid item xs={0} sm={0} md={2} />
                <Grid item xs={12} sm={12} md={8} sx={{mt: 5}}>
                    <TextField
                        required
                        label="Prompt Name"
                        fullWidth={true}
                        name="promptName"
                        value={promptState.promptName}
                        inputProps={{size: 40}}
                        onChange={handleTextChange}
                    />
                    <FormGroup>
                        <Tooltip
                            title="If you make this video public, all other admins will be able to view it and use it after you click submit."
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={promptState.isPublic}
                                        onChange={handleCheckboxChange}
                                        name="isPublic"
                                    />
                                }
                                label="Make public?"
                            />

                        </Tooltip>
                    </FormGroup>
                    <Box sx={{textAlign: "center", mt: 3}} component="div">
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={isRecording || !isDataAvailable || promptState.promptName.length === 0}
                            onClick={() => {
                                setRecordingSource();
                                setSubmitModalOpen(true);
                            }}
                        >
                            Review and Save
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={0} sm={0} md={2} />
            </Grid>
            <SubmitResponseModal
                videoSource={recordedVideoSource}
                open={submitModalOpen}
                setOpen={setSubmitModalOpen}
                action={handleSubmit}
                handlePlayerReady={() => {}}
                loading={false}
            />
        </Container>
    )
}