import React from "react";
import {CompetitionMeta, UserStatistics} from "../../data-types";
import {Card, CardContent, CardHeader, Box, Avatar, Grid, Typography, Button, Chip, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import {RankIcon} from "../../icons";
import ReplyIcon from "@mui/icons-material/Reply";

interface CompletedEventCardProps {
    competitionMeta: CompetitionMeta;
    userStatistics: UserStatistics | null;
    position?: number;
}

export function CompletedEventCard({competitionMeta, userStatistics, position}: CompletedEventCardProps) {
    const theme = useTheme();

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar>{position}</Avatar>
                }
                title={competitionMeta.name}
                titleTypographyProps={{
                    variant: "h5",
                    noWrap: true
                }}
                subheader={competitionMeta.paymentParticipant ?
                    `Event fee: $${(competitionMeta.paymentParticipant / 100).toFixed(2)}` :
                    ""
                }
            />
            <CardContent sx={{pt: 0}}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} md={7} justifyContent="space-around" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{pl: 7}}>{competitionMeta.description}</Typography>
                            {competitionMeta.paymentParticipant ?
                                <Button
                                    sx={{ml: 7}}
                                    startIcon={<ReplyIcon />}
                                    component={Link}
                                    variant="outlined"
                                    size="small"
                                    color="info"
                                    to={`/receipt/${competitionMeta.competitionId}`}
                                >
                                    Receipt
                                </Button> :
                                null
                            }
                            <Box component="div" sx={{mt: 2, pl: 7}}>
                                {competitionMeta.tags.map((c, i) => (
                                        <Chip
                                            sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                            variant="filled"
                                            label={c.name}
                                            key={i}
                                            size="small"
                                        />
                                    )
                                )}
                            </Box>
                        </Grid>
                        <Grid item container spacing={2} sx={{mt: 2}}>
                            <Grid item xs={6}>
                                <Box component="div" sx={{textAlign: 'center'}}>
                                    <Typography variant="body1">
                                        Score
                                    </Typography>
                                    <Typography variant="h6" component="span">
                                        {userStatistics ? userStatistics.currentScore.toFixed(2) : "N/A"}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box component="div" sx={{textAlign: 'center'}}>
                                    <Typography variant="body1">
                                        Rank
                                    </Typography>
                                    <RankIcon rank={userStatistics ? userStatistics.percentile : 0.5} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} order={{xs: 3, md: 2}}>
                        <Button
                            variant="outlined"
                            color="info"
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/myResults/${competitionMeta.competitionId}/reviewsOfMe`}
                        >
                            Reviews of Me
                        </Button>
                        <Button
                            variant="outlined"
                            color="info"
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/myResults/${competitionMeta.competitionId}/results`}
                        >
                            My Results
                        </Button>
                        <Button
                            variant="outlined"
                            color="info"
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/myResults/${competitionMeta.competitionId}/rankings`}
                        >
                            Current Rankings
                        </Button>
                        <Button
                            variant="outlined"
                            color="info"
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/leaders/${competitionMeta.competitionId}`}
                        >
                            View Leaders
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}