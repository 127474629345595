import React from "react";
import {Avatar, Grid, Paper, Typography, Button, Divider} from "@mui/material";
import {AdminReviewData} from "../../data-types";
import ErrorIcon from '@mui/icons-material/Error';
import {Link} from "react-router-dom";
import {DataFormatting} from "../../util";

interface ReviewCardProps {
    review: AdminReviewData;
    responseId: string | undefined;
}
export function ReviewCard({review, responseId}: ReviewCardProps) {

    return (
        <Paper elevation={1}>
            <Grid container spacing={2} sx={{mb: 5, p: 3}}>
                <Grid item xs={2} sm={1} md={1}>
                    {/* Avatar   */}
                    <Avatar
                        src={review.reviewerAvatar ? `data:image/jpeg;base64,${review.reviewerAvatar }` : ""}
                        imgProps={{alt: `${review.reviewerUsername}'s Avatar`}}
                    />
                </Grid>
                <Grid item xs={10} sm={11} md={5}>
                    {/*  Username */}
                    <Typography variant="h5" sx={{mb: 2}}>
                        {review.reviewerUsername}
                    </Typography>
                    <Typography variant="h6" sx={{mb: 2}}>
                        {`Score:  ${review.rawScore ?? "Not Available"}`}
                    </Typography>
                    <Typography variant="body1">
                        {`RPS:  ${review.rps ? DataFormatting.intToOrdinal(review.rps) : "Not Available"}`}
                    </Typography>
                    <Typography variant="body1">
                        {`RPR:  ${review.rpr ? review.rpr.toFixed(2) : "Not Available"}`}
                    </Typography>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} sx={{textAlign: "center"}}>
                    <Grid item xs={12} sx={{textAlign: {xs: "left", sm: "left", md: "right"}, mb: 3}}>
                        {review.isReported ?
                            <Button
                                variant="contained"
                                color="secondary"
                                component={Link}
                                to={`/admin/report/detail/${responseId}`}
                                startIcon={<ErrorIcon />}
                            >
                                View Reports
                            </Button> :
                            null
                        }
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "left"}}>
                        <Typography variant="h6">
                            Attributes
                        </Typography>
                        <Divider sx={{my: 2}} />
                    </Grid>
                    {review.attributeScores ?
                        review.attributeScores.map((c, i) =>
                            <Grid key={i} item xs={6} sm={4} sx={{p: 2, textAlign: "center"}}>
                                <Paper elevation={0}>
                                    <Typography variant="h6">
                                        {c.attributeScore}
                                    </Typography>
                                    <Typography variant="h6">
                                        {c.attributeName}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ) :
                        <Grid item xs={12} sx={{textAlign: "center"}}>
                            <Paper elevation={0} sx={{p: 2}}>
                                <Typography variant="subtitle2">
                                    No attribute scores available
                                </Typography>
                            </Paper>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={0} sm={0} md={1} />
                <Grid item xs={12} sm={12} md={11}>
                {/*  Comments  */}
                    <Typography variant="subtitle2">
                        {review.comments && review.comments.length > 0 ?
                            `Comments:  ${review.comments}` :
                            "No comments to show."
                        }
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}