import React from "react";
import {LeaderCard} from "../leader-card";
import {Grid, Button, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {RankingsLeadersProps} from "../../data-types";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export function RankingsLeaders({roundRanks, competitionId}:  RankingsLeadersProps) {
    // get the last round, this component always lists
    // the current leaders
    // const lastRoundIndex = roundRanks.length - 1;
    const lastRoundWithRanks = roundRanks.reduce((a, c, ci) => {
        return (c.userRanks.length !== 0) ? ci : a
    }, -1);

    if (lastRoundWithRanks === -1) {
        return (
            <Grid container>
                <Grid item xs={12} sx={{mt: 5}}>
                    <Paper elevation={1} sx={{textAlign: "center", p: 3}}>
                        <Typography variant="subtitle2">
                            There are no rankings available right now.  Check back later when the first round ends.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        )
    } else {
        const round = roundRanks[lastRoundWithRanks];
        const showTrend = roundRanks.length > 1;
        const [first, second, third] = round.userRanks;

        return (
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={4} order={{xs: 2, sm: 1}}>
                    {second ?
                        <LeaderCard
                            place="2nd"
                            user={second}
                            trend={showTrend}
                        /> :
                        null
                    }
                </Grid>
                <Grid item xs={12} sm={4} order={{xs: 1, sm: 2}}>
                    {first ?
                        <LeaderCard
                            place="1st"
                            user={first}
                            trend={showTrend}
                        /> :
                        null
                    }
                </Grid>
                <Grid item xs={12} sm={4} order={{xs: 3, sm: 3}}>
                    {third ?
                        <LeaderCard
                            place="3rd"
                            user={third}
                            trend={showTrend}
                        /> :
                        null
                    }
                </Grid>
                <Grid item xs={12} container order={{xs: 4, sm: 4}}>
                    <Grid item xs={12} sx={{mt: 3, textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={`/leaders/${competitionId}`}
                            startIcon={<OpenInNewIcon />}
                        >
                            See Leaders' Responses
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
