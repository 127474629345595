import {UserProfileState} from "../../data-types";

export const DataValidation = {

    zipCodeRegex: /^\d{5}(?:[-\s]\d{4})?$/,

    emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,

    isUserProfileIncomplete: function(user: UserProfileState): boolean {
        return user.ageBracket === null ||
            !user.regionOrCountry ||
            !user.primaryLanguage ||
            !user.mailingZip ||
            !user.highSchoolZip ||
            user.usResident === null
    },

    isValidZipCode: function(zipCode: string): boolean {
        return this.zipCodeRegex.test(zipCode);
    },

    isValidEmailAddress:  function(email: string): boolean {
        return this.emailRegex.test(email);
    }
}