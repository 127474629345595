import React, {useEffect, useRef} from "react";
import videojs, {VideoJsPlayerOptions} from "video.js";
import "video.js/dist/video-js.css";
import {VideoProps} from "../../data-types";
import {Box} from "@mui/material";
import DetectRTC from "detectrtc";
import {DataFormatting} from "../../util";

// NOTE:  videojs, for whatever reason, will completely destroy the
// html video element on calling dispose.  In production, this is fine.
// But under React.StrictMode effects are run twice, this will cause
// dispose to be called on a functioning element, removing the video
// element from the DOM entirely.  Subsequently trying to set up the
// player will fail since the reference is now invalid.

// Since options prop is an object not passed by reference, React will re-render on
// any parent state change unless you supply a custom arePropsEqual function.
// This function only checks whether the video URLs (options.sources) and transcript
// tracks (options.tracks) are equivalent
function arePropsEqual(oldProps: VideoProps, newProps: VideoProps): boolean {
    // the ONLY reasons to re-render this component should be if the sources
    // key in VideoJsOptions changes OR the transcript track changes
    const {options: oldOptions} = oldProps;
    const {options: newOptions} = newProps;
    const oldSources = oldOptions ? oldOptions.sources || [] : [];
    const newSources = newOptions ? newOptions.sources || [] : [];
    const oldTracks = oldOptions ? oldOptions.tracks || [] : [];
    const newTracks = newOptions ? newOptions.tracks || [] : [];

    // are sources equal?
    const zippedSources = DataFormatting.zip(oldSources, newSources);
    const sourcesEqual = zippedSources.reduce<boolean>((p, c) => {
        const [i, j] = c;
        const iSrc = i?.src;
        const jSrc = j?.src;
        return p && iSrc === jSrc;
    }, true);

    // are tracks equal?
    const zippedTracks = DataFormatting.zip(oldTracks, newTracks);
    const tracksEqual = zippedTracks.reduce<boolean>((p, c) => {
        const [i, j] = c;
        const iSrc = i?.src;
        const jSrc = j?.src;
        return p && iSrc === jSrc;
    }, true)

    // if both tracks and sources are same, don't re-render
    return sourcesEqual && tracksEqual;
}

export const VideoPlayer = React.memo(function ({options, handlePlayerReady, height}: VideoProps) {
    const player = useRef<videojs.Player | null>(null);
    const playerContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const browserConfig = (options: VideoJsPlayerOptions) => {
            const browserName = DetectRTC.browser.name;
            const os = DetectRTC.osName;
            if (browserName === "Safari" || os.includes("iOS")) {
                // filter out non-mp4 version
                const sources = options.sources || [];
                return {
                    ...options,
                    sources: sources.filter(c => c.type?.toString().toUpperCase().includes("MP4"))
                }
            } else {
                return options;
            }
        }

        if (!player.current && options) {
            const playerEl = playerContainer.current;

            // video-js will destroy the video element in the DOM outside
            // the normal React lifecycle.  Therefore, re-append a video
            // element to the container element and use this as the
            // video reference required for first arg of videojs()
            const browserOptions = browserConfig(options);
            const videoEl = playerEl!.appendChild(
                document.createElement("video-js")
            );
            videoEl.setAttribute('class', 'video-js vjs-big-play-centered');
            player.current = videojs(
                videoEl,
                browserOptions,
                () => {
                    handlePlayerReady && handlePlayerReady(player.current!);
                })
        }

        return () => {
            if (player.current) {
                player.current?.dispose();
                player.current = null;
            }
        }
    }, [options, handlePlayerReady])

    let h = height ?? 420;

    return (
        <Box sx={{height: h, width: '100%'}} ref={playerContainer} component="div" />
    )
}, arePropsEqual)