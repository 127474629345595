import React from "react";
import {Modal, Box, Grid, Typography, Divider, Paper, Button} from "@mui/material";
import {ModalProps, OptionalScriptStepConfig, InstitutionSelections} from "../../data-types";
import {InstitutionSelectCard} from "./InstitutionSelectCard";

interface OptionalStepRoundModalProps extends ModalProps {
    optionalScriptSteps: OptionalScriptStepConfig[];
    handleInstitutionSelect: (institutionId: number | null, scriptStepId?: string) => void;
    institutionSelections: InstitutionSelections;
}
export function OptionalStepRoundModal({open, setOpen, action, optionalScriptSteps, handleInstitutionSelect, institutionSelections}: OptionalStepRoundModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={style} component="div">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Institution Selection
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={1} sx={{p: 2, mb: 3}}>
                            <Typography variant="subtitle2">
                                Please select an institution from the list below.  These institutions have provided video prompts for you to respond to.
                            </Typography>
                        </Paper>
                    </Grid>
                    {optionalScriptSteps.map((c, i) => (
                        <Grid item container xs={12} key={i} sx={{mb: 3}} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {`Prompt ${c.position}`}
                                </Typography>
                                <Divider sx={{my: 2}} />
                            </Grid>
                            {c.institutions.map((cc, ii) => (
                                <Grid item xs={12} sm={6} md={4} key={ii}>
                                    <InstitutionSelectCard
                                        institutionName={cc.institutionName}
                                        institutionAvatar={null}
                                        scriptStepId={c.scriptStepId.toString()}
                                        institutionId={cc.institutionId}
                                        handleSelect={handleInstitutionSelect}
                                        selected={institutionSelections[c.scriptStepId] === cc.institutionId}
                                    />
                                </Grid>
                            ))}
                            {c.isSkippable ?
                                <Grid item xs={12} sm={6} md={4}>
                                    <InstitutionSelectCard
                                        institutionName="Skip Prompt"
                                        institutionAvatar={null}
                                        scriptStepId={c.scriptStepId.toString()}
                                        institutionId={null}
                                        handleSelect={handleInstitutionSelect}
                                        selected={institutionSelections[c.scriptStepId] === null}
                                    />
                                </Grid> :
                                null
                            }
                        </Grid>
                    ))}
                    <Grid container item xs={12} sx={{mt: 5}}>
                        <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => action()}
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    handleInstitutionSelect(NaN);
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
