import {GridColDef} from "@mui/x-data-grid";
import {Avatar, IconButton} from "@mui/material";
import {Link} from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const userColDefs: GridColDef[] = [
    {field: 'avatar', width: 75, headerName: "",
        renderCell: (params) => {
            return (
                <Avatar
                    src={`data:image/jpeg;base64,${params.row.avatar}`}
                    imgProps={{alt: "user avatar"}}
                />
            )
        }
    },
    {field: 'name', headerName: 'Name', flex: 1,
        valueGetter: (params) => {
            return params.row.firstName + " " + params.row.lastName
        }
    }, // firstName + " " + lastName
    {field: 'username', headerName: "Username", flex: 1 },
    {field: 'link', headerName: "Detail", headerAlign: "right", align: "right", flex: 1,
        renderCell: (params) => {
            return (
                <IconButton
                    component={Link}
                    to={`/admin/userDetail/${params.row.userId}`}
                >
                    <OpenInNewIcon />
                </IconButton>
            )
        }
    }
]
export const candidateColDefs: GridColDef[] = [
    {field: 'email', flex: 1, headerName: "Email"},
    {field: 'link', headerName: "Detail", headerAlign: "right", align: "right", flex: 1,
        renderCell: (params) => {
            return (
                <IconButton
                    component={Link}
                    to={`/admin/candidateDetail/${params.row.candidateId}`}
                >
                    <OpenInNewIcon />
                </IconButton>
            )
        }
    }
];
