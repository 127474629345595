import React from "react";
import {Grid, Container, Card, CardContent, CardActionArea, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export function Analytics() {

    return (
        <Container>
            <Typography variant="h3" component="h1" sx={{mb: 8}}>
                Analytics
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardActionArea component={Link} to={"/perception-cube"}>
                            <CardContent>
                                <Typography variant="h4">
                                    <Typography variant="inherit" display="inline" color="primary">*DEMO*</Typography> Perception Cube
                                </Typography>
                                <Typography variant="body1">
                                    See a demo of the Perception Cube using arbitrary data.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}