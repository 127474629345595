import React from "react";
import {Modal, Box, Grid, TextField, Typography, Divider, Button} from "@mui/material";
import {ShareModalProps} from "../../data-types";

export function ShareModal({open, setOpen, action, value, handleChange}: ShareModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style} component="div">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Share this Event
                        </Typography>
                        <Typography variant="subtitle2">
                            Enter an email address of an admin to share with.
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Share with admin"
                            fullWidth
                            name="emailToShareWith"
                            value={value}
                            inputProps={{size: 40}}
                            onChange={handleChange}
                            sx={{mb: 3}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => action()}
                        >
                            Share
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}