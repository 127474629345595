import {useCallback} from "react";
import {DataAccess} from "../../util";
export const useTokenizedUrl = () => {

    const getTokenizedUrl = useCallback(async () => {
        // https://collegenet.atlassian.net/wiki/spaces/S25Dev/pages/627179759/knowledgeowl+and+token+request
        const tokenRequestPayload = {
            "token_request": {
                "token_id": "admin-focusring",
                "remote_system": "knowledgeowl",
                "token_type": "remote"
            }
        };
        const response = await DataAccess.post("/api/token_request.json", {data: tokenRequestPayload});
        const {base_url, token} = response.token_request;
        return "https://" + base_url + "/help/remote-auth?n=" + token;
    }, []);

    return {
        getTokenizedUrl: getTokenizedUrl
    }
}