import {useState, useEffect, useCallback} from "react";
import {DataAccess} from "../../util";
import {RequestStatus} from "../../data-types";

type PageCache<T> = {
    [key: number]: T[];
}
export const usePagination = <T = any>(pageQuery: string, pageSize: number = 6) => {
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(1);
    const [pages, setPages] = useState<PageCache<T>>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<string>("");
    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");

    useEffect(() => {
        const init = async () => {
            const query = pageQuery.replace("PAGE_NUMBER", "1") + queryParams;
            const {paging: response} = await DataAccess.get(query);
            setPages(prev => {
                return {
                    ...prev, 1: response.pagingData
                }
            });
            setTotalRecords(response.recordCount);
            setLoading(false);
        }

        init()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(_ => {
                setRequestStatus("error");
            });

    }, [pageQuery, queryParams]);

    const changePage = useCallback(async (pageNumber: number) => {

        if (!(pageNumber in pages)) {
            setLoading(true);
            const query = pageQuery.replace("PAGE_NUMBER", pageNumber.toString()) + queryParams;
            const {paging: response} = await DataAccess.get(query);
            setPages(prev => {
                return {
                    ...prev, [pageNumber]: response.pagingData
                }
            })
        }
        setCurrentPageNumber(pageNumber);
        setLoading(false);
    }, [pages, pageQuery, queryParams]);

    const modifyQueryParams = useCallback(async (params: string) => {
        setQueryParams(params);
    }, []);

    return {
        currentPageNumber: currentPageNumber,
        pages: pages,
        totalRecords: totalRecords,
        changePage: changePage,
        modifyQueryParams: modifyQueryParams,
        loading: loading,
        requestStatus: requestStatus
    }
}