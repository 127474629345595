import React from "react";
import {Grid, Box, Paper, Typography, Link, FormGroup, FormControlLabel, Checkbox, Badge} from "@mui/material";
import {VideoReviewContent} from "./VideoReviewConfig";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {MovieLabeledIcon, PersonLabeledIcon} from "../../icons/labeled-icons";

interface ResponseButtonProps {
    content: VideoReviewContent;
    handleHideReview: (content: VideoReviewContent) => void;
    label: string;
}

export function ResponseButton({content, handleHideReview, label}: ResponseButtonProps) {

    const currentScore = content.review?.rawScore ? `${content.review.rawScore} / 10` : "Unrated";
    const isReported = content.review?.isReported;
    const icon = (isReported) ?
        <CancelIcon fontSize="small" color="secondary" /> :
        (content.saved) ?
            <CheckCircleIcon fontSize="small" color="primary" /> :
            null;

    return (
        <Grid item>
            <Badge badgeContent={" "} color="warning" variant="dot" invisible={!content.modified}>
                <Paper component={Link} href={`#review-${content.reviewId}`} elevation={1} sx={{p: 1, textDecoration: "none"}}>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                        <Grid item xs={2}>
                            {isNaN(parseInt(label)) ? <PersonLabeledIcon label={label} accessibilityText={"Responder " + label} /> : <MovieLabeledIcon label={label} accessibilityText={"Prompt " + label} />}
                        </Grid>
                        <Grid item xs={9}>
                            <Box component="div" sx={{display: "flex", alignItems: "center", mt: 1, justifyContent: "center"}}>
                                {icon}
                                {isReported ?
                                    <Typography variant="subtitle2" sx={{ml: 1}}>
                                        Reported
                                    </Typography> :
                                    <Typography variant="subtitle2" sx={{ml: 1}}>
                                        {`${currentScore}`}
                                    </Typography>
                                }
                            </Box>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{ml: 1}}
                                    control={
                                        <Checkbox
                                            inputProps={{
                                                'aria-label': `Hide-review-${content.reviewId}`
                                            }}
                                            checked={content.hidden || isReported}
                                            disabled={isReported}
                                            onChange={() => handleHideReview(content)}
                                            sx={{transform: "scale(0.70)"}}
                                        />
                                    }
                                    label={
                                        <Typography variant="subtitle2" sx={{fontSize: 10}}>
                                            Hide
                                        </Typography>
                                    }
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Paper>

            </Badge>
        </Grid>
    )
}