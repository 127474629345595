import React, {useState} from "react";
import {
    Box, OutlinedInput, InputLabel, MenuItem,
    FormControl, Select, SelectChangeEvent, Chip
} from "@mui/material";
import {MultiselectFormProps} from "../../data-types";

export function FormMultiselect({selections, label, parentHandler}: MultiselectFormProps) {

    const [selection, setSelection] = useState<string[]>([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = (event: SelectChangeEvent<typeof selection>) => {
        const {
            target: { value }
        } = event;

        const v: string | string[] = (typeof value === 'string') ? value.split(',') : value;
        if (parentHandler) {
            parentHandler(v);
        }

        setSelection(v);
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: "90%" }}>
                <InputLabel id={label}>{label}</InputLabel>
                <Select
                    labelId={label}
                    id={`${label}Select`}
                    multiple
                    value={selection}
                    onChange={handleChange}
                    input={<OutlinedInput id={`select-multiple-${label}`} label={label} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} component="div">
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {selections.map((c) => (
                        <MenuItem
                            key={c}
                            value={c}
                        >
                            {c}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}