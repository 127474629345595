import {useState, useEffect, useCallback} from "react";
import {RequestStatus} from "../../data-types";
import {VideoReviewContent, APIReviewContent, MarshalledAPIData, StandardAttribute} from "./VideoReviewConfig";
import {DataAccess, DataFormatting} from "../../util";

function marshallApiData(apiData:  APIReviewContent[]): MarshalledAPIData[] {
    return apiData.map(c => {
        const review = c.reviews[0];
        const videoPrompt = DataFormatting.videoToVideoJSOptions(c.videoPrompt);
        const videoResponse = DataFormatting.videoToVideoJSOptions(c.videoResponse);

        // backfill comments
        review.comments = review.comments || "";

        return {
            videoPrompt: videoPrompt,
            videoResponse: videoResponse,
            review: review
        }
    })
}

export const useVideoReviewConfig = (competitionId: string | undefined, isProxy: boolean = false) => {

    // request status
    const [metaRequestStatus, setMetaRequestStatus] = useState<RequestStatus>("loading");
    const [reviewRequestStatus, setReviewRequestStatus] = useState<RequestStatus>("loading");

    // state
    const [videoReviewState, setVideoReviewState] = useState<VideoReviewContent[]>([]);
    const [standardAttributes, setStandardAttributes] = useState<StandardAttribute[]>([]);

    // toggle, current prompt or responder depending on toggle
    const [promptToggle, setPromptToggle] = useState<boolean>(true);
    const [selectedPrompt, setSelectedPrompt] = useState<number>(NaN);
    const [selectedResponder, setSelectedResponder] = useState<number>(NaN);

    const metaRoute: string= isProxy ? `/api/proxyReview/${competitionId}/getMeta.json` : `/api/review/${competitionId}/getMeta.json`;
    const contentRoute: string = isProxy ? `/api/proxyReview/getContent.json` : `/api/review/getContent.json`;
    const saveRoute: string = isProxy ? `/api/proxyReview/save.json` : `/api/review/save.json`;
    const submitRoute: string = isProxy ? `/api/proxyReview/${competitionId}/submit.json` : `/api/review/${competitionId}/submit.json`

    useEffect(() => {

        const getMeta = async () => {
            if (competitionId) {
                const r = await DataAccess.get(metaRoute);
                const reviewMeta: VideoReviewContent[] = r.reviewMeta;
                const standardAttributes: StandardAttribute[] = r.standardAttributeConfig;
                reviewMeta.sort((i, j) => {
                    if (i.parentPosition === j.parentPosition) {
                        if (i.institutionName === j.institutionName) {
                            return i.childPosition! - j.childPosition!;
                        } else {
                            return i.institutionName! < j.institutionName! ? -1 : 1;
                        }
                    } else {
                        return i.parentPosition - j.parentPosition;
                    }
                })

                // we want to map revieweeIds to A, B, C and promptIds to 1, 2, 3, ...
                // generate maps of unique revieweeIds to letters, unique promptIds to numbers
                const uniqueReviewees = Array.from(new Set(reviewMeta.map(m => m.revieweeId)));
                const uniquePrompts = Array.from(new Set(reviewMeta.map(m => m.promptId)));

                // for each reviewMeta, assign a promptLabel and revieweeLabel based on index of
                // unique reviewee/promptIds.  Reviewee label should be a letter as long as
                // number of reviewees < 52
                return {
                    standardAttributes: standardAttributes,
                    content: reviewMeta.map(m => {
                        return {
                            ...m,
                            promptLabel: (uniquePrompts.indexOf(m.promptId) + 1).toString(),
                            revieweeLabel: String.fromCharCode(uniqueReviewees.indexOf(m.revieweeId) + 65)
                        } as VideoReviewContent;
                    })
                }
            } else {
                return {standardAttributes: [], content: []};
            }
        }

        const getFirstPromptData = async (content: VideoReviewContent[]) => {
            if (content.length === 0) {
                throw new Error("No reviews to get");
            } else {
                const firstPromptId = content[0].promptId;
                const firstRevieweeId = content[0].revieweeId;
                const firstPromptReviewIds = content
                    .filter(c => c.promptId === firstPromptId)
                    .map(c => c.reviewId);
                const data = {data: {reviewIds: firstPromptReviewIds}};
                const r = await DataAccess.post(contentRoute, data);

                // data returned from api is not in a form this component can consume.
                // marshall the returned data to VideoReviewContent and merge
                // review, videoPrompt, and videoResponse keys
                const apiReviews: APIReviewContent[] = r.reviewList.reviews;
                const marshalledReviews = marshallApiData(apiReviews);
                // merge marshalledReviews with content
                const mergedReviews = content.map(c => {
                    const found = marshalledReviews.find(m => m.review.reviewId === c.reviewId);
                    return (found) ? {...c, ...found} : c;
                });
                setVideoReviewState(mergedReviews);
                setSelectedPrompt(firstPromptId);
                // arbitrarily set responder id to first found
                setSelectedResponder(firstRevieweeId);
            }
        }

        getMeta()
            .then(c => {
                setMetaRequestStatus("complete");
                setStandardAttributes(c.standardAttributes);
                return c.content;
            })
            .catch(e => {
                console.log(e);
                setMetaRequestStatus("error");
                return [] as VideoReviewContent[];
            })
            .then(content => {
                return getFirstPromptData(content);
            })
            .then(_ => setReviewRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setReviewRequestStatus("error");
            })

    }, [competitionId, metaRoute, contentRoute]);

    const toggleView = useCallback(() => {
        setPromptToggle(prev => !prev);
    }, []);

    const getMissingReviews = useCallback(async (missingReviewIds: number[]) => {

        const data = {data: {reviewIds: missingReviewIds}}
        const r = await DataAccess.post(contentRoute, data);
        // marshall and merge

        const apiReviews: APIReviewContent[] = r.reviewList.reviews;
        const marshalledReviews = marshallApiData(apiReviews);
        // merge marshalledReviews with content
        const mergedReviews = videoReviewState.map(c => {
            const found = marshalledReviews.find(m => m.review.reviewId === c.reviewId);
            return (found) ? {...c, ...found} : c;
        });
        setVideoReviewState(mergedReviews);
    }, [contentRoute, videoReviewState]);

    const handlePromptChange = useCallback(async (newPrompt: number) => {
        setReviewRequestStatus("loading");
        // first, get VideoReviewContents that we don't already have from api
        const missingReviews = videoReviewState
            .filter(c => (c.promptId === newPrompt) && (!c.review))
            .map(c => c.reviewId);

        // if not empty, get content
        if (missingReviews.length > 0) {
            await getMissingReviews(missingReviews);
        }
        setSelectedPrompt(newPrompt);
        setReviewRequestStatus("complete");
    }, [videoReviewState, getMissingReviews]);

    const handleResponderChange = useCallback(async (newResponder: number) => {
        setReviewRequestStatus("loading");
        const missingReviews = videoReviewState
            .filter(c => (c.revieweeId === newResponder) && (!c.review))
            .map(c => c.reviewId);

        // if not empty, get content
        if (missingReviews.length > 0) {
            await getMissingReviews(missingReviews);
        }
        setSelectedResponder(newResponder);
        setReviewRequestStatus("complete");
    }, [videoReviewState, getMissingReviews]);

    const handleScoreChange = useCallback((reviewId: number, attributeName: string, newScore: number) => {
        setVideoReviewState(prev => {
            return prev.map(c => {
                if (c.reviewId === reviewId) {
                    const reviewContent = c.review!;
                    if (attributeName === "overall") {
                        return {...c, review: {...reviewContent, rawScore: newScore}, modified: true}
                    } else {
                        const attributeContent = reviewContent.attributes;
                        const newAttributeScores = attributeContent
                            .map(a => {
                                return (a.attributeName === attributeName) ?
                                    {...a, attributeScore: newScore} :
                                    a
                            })
                        return {...c, review: {...reviewContent, attributes: newAttributeScores}, modified: true}
                    }
                } else {
                    return c;
                }
            })
        })
    }, []);

    const handleStandardAttributeChange = useCallback((reviewId: number, attribute: StandardAttribute) => {
        console.log(attribute);
        setVideoReviewState(prev => {
            return prev.map(c => {
                if (c.reviewId === reviewId) {
                    const reviewContent = c.review!;
                    const attributeExists = reviewContent.standardAttributes.some(a => a.attributeId === attribute.attributeId);
                    const newAttributeData = (attributeExists) ?
                        reviewContent.standardAttributes.filter(a => a.attributeId !== attribute.attributeId) :
                        [...reviewContent.standardAttributes, attribute];
                    return {...c, review: {...reviewContent, standardAttributes: newAttributeData}, modified: true}
                } else {
                    return c;
                }
            })
        })
    }, []);

    const handleCommentChange = useCallback((reviewId: number, comments: string) => {
        setVideoReviewState(prev => {
            return prev.map(c => {
                if (c.reviewId === reviewId) {
                    const reviewContent = c.review!;
                    return {...c, review: {...reviewContent, comments: comments}, modified: true}
                } else {
                    return c;
                }
            })
        })
    }, []);

    const handleSaveReview = useCallback(async (content: VideoReviewContent) => {
        // marshall the state so that database can consume
        const reviewData = content.review!
        const reviewId = reviewData.reviewId;
        const attributeData = reviewData.attributes.filter(c => c.attributeScore && c.attributeScore !== 0);
        const data = {
            reviewId: reviewData.reviewId,
            rawScore: reviewData.rawScore,
            comments: reviewData.comments,
            isReported: reviewData.isReported,
            attributes: attributeData,
            standardAttributes: reviewData.standardAttributes
        };

        await DataAccess.put(saveRoute, {data: data})

        // if request succeeds, local state changes to 'saved'
        setVideoReviewState(prev =>
            prev.map(c => (c.reviewId === reviewId) ?
                {...c, saved: true, modified: false, review: reviewData} :
                c
            ))
    }, [saveRoute]);

    const handleHideReview = useCallback((content: VideoReviewContent) => {
        const reviewId = content.reviewId;
        setVideoReviewState(prev =>
            prev.map(c => (c.reviewId === reviewId) ? {...c, hidden: !c.hidden} : c)
        );
    }, []);

    const handleSubmit = useCallback(async () => {
        await DataAccess.put(submitRoute, {data: null});
    }, [submitRoute]);

    return {
        metaRequestStatus: metaRequestStatus,
        reviewRequestStatus: reviewRequestStatus,
        videoReviewState: videoReviewState,
        promptToggle: promptToggle,
        selectedPrompt: selectedPrompt,
        selectedResponder: selectedResponder,
        handlePromptChange: handlePromptChange,
        handleResponderChange: handleResponderChange,
        handleScoreChange: handleScoreChange,
        handleCommentChange: handleCommentChange,
        handleSaveReview: handleSaveReview,
        handleHideReview: handleHideReview,
        handleSubmit: handleSubmit,
        toggleView: toggleView,
        standardAttributes: standardAttributes,
        handleStandardAttributeChange: handleStandardAttributeChange
    }
}