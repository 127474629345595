import React from "react";
import {Container, Typography} from "@mui/material";

export function FrequentlyAskedQuestions() {

    return (
        <Container>

        </Container>
    )
}