import React from "react";
import {
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent, TimelineDotProps
} from "@mui/lab";
import {Box, IconButton, Button} from "@mui/material";
import {RoundStatus} from "../../data-types";
import {Typography} from "@mui/material";
import {format, parseISO, isBefore, isAfter, formatDistance} from "date-fns";
import {Link} from "react-router-dom";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PollIcon from "@mui/icons-material/Poll";
import SettingsIcon from '@mui/icons-material/Settings';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {AddRoundTimelineItem} from "./AddRoundTimelineItem";

interface RoundTimelineItemProps {
    round: RoundStatus;
    handleDeleteClick: (id: number) => void;
    competitionId: number;
    canEdit: boolean;
}

function roundDistanceString(now: Date, from: Date, to: Date) {
    if (isBefore(now, from)) {
        return `starts in ${formatDistance(now, from)}`;
    } else if (isAfter(now, to)) {
        return `ended ${formatDistance(to, now, {addSuffix: true})}`;
    } else {
        return "Active now!"
    }
}

function roundIconColors(phase: string) {
    let proxyColor, reviewColor, responseColor;

    switch (phase) {
        case "response": {
            proxyColor = "grey";
            reviewColor = "grey";
            responseColor = "secondary";
            break;
        }
        case "review": {
            proxyColor = "grey";
            reviewColor = "secondary";
            responseColor = "primary";
            break;
        }
        case "proxy": {
            proxyColor = "secondary"
            reviewColor = "primary";
            responseColor = "primary";
            break;
        }
        case "finished": {
            proxyColor = "primary"
            reviewColor = "primary";
            responseColor = "primary";
            break;
        }
        default: {
            proxyColor = "grey"
            reviewColor = "grey";
            responseColor = "grey";
        }
    }

    return {reviewColor: reviewColor as TimelineDotProps["color"],
        responseColor: responseColor as TimelineDotProps["color"],
        proxyColor: proxyColor as TimelineDotProps["color"]};
}
export function RoundTimelineItem({round, handleDeleteClick, competitionId, canEdit}: RoundTimelineItemProps) {
    const now = new Date();
    const position = (round.roundNumber % 2 === 0) ? "left" : "right";
    let responseStartDate = parseISO(round.responseStartDate + "Z");
    let responseEndDate = parseISO(round.responseEndDate + "Z");
    let reviewStartDate = parseISO(round.reviewStartDate + "Z");
    let reviewEndDate = parseISO(round.reviewEndDate + "Z");
    let proxyStartDate = parseISO(round.proxyStartDate + "Z");
    let proxyEndDate = parseISO(round.proxyEndDate + "Z");

    let responseDistanceString = roundDistanceString(now, responseStartDate, responseEndDate);
    let reviewDistanceString = roundDistanceString(now, reviewStartDate, reviewEndDate);
    let proxyDistanceString = roundDistanceString(now, proxyStartDate, proxyEndDate);

    let isRoundStarted = isAfter(now, responseStartDate);

    let {responseColor, reviewColor, proxyColor} = roundIconColors(round.phase);

    return (
        <Box component="div">
            {round.phase === "standby" ?
                <AddRoundTimelineItem competitionId={competitionId} endConnector={false} roundNumber={round.roundNumber} canEdit={canEdit} /> :
                null
            }
            <TimelineItem position={position} sx={{alignItems: "center"}}>
                <TimelineOppositeContent>
                    <Typography variant="body2">
                        {`Starts: ${format(responseStartDate, "Pp")}`}
                    </Typography>
                    <Typography variant="body2">
                        {`Ends: ${format(responseEndDate, "Pp")}`}
                    </Typography>
                    <Typography variant="body2">
                        {responseDistanceString}
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator sx={{ml: 2, mr: 2, height: 125}}>
                    <TimelineConnector />
                    <TimelineDot color={responseColor}>
                        <KeyboardVoiceIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography variant="h5" color="white">
                        {`Round ${round.roundNumber}`}
                    </Typography>
                    <IconButton component={Link} to={`/admin/round/detail/${round.roundId}`}>
                        <FormatListBulletedIcon />
                    </IconButton>
                    <IconButton disabled={!canEdit} component={Link} to={`/admin/round/config/${round.roundId}`}>
                        <SettingsIcon />
                    </IconButton>
                    <IconButton disabled={isRoundStarted || !canEdit} onClick={() => handleDeleteClick(round.roundId)}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton component={Link} to={`/admin/round/edit/${round.roundId}`} disabled={isRoundStarted || !canEdit}>
                        <EditIcon />
                    </IconButton>
                    <Typography>
                        Response Phase
                    </Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem position={position} sx={{alignItems: "center"}}>
                <TimelineOppositeContent>
                    <Typography variant="body2">
                        {`Starts: ${format(reviewStartDate, "Pp")}`}
                    </Typography>
                    <Typography variant="body2">
                        {`Ends: ${format(reviewEndDate, "Pp")}`}
                    </Typography>
                    <Typography variant="body2">
                        {reviewDistanceString}
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator sx={{ml: 2, mr: 2, height: 125}}>
                    <TimelineConnector />
                    <TimelineDot color={reviewColor} >
                        <PollIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography>
                        Review Phase
                    </Typography>
                </TimelineContent>
            </TimelineItem>
            {round.hasProxy &&
                <TimelineItem position={position} sx={{alignItems: "center"}}>
                    <TimelineOppositeContent>
                        <Typography variant="body2">
                            {`Starts: ${format(proxyStartDate, "Pp")}`}
                        </Typography>
                        <Typography variant="body2">
                            {`Ends: ${format(proxyEndDate, "Pp")}`}
                        </Typography>
                        <Typography variant="body2">
                            {proxyDistanceString}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator sx={{ml: 2, mr: 2, height: 125}}>
                        <TimelineConnector />
                        <TimelineDot color={proxyColor} >
                            <PollIcon />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography>
                            Proxy Phase
                        </Typography>
                        <Button
                            variant="contained"
                            component={Link}
                            to={`/admin/proxyReviewResults/${round.roundId}`}
                            color="secondary"
                            size="small"
                            sx={{mt: 2, textAlign: "center"}}
                        >
                            Results
                        </Button>
                    </TimelineContent>
                </TimelineItem>
            }
        </Box>
    )
}