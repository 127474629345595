import React from "react";
import {ContinuousBinEditorProps} from "../../data-types";
import {Grid, TextField, Button} from "@mui/material";

export function ContinuousBinEditor({bin, binConfig, binIndex, onChange, remove, active}: ContinuousBinEditorProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {valueAsNumber, name} = e.target;
        onChange(binIndex, {...bin, [name]: valueAsNumber})
    }

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{mb: 2}}>
            <Grid item xs={4}>
                <TextField
                    variant="standard"
                    id={`${binIndex}-min`}
                    name="from"
                    type="number"
                    label="From"
                    value={bin.from}
                    inputProps={{
                        step: binConfig.minStep,
                        min: binConfig.min,
                        max: binConfig.max,
                        disabled: !active
                    }}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    variant="standard"
                    id={`${binIndex}-max`}
                    name="to"
                    type="number"
                    label="To"
                    value={bin.to}
                    inputProps={{
                        step: binConfig.minStep,
                        min: binConfig.min,
                        max: binConfig.max,
                        disabled: !active
                    }}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => remove(binIndex)}
                    disabled={!active}
                >
                    Remove
                </Button>

            </Grid>
        </Grid>
    )
}