import React from "react";
import {Grid, Typography, Paper, SxProps, Button} from "@mui/material";
import {ToDoItemProps} from "../../data-types";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PollIcon from "@mui/icons-material/Poll";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {parseISO} from "date-fns";
import {Link} from "react-router-dom";

export function ToDoItem({todo}: ToDoItemProps) {

    let icon;
    let link: string = "/";
    switch(todo.type) {
        case "REVIEW":
            icon = <PollIcon fontSize="large" />;
            link = `/video-review/${todo.competitionId}`
            break;
        case "RESPONSE":
            icon = <KeyboardVoiceIcon fontSize="large" />;
            link = `/video-response/${todo.competitionId}`;
            break;
        case "PROXY_REVIEW":
            icon = <PollIcon fontSize="large" />;
            link = `/reviewer/video-proxy-review/${todo.competitionId}`;
            break;
        case "ADMIN_COMPETITION":
            icon = <AdminPanelSettingsIcon fontSize="large" />;
            link = `/admin/events/detail/${todo.competitionId}`
            break;
        case "PROFILE":
            icon = <AccountCircleIcon fontSize="large" />;
            link = `/user/profile`;
            break;
        default:
            icon = <ErrorIcon fontSize="large" />;
    }

    let statusStyle: SxProps = {p: 1, m: 1, textAlign: "center", fontWeight: "bold"};
    switch (todo.status) {
        case "TO DO":
            statusStyle = {...statusStyle, bgcolor: "#F50057", color: "white"};
            break;
        case "IN PROGRESS":
            statusStyle = {...statusStyle, color: "#dddddd", bgcolor: "#0096FF"};
            break;
        case "COMPLETED":
            statusStyle = {...statusStyle, bgcolor: "#28871D", color: "white"};
            break;
        case "WON'T DO":
            statusStyle = {...statusStyle, bgcolor: "#202020", color: "#aaaaaa"}
            break;
    }

    return (
        <Grid container item xs={12} justifyContent="center" alignItems="center" component={Paper} sx={{mb: 3, p: 3}}>
            <Grid item xs={2} sx={{pl: 2}}>
                {icon}
            </Grid>
            <Grid item container xs={10} justifyContent="center" alignItems="center" sx={{textAlign: "center"}} spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1">
                        {todo.description}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    {`Due:  ${(todo.dueDate) ? parseISO(todo.dueDate + "Z").toLocaleString() : "N/A"}`}
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button
                        component={Link}
                        to={link}
                        sx={statusStyle}
                        disabled={todo.status === "COMPLETED" || todo.status === "WON'T DO"}
                        startIcon={<OpenInNewIcon />}
                    >
                        {todo.status}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}