import React from "react";
import { Card, CardContent,
    CardMedia, CardActions, Typography, CardActionArea, IconButton,
    Tooltip, Box
} from "@mui/material";
import {VideoCardProps} from "../../data-types";
import {parseISO} from "date-fns";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Link} from "react-router-dom";
import video_placeholder from "../../img/video_placeholder.png";
import {DataFormatting} from "../../util";
import IosShareIcon from '@mui/icons-material/IosShare';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export function VideoCard({video, selected, toggleSelection, handleShareClick, handleDeleteClick}: VideoCardProps) {

    const overlayStyles = {
        hidden: {display: "none"},
        selected: {
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            bgcolor: "green",
            color: "white",
            padding: "5px",
            textAlign: "center"
        }
    };

    const link = (video.type === "prompt") ?
        `/admin/videoPrompts/detail/${video.videoId}` :
        `/responses/${video.videoId}`;

    const poster = video.sources?.find(s => s.type === "IMAGE/JPG")?.src;

    return (
        <Card sx={
            selected ?
            {border: "3px solid green", boxSizing: "border-box"} :
            {border: "3px solid rgba(0, 0, 0, 0.0)", boxSizing: "border-box"}
        }
        >
            <CardActionArea
                onClick={() => toggleSelection?.(video)}
                disabled={!toggleSelection}
            >
                <Box sx={{position: "relative"}} component="div">
                    <CardMedia
                        sx={{height: 150}}
                        component="img"
                        image={poster ?? video_placeholder}
                    />
                    <Box
                        component="div"
                        sx={selected ? overlayStyles.selected : overlayStyles.hidden}
                    >
                        <Typography variant="h6">
                            Selected
                        </Typography>
                    </Box>
                </Box>
                <CardContent>
                    <Typography variant="h5" sx={{mb: 3}}>
                        {video.name}
                    </Typography>
                    <Typography variant="subtitle2" sx={{mb: 2}}>
                        {`Author:  ${video.createdBy}`}
                    </Typography>
                    <Box component="div" sx={{display: "flex", alignItems: "center", mb: 2}}>
                        <CheckCircleIcon color={video.isPublic ? "primary" : "inherit"} />
                        <Typography variant="subtitle2" sx={{ml: 2}}>
                            {`Is Public:  ${video.isPublic ? "Yes" : "No"}`}
                        </Typography>
                    </Box>
                    <Box sx={{display: "flex", alignItems: "center", mb: 2}} component="div">
                        <AccessTimeIcon />
                        <Typography variant="subtitle2" sx={{ml: 2}}>
                            {`Length:  ${DataFormatting.secondsToTimeString(parseInt(video.size))}`}
                        </Typography>
                    </Box>
                    <Box sx={{display: "flex", alignItems: "center", mb: 2}} component="div">
                        <CalendarMonthIcon />
                        <Typography variant="subtitle2" sx={{ml: 2}}>
                            {`Created:  ${parseISO(video.createDate + "Z").toLocaleString()}`}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
            <CardActions sx={{display: "flex", justifyContent: "center"}}>
                <Tooltip title="View Details">
                    <IconButton
                        size="large"
                        component={Link}
                        to={link}
                        rel="noopener noreferrer"
                    >
                        <OpenInNewIcon />
                    </IconButton>
                </Tooltip>
                {
                    handleShareClick ?
                        <Tooltip title="Share">
                            <IconButton
                                size="large"
                                onClick={() => handleShareClick(video.videoId)}
                                disabled={!(!video.isPublic && video.canShare)}
                            >
                                <IosShareIcon />
                            </IconButton>
                        </Tooltip> :
                        null
                }
                {
                    handleDeleteClick ?
                        <Tooltip title="Delete">
                            <IconButton
                                size="large"
                                onClick={() => handleDeleteClick(video.videoId)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip> :
                        null
                }
            </CardActions>
        </Card>
    )
}
