import React from "react";
import {Avatar, Box, Card, CardContent, Divider, Typography, Grid} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import PersonIcon from '@mui/icons-material/Person';
import {LeaderCardProps} from "../../data-types";

export function LeaderCard({place, user, trend}: LeaderCardProps) {
    let color;
    switch (place) {
        case "1st":
            color = "#FFD700";
            break;
        case "2nd":
            color = "#c0c0c0";
            break;
        case "3rd":
            color = "#967444";
            break;
        default:
            color = "#FFFFFF";
    }

    const trendIcon = function(trend: number) {
        let icon;
        if (trend > 0) {
            icon = <TrendingUpIcon color="primary" fontSize="large"/>
        } else if (trend < 0) {
            icon = <TrendingDownIcon color="secondary" fontSize="large"/>
        } else {
            icon = <TrendingFlatIcon fontSize="large"/>
        }
        return (
            <Box sx={{display: 'inline-flex', alignItems: "center"}} component="div">
                {icon}
            </Box>
        )
    }

    return (
        <Card sx={(place === "1st") ? {pt: 3, pb: 3, border: 2, borderColor: '#ffd802', mt: 2} : {mt: 3}}>
            <CardContent>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: -5}} component="div">
                    <EmojiEventsIcon sx={{mx: 1, color: color}} fontSize="large" />
                    <Typography variant="h6" component="h2">
                        {place} Place
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', mt: 5, mb: 3}} component="div">
                    <Avatar
                        src={user.avatar ? `data:image/jpeg;base64,${user.avatar}` : ""}
                        sx={{width: 56, height: 56, mb: 2}}
                        imgProps={{alt: user.username+"'s avatar"}}
                    >
                        <PersonIcon titleAccess={`${user.username}'s avatar`} sx={{width: 42, height: 42}}/>
                    </Avatar>
                    <Box sx={{display: 'inline-flex', alignItems: "center"}} component="div">
                        <Typography variant="h6" component="h2">
                            {user.username}
                        </Typography>
                    </Box>
                </Box>
                <Divider variant="middle" sx={{mb: 3}} />
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={6} md={6}>
                        <Box sx={{textAlign: 'center'}} component="div">
                            <Typography variant="body1">
                                Total
                            </Typography>
                            <Typography variant="h6" component="span">
                                {user.totalScore.toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    {trend ?
                        <Grid item xs={6} md={6}>
                            <Box sx={{textAlign: 'center'}} component="div">
                                <Typography variant="body1">
                                    Trending
                                </Typography>
                                {trendIcon(user.rankTrend)}
                            </Box>
                        </Grid> :
                        null
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}
