import React, {useState} from "react";
import {Container, Typography, Grid, Button, Tabs, Tab, TextField, MenuItem} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useMyEvents} from "./useMyEvents";
import {NoDataCard, ErrorComponent, LoadingSpinner, OptionalStepRoundModal, ConfirmActionModal} from "../../components";
import {Competition, InstitutionSelections, OptionalScriptStepConfig} from "../../data-types";
import {ActiveEventCard} from "./ActiveEventCard";
import {CompletedEventCard} from "./CompletedEventCard";
import {toast} from "react-toastify";
import {DataAccess, formatError} from "../../util";
import {useEnroll} from "../enroll/useEnroll";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type EventFilter = "active" | "response" | "review" | "standby" | "none";
type EventSort = "none" | "alphabetical";

export function MyEvents() {

    const {
        requestStatus,
        activeEvents,
        completedEvents,
        tab,
        handleTabChange,
        removeEvent
    } = useMyEvents();

    const {
        invitations,
        openCompetitions,
        handleUnenroll
    } = useEnroll();

    const [stepModalOpen, setStepModalOpen] = useState<boolean>(false);
    const [selectedRound, setSelectedRound] = useState<number>(NaN);
    const [selectedOptionalStepConfig, setSelectedOptionalStepConfig] = useState<OptionalScriptStepConfig[]>([]);
    const [selections, setSelections] = useState<InstitutionSelections>({});

    // unenroll section
    const [unenrollModalOpen, setUnenrollModalOpen] = useState<boolean>(false);
    const [unenrollSelection, setUnenrollSelection] = useState<number>(NaN);

    const [activeEventFilter, setActiveEventFilter] = useState<EventFilter>("none");
    const [activeEventSort, setActiveEventSort] = useState<EventSort>("none");

    const navigate = useNavigate();

    const availableEnrollmentCount = invitations.current.length + openCompetitions.length;

    const handleSelectRoundModalOpen = (optionalScriptStepConfig: OptionalScriptStepConfig[]) => {
        const roundId = optionalScriptStepConfig.length > 0 ? optionalScriptStepConfig[0].roundId : NaN;
        if (!isNaN(roundId)) {
            setSelectedOptionalStepConfig(optionalScriptStepConfig);
            setSelectedRound(roundId);
            setStepModalOpen(true);
        }
    }

    const handleInstitutionSelection = (institutionId: number | null, scriptStepId?: string) => {
        if (scriptStepId) {
            setSelections(prev => {
                return {
                    ...prev,
                    [scriptStepId]: institutionId
                }
            })
        } else {
            setSelections({});
        }
    };

    const submitInstitutionSelections = () => {
        submitSelections()
            .then(_ => {
                toast.success("Thank you for making selections for this round.");
                navigate(0);
            })
            .catch(e => {
                toast.error(formatError(e));
            })
    }

    const submitSelections = async () => {
        // check if institutionSelections same length as config
        if (Object.keys(selections).length !== selectedOptionalStepConfig.length) {
            throw new Error("Must make a selection for each.");
        }
        const data = Object.entries(selections).map(([key, value]) => {
            return {
                scriptStepId: key,
                chosenInstitutionId: value
            }
        })

        await DataAccess.post(`/api/round/${selectedRound}/userStart.json`, {data: {userChoices: data}});
    }

    const handleUnenrollClick = (competitionId: number) => {
        setUnenrollSelection(competitionId);
        setUnenrollModalOpen(true);
    }

    const submitUnenroll = () => {
        handleUnenroll(unenrollSelection)
            .then(_ => {
                removeEvent(unenrollSelection);
                toast.success("Successfully unenrolled from event.");
            })
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
            .finally(() => {
                setUnenrollSelection(NaN);
                setUnenrollModalOpen(false);
            })
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {

        let filteredEvents: Competition[];
        if (activeEventFilter === "active") {
            filteredEvents = activeEvents.filter(c => !c.participantStatus!.eliminated);
        } else if (activeEventFilter === "response") {
            filteredEvents = activeEvents.filter(c => c.roundStatus?.phase === "response");
        } else if (activeEventFilter === "review") {
            filteredEvents = activeEvents.filter(c => c.roundStatus?.phase === "review");
        } else if (activeEventFilter === "standby") {
            filteredEvents = activeEvents.filter(c => !c.roundStatus || c.roundStatus?.phase === "standby");
        } else {
            filteredEvents = activeEvents;
        }

        if (activeEventSort === "alphabetical") {
            filteredEvents = [...filteredEvents].sort((a, b) => {
                const aName = a.competitionMeta!.name.toLowerCase();
                const bName = b.competitionMeta!.name.toLowerCase();
                if (aName < bName) {
                    return -1;
                } else if (aName > bName) {
                    return 1;
                } else {
                    return 0;
                }
            })
        }

        return (
            <Container sx={{mb: 10}}>
                <Grid container justifyContent="space-between" alignItems="center" sx={{mb: 5}}>
                    <Grid item>
                        <Typography variant="h4">
                            My Events
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            component={Link}
                            to={"/enroll"}
                            startIcon={<OpenInNewIcon />}
                        >
                            {`Select Events ${availableEnrollmentCount > 0 ? `(${availableEnrollmentCount})` : ""}`}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 5}}>
                    <Grid item xs={12}>
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            centered
                            textColor="inherit"
                        >
                            <Tab label={`Active Events ${activeEvents.length ? `(${activeEvents.length})` : ""}`} value="active" />
                            <Tab label={`Completed Events ${completedEvents.length ? `(${completedEvents.length})` : ""}`} value="complete" />
                        </Tabs>
                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                    {tab === "active" ?
                        <Grid container item xs={12} spacing={2} justifyContent="space-between">
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Filter By"
                                    name="filterBy"
                                    id="filterBy"
                                    select
                                    value={activeEventFilter}
                                    onChange={(e) => setActiveEventFilter(e.target.value as EventFilter)}
                                    sx={{width: 250}}
                                >
                                    <MenuItem value="none">
                                        No Filter
                                    </MenuItem>
                                    <MenuItem value="active">
                                        Active
                                    </MenuItem>
                                    <MenuItem value="response">
                                        Response
                                    </MenuItem>
                                    <MenuItem value="review">
                                        Review
                                    </MenuItem>
                                    <MenuItem value="standby">
                                        Standby
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Sort By"
                                    name="sortBy"
                                    id="sortBy"
                                    select
                                    value={activeEventSort}
                                    onChange={(e) => setActiveEventSort(e.target.value as EventSort)}
                                    sx={{width: 250}}
                                >
                                    <MenuItem value="none">
                                        Due Date
                                    </MenuItem>
                                    <MenuItem value="alphabetical">
                                        Alphabetical
                                    </MenuItem>
                                </TextField>

                            </Grid>
                        </Grid> :
                        null
                    }
                    {tab === "active" ?
                        (filteredEvents.length > 0) ?
                            filteredEvents.map((c, i) =>
                                <Grid item xs={12} key={i}>
                                    <ActiveEventCard
                                        competitionMeta={c.competitionMeta!}
                                        roundStatus={c.roundStatus!}
                                        userStatistics={c.userStatistics!}
                                        participantStatus={c.participantStatus!}
                                        handleSelectRoundModalOpen={handleSelectRoundModalOpen}
                                        handleUnenrollClick={handleUnenrollClick}
                                        position={i + 1}
                                    />
                                </Grid>) :
                            <Grid item xs={12}>
                                <NoDataCard message="No events." hideHomeButton={true} />
                            </Grid> :
                        (completedEvents.length > 0) ?
                            completedEvents.map((c, i) =>
                                <Grid item xs={12} key={i}>
                                    <CompletedEventCard
                                        competitionMeta={c.competitionMeta!}
                                        userStatistics={c.userStatistics!}
                                        position={i + 1}
                                    />
                                </Grid>
                        ) :
                            <Grid item xs={12}>
                                <NoDataCard message="You have no completed events" />
                            </Grid>
                    }
                </Grid>
                <OptionalStepRoundModal
                    optionalScriptSteps={selectedOptionalStepConfig}
                    handleInstitutionSelect={handleInstitutionSelection}
                    institutionSelections={selections}
                    open={stepModalOpen}
                    setOpen={setStepModalOpen}
                    action={submitInstitutionSelections}
                />
                <ConfirmActionModal
                    open={unenrollModalOpen}
                    setOpen={setUnenrollModalOpen}
                    action={submitUnenroll}
                    confirmActionText="Unenroll"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Unenroll from Event
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                Are you sure you want to unenroll from this event?  Please note:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                1.  All saved progress will be lost.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                2.  You will no longer be enrolled in this event and your payment will not be reversed (if applicable).  Please contact support@collegenet.com to process a reversal.
                            </Typography>
                        </Grid>
                    </Grid>
                </ConfirmActionModal>
            </Container>
        )
    }
}