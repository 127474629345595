import React, {PropsWithChildren} from "react";
import {Grid, Pagination, Stack, Typography} from "@mui/material";
import {LoadingSpinner} from "../../components";

interface ResponseLibraryProps {
    loading: boolean;
    totalRecords: number;
    changePage: (pageNumber: number) => Promise<void>;
    pageNumber: number;
    recordsPerPage: number;
}
export function ResponseLibrary(props: PropsWithChildren<ResponseLibraryProps>) {

    return (
        <>
            {props.loading ?
                <LoadingSpinner /> :
                props.children
            }
            <Grid container spacing={2} sx={{mt: 3}}>
                <Grid item xs={12}>
                    <Stack alignItems="center">
                        <Pagination
                            page={props.pageNumber}
                            count={Math.max(1, Math.ceil(props.totalRecords / props.recordsPerPage ))}
                            color="primary"
                            onChange={async (_, pageNumber) => await props.changePage(pageNumber)}
                        />
                        <Typography variant="body1" sx={{mt: 3}}>
                            {`Video Count:  ${props.totalRecords}`}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}