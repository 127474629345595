import React from "react";
import {Modal, Box, Grid, Typography, CircularProgress} from "@mui/material";
import {ModalProps} from "../../data-types";
export function RedirectModal({open}: ModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    }

    return (
        <Modal
            open={open}
        >
            <Box sx={style} component="div">
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                        <CircularProgress color="primary" variant="indeterminate" />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Redirecting you to an external site.
                        </Typography>
                        <Typography variant="subtitle2">
                            Your page will open in a new tab.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}