import React, {useState, useEffect} from "react";
import {Container, Grid, Tabs, Tab} from "@mui/material";
import {useLocation, useNavigate, useParams, Outlet} from "react-router-dom";

type AdminResultsTab = "reviews" | "rankings" | "reports" | "results";

export function AdminCompetitionResults2() {

    const navigate = useNavigate();
    const location = useLocation();
    const [adminResultsTab, setAdminResultsTab] = useState<AdminResultsTab>(() => {
            const startingPage = location.pathname.split("/");
            return (startingPage[5]) ? startingPage[5] as AdminResultsTab : "rankings";
        }
    );

    useEffect(() => {
        const currentPage = location.pathname.split("/");
        const currentTab = (currentPage[5]) ? currentPage[5] as AdminResultsTab : "rankings";
        setAdminResultsTab(currentTab);
        navigate(currentTab);
    }, [location.pathname, navigate])

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setAdminResultsTab(newValue as AdminResultsTab);
        navigate(newValue);
    }

    return (
        <Grid container sx={{mt: -7}}>
            <Grid container item xs={12}>
                <Grid item xs={12} sx={{my: 3}}>
                    <Tabs
                        value={adminResultsTab}
                        onChange={handleTabChange}
                        centered
                        textColor="inherit"
                    >
                        <Tab label="Rankings" value="rankings" />
                        <Tab label="Results" value="results" />
                        <Tab label="Reviews" value="reviews" />
                        <Tab label="Reports" value="reports" />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <Outlet />
                </Grid>
            </Grid>
        </Grid>
    )
}