import React from "react";
import {Grid, Typography} from "@mui/material";
import {RoundRanks} from "../../data-types";
import {RankingsTable} from "../../components";

interface RoundRanksProps {
    ranks: RoundRanks | undefined;
}

export function RoundRankLists({ranks}: RoundRanksProps) {

    if (ranks) {

        const gold = ranks.userRanks.filter(c => c.percentile >= 0.9);
        const silver = ranks.userRanks.filter(c => c.percentile < 0.9 && c.percentile >= 0.5);
        const bronze = ranks.userRanks.filter(c => c.percentile < 0.5);

        const showTrend = ranks.roundNumber > 1;

        return (
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} >
                    <Typography variant="h6">
                        Gold Rank
                    </Typography>
                    <RankingsTable ranks={gold} showTrend={showTrend} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} >
                    <Typography variant="h6">
                        Silver Rank
                    </Typography>
                    <RankingsTable ranks={silver} showTrend={showTrend}  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} >
                    <Typography variant="h6">
                        Bronze Rank
                    </Typography>
                    <RankingsTable ranks={bronze} showTrend={showTrend} />
                </Grid>
            </Grid>
        );
    } else {
        return <></>;
    }
}