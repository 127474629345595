import React, {PropsWithChildren} from "react";
import {ConfirmActionModalProps} from "../../data-types";
import {Box, Button, Divider, Grid, Modal} from "@mui/material";

export function ConfirmActionModal(props: PropsWithChildren<ConfirmActionModalProps>) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
        >
            <Box sx={style} component="div">
                {props.children}
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => props.action()}
                            sx={{width: "50%"}}
                        >
                            {props.confirmActionText ?? "Confirm"}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => props.setOpen(false)}
                            sx={{width: "50%"}}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}