import React from "react";
import {Container} from "@mui/material";

export function AdminCandidateDetail() {

    return (
        <Container>

        </Container>
    )
}