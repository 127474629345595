import {useState, useCallback} from "react";
import {RecordingConfig} from "../../data-types";
import {AxiosProgressEvent} from "axios";
import {DataAccess} from "../../util";

export const useVideoResponseSave = () => {

    const [uploadProgress, setUploadProgress] = useState<number>(NaN);

    const saveResponse = useCallback(async (responseId: number, recordingConfig: RecordingConfig) => {
        const handleUploadProgress = (e: AxiosProgressEvent) => {
            // if for some reason the event total is undefined, then pass 1,
            // which will yield a progress > 100 and an indeterminate
            // progress bar in ui
            const total = e.total || 1;
            setUploadProgress(Math.round((e.loaded / total) * 100));
        }

        const data = new Blob(recordingConfig.recordingData, {type: recordingConfig.mimeType});
        const formData = new FormData();
        formData.append("file", data);
        formData.append("name", `response-${responseId}`);
        formData.append("size", (60 - recordingConfig.time).toString());
        formData.append("responseId", responseId.toString());
        formData.append("contentType", recordingConfig.mimeType.split(";")[0]);

        await DataAccess.post("/api/response/save.json", {data: formData}, {onUploadProgress: handleUploadProgress});
    }, []);

    const submitResponses = useCallback(async (competitionId: string | undefined) => {
        await DataAccess.post(`/api/response/${competitionId}/submit.json`, {data: null});
    }, []);

    return {
        uploadProgress: uploadProgress,
        saveResponse: saveResponse,
        submitResponses: submitResponses
    }
}