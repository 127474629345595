import React from "react";
import {Box, Divider, Container, Grid, Typography, Paper} from "@mui/material";
import {useParams, Link} from "react-router-dom";
import {useRoundDetail} from "../../hooks";
import {LoadingSpinner, ErrorComponent} from "../../components";
import {SxProps} from "@mui/material/styles";
import {DataFormatting} from "../../util";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PollIcon from "@mui/icons-material/Poll";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {PromptResponseDetailCard} from "./PromptResponseDetailCard";

export function AdminRoundDetail() {

    const {roundId} = useParams();
    const {
        requestStatus,
        roundDetail
    } = useRoundDetail(roundId);

    let statusEl;
    switch (roundDetail.status) {
        case "response":
            statusEl = <KeyboardVoiceIcon/>;
            break;
        case "review":
        case "proxy":
            statusEl = <PollIcon/>;
            break;
        case "standby":
            statusEl = <HourglassTopIcon/>;
            break;
        case "error":
            statusEl = <ErrorIcon/>;
            break;
        default:
            statusEl = <CheckCircleIcon/>;
    }

    const scriptLength = DataFormatting.secondsToTimeString(
        roundDetail.prompts.reduce((p, c) => {
            const parsed = parseInt(c.size);
            return (isNaN(parsed)) ? p : p + parsed;
        }, 0)) ;
    const metaStyle: SxProps = {py: 3, textAlign: "center", minHeight: 110};

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2} sx={{mb: 5}}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Round Detail
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {/* Competition name */}
                            {roundDetail.competitionName}
                        </Typography>
                        <Typography variant="h6">
                            {/* Round number */}
                            {`Round #${roundDetail.roundNumber}`}
                        </Typography>
                        {roundDetail.scriptId === -1 || roundDetail.scriptId === undefined ?
                            <Typography variant="h6">
                                {/* Round number */}
                                {`Script:  ${roundDetail.scriptName}`}
                            </Typography> :
                            <Typography variant="h6" component={Link} to={`/admin/scripts/detail/${roundDetail.scriptId}`} sx={{color: 'white'}}>
                                {`Script:  ${roundDetail.scriptName}`}
                            </Typography>
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" sx={{mb: 5}}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={1} sx={metaStyle}>
                            <Typography variant="h6">
                                {roundDetail.prompts.length}
                            </Typography>
                            <Typography variant="subtitle2">
                                Prompts
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={1} sx={metaStyle}>
                            <Typography variant="h6">
                                {scriptLength}
                            </Typography>
                            <Typography variant="subtitle2">
                                Length
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={1} sx={metaStyle}>
                            <Box component="div" sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                {statusEl}
                                <Typography variant="h6" sx={{ml: 1}}>
                                    {roundDetail.status.charAt(0).toUpperCase() + roundDetail.status.slice(1)}
                                </Typography>
                            </Box>
                            <Typography variant="subtitle2">
                                Status
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={1} sx={metaStyle}>
                            <Typography variant="h6">
                                {`${roundDetail.completedResponses} / ${roundDetail.savedResponses} / ${roundDetail.dueResponses}`}
                            </Typography>
                            <Typography variant="subtitle2">
                                Submitted / Saved / Total
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                {roundDetail.prompts.map((c, i) => <PromptResponseDetailCard roundId={+roundId!} prompt={c} key={i} />)}
            </Container>
        )
    }
}