import React, {useState} from "react";
import {Button, Container, Grid, List, ListItem, ListItemText, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {DataFormatting} from "../../util";
import {LoadingSpinner, VideoPlayer, EditTranscript} from "../../components";
import {Link} from "react-router-dom";
import {parseISO} from "date-fns";
import {useVideoDetail} from "../../hooks";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export function AdminVideoPromptDetail() {

    const {videoId} = useParams();
    const {
        video: videoPrompt,
        videoOptions,
        requestStatus,
        updateLocalTranscript
    } = useVideoDetail(videoId, true);
    const [detailsToggle, setDetailsToggle] = useState<boolean>(true);

    if (requestStatus === "loading") {
        return <LoadingSpinner />
    } else if (requestStatus === "error" || videoPrompt === null) {
        return <Container />
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item md={6} xs={12} sm={12}>
                        <Button
                            component={Link}
                            to={"/admin/videoPrompts"}
                            variant="outlined"
                            size="small"
                            color="info"
                            sx={{ml: 3, mb: 3}}
                            startIcon={<KeyboardBackspaceIcon />}
                        >
                            Back to List
                        </Button>
                        <VideoPlayer
                            options={videoOptions}
                            height={400}
                        />
                    </Grid>
                    <Grid container item md={6} xs={12} sm={12}>
                        <Grid container item xs={12} justifyContent="flex-end">
                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{mb: 3}}>
                                    {detailsToggle ? "Prompt Details" : "Edit Transcript"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{textAlign: "right"}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setDetailsToggle(prev => !prev)}
                                >
                                    {detailsToggle ? "Edit Transcript" : "Back to Details"}
                                </Button>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            {detailsToggle ?
                                <List
                                    sx={{
                                        width: "100%",
                                        bgcolor: "background.paper"
                                    }}
                                >
                                    <ListItem>
                                        <ListItemText primary={`Name:  ${videoPrompt.name}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Size:  ${DataFormatting.secondsToTimeString(parseInt(videoPrompt.size))}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Create Date:  ${parseISO(videoPrompt.createDate + "Z").toLocaleString()}`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={`Created By:  ${videoPrompt.createdBy}`} />
                                    </ListItem>
                                </List> :
                                <EditTranscript video={videoPrompt} updateLocalTranscript={updateLocalTranscript} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}
