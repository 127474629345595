import {useState, useEffect} from "react";
import {ScriptDetail, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

export const useScriptDetail = (scriptId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [scriptDetail, setScriptDetail] = useState<ScriptDetail>({
        scriptId: NaN,
        scriptName: "",
        createDate: "",
        countVideos: NaN,
        isPublic: true,
        isFinalized: true,
        canShare: true,
        createdBy: "",
        canEdit: false,
        usage: [],
        scriptSteps: []
    });

    useEffect(() => {

        const getScriptDetail = async () => {
            let r = await DataAccess.get(`/api/script/${scriptId}/getDetail.json`);
            setScriptDetail(r.script);
        }

        getScriptDetail()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                setRequestStatus("error");
                console.log(e);
            })
    }, [scriptId]);

    return {
        requestStatus: requestStatus,
        scriptDetail: scriptDetail
    }
}