import React from "react";
import {Grid, Typography} from "@mui/material";
import {VideoPlayer} from "../../components";
import {PromptResultsProps} from "../../data-types";
import {ProxyReviewColDefs} from "./ColDefs";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";

export function PromptResults({proxyReviewData}: PromptResultsProps) {

    const promptOptions = {
        autoplay: false,
        controls: true,
        errorDisplay: false,
        userActions: {hotkeys: true},
        sources: proxyReviewData.videoPrompt.sources,
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    return (
        <Grid container spacing={2} sx={{mb: 5}} alignItems="center">
            <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body1" sx={{mb: 2}}>
                    {`Prompt:  ${proxyReviewData.videoPrompt.name}`}
                </Typography>
                <VideoPlayer
                    options={promptOptions}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <DataGrid
                    columns={ProxyReviewColDefs}
                    rows={proxyReviewData.proxyReviews}
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            printOptions: {
                                hideToolbar: true,
                                pageStyle: ".MuiDataGrid-main {color: #000;}"
                            }
                        }
                    }}
                    getRowId={row => row.proxyReviewId}
                    initialState={{pagination: {paginationModel: {pageSize: 6}}}}
                    pageSizeOptions={[6, 12, 24]}
                    autoHeight={true}
                />
            </Grid>
        </Grid>
    )
}