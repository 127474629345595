import React, {useState, useEffect, useCallback} from "react";
import {DataAccess, UrlConfig} from "../../util";
import {
    InvitationStatus,
    OpenCompetitionResult,
    RequestStatus,
    UserInvitationState
} from "../../data-types";
import {parseISO, isAfter, isValid} from "date-fns";

type EnrollTab = "invitations" | "available";

export const useEnroll = () => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [invitations, setInvitations] = useState<UserInvitationState>({
        current: [],
        past: []
    });
    const [openCompetitions, setOpenCompetitions] = useState<OpenCompetitionResult[]>([]);
    const [enrollTab, setEnrollTab] = useState<EnrollTab>("invitations");

    useEffect(() => {

        const invitationController = new AbortController();
        const openCompetitionController = new AbortController();

        const getInvitations = async () => {
            return await DataAccess.get("/api/user/getInvitations.json", {signal: invitationController.signal}) as UserInvitationState;
        }

        const getOpenEnroll = async () => {
            const r = await DataAccess.get("/api/competition/getOpenCompetitions.json", {signal: openCompetitionController.signal});
            const competitions: OpenCompetitionResult[] = r.competitions;
            competitions.sort((i, j) => {
                const iFirstRoundEnd = parseISO(i.firstRoundResponseEnd + "Z");
                const jFirstRoundEnd = parseISO(j.firstRoundResponseEnd + "Z");
                // if i not valid, order j first
                if (!isValid(iFirstRoundEnd)) {
                    return 1;
                } else if (!isValid(jFirstRoundEnd)) {
                    return -1;
                } else {
                    return isAfter(jFirstRoundEnd, iFirstRoundEnd) ? -1 : 1;
                }
            })
            return competitions;
        }

        const getAll = async () => {
            return await Promise.all([getInvitations(), getOpenEnroll()]);
        }

        getAll()
            .then(([invitations, openEnrollCompetitions]) => {
                setInvitations(invitations);
                setOpenCompetitions(openEnrollCompetitions);
                if (invitations.current.length === 0) {
                    setEnrollTab("available");
                }
            })
            .then(_ => setRequestStatus("complete"))
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            })

        return () => {
            invitationController.abort();
            openCompetitionController.abort();
        }
    }, []);

    const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
        setEnrollTab(newValue as EnrollTab);
    }, []);

    const handleEnroll = useCallback(async (competitionId: number, chosenInstitutionId: number | null) => {
        const foundInvitation = invitations.current.find(c => c.competitionId === competitionId);
        const foundOpenEnroll = openCompetitions.find(c => c.competitionId === competitionId);
        if (foundInvitation) {
            const invitationPayload = {
                invitationUpdate: {
                    competitionId: competitionId,
                    status: "accepted" as InvitationStatus,
                    chosenInstitutionId: chosenInstitutionId
                }
            }

            const r = await DataAccess.put("/api/invitation/publish.json", {data: invitationPayload});

            if (r) {
                window.location.href = UrlConfig.getPaymentUrl() + r.PaymentSession.token;
                return;
            }

        } else if (foundOpenEnroll) {
            const openEnrollPayload = {
                enrollmentUpdate: {
                    competitionId: competitionId,
                    isEnrolled: true,
                    chosenInstitutionId: chosenInstitutionId
                }
            }

            const r = await DataAccess.post(`/api/competition/openEnroll.json`, {data: openEnrollPayload});

            if (r) {
                window.location.href = UrlConfig.getPaymentUrl() + r.PaymentSession.token;
                return;
            }

        } else {
            return;
        }

        // update local state
        setOpenCompetitions(prev => prev.map(c => {
            return (c.competitionId === competitionId) ?
                {...c, isEnrolled: true} :
                c
        }));

        setInvitations(prev => {
            return {
                current: prev.current.filter(c => c.competitionId !== competitionId),
                past: foundInvitation ? [...prev.past, {...foundInvitation, status: "accepted"}] : prev.past
            }
        });
    }, [invitations, openCompetitions]);

    const handleUnenroll = useCallback(async (competitionId: number) => {
        const data = {
            enrollmentUpdate: {
                competitionId: competitionId,
                isEnrolled: false,
                chosenInstitutionId: null
            }
        };

        await DataAccess.post(`/api/competition/openEnroll.json`, {data: data});
    }, []);

    const rejectInvitation = useCallback(async (competitionId: number) => {
        const data = {
            invitationUpdate: {
                competitionId: competitionId,
                status: "rejected" as InvitationStatus,
                chosenInstitutionId: null
            }
        };

        await DataAccess.put("/api/invitation/publish.json", {data: data});

        // set local state
        setInvitations(prev => {
            const foundInvitation = prev.current.find(c => c.competitionId === competitionId);
            return {
                current: prev.current.filter(c => c.competitionId !== competitionId),
                past: foundInvitation ? [...prev.past, {...foundInvitation, status: "rejected"}] : prev.past
            }
        });
        setOpenCompetitions(prev => prev.filter(c => c.competitionId !== competitionId));

    }, []);

    return {
        requestStatus: requestStatus,
        invitations: invitations,
        openCompetitions: openCompetitions,
        enrollTab: enrollTab,
        handleTabChange: handleTabChange,
        handleEnroll: handleEnroll,
        handleUnenroll: handleUnenroll,
        rejectInvitation: rejectInvitation
    }
}