import React, {useState} from "react";
import {useTheme, Container, Grid, Button, Typography} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useParams} from "react-router-dom";
import {DataAccess} from "../../util";

export function ConfirmEmailReminder() {

    const {email} = useParams();
    const theme = useTheme();
    const [submitted, setSubmitted] = useState<boolean>(false);

    const handleResendConfirmationEmail = async () => {
        const data = {email: decodeURIComponent(email as string)};
        try {
            await DataAccess.post("/api/user/resendConfirmationEmail.json", {data: data});
            setSubmitted(true);
        } catch (e) {
            alert("There was an error when resending your confirmation email.  Please try again later.");
            console.log(e);
        }
    }

    return (
        <Container sx={{
            bgcolor: theme.palette.background.paper,
            width: "100%",
            minWidth: "350px",
            maxWidth: "500px",
            mt: {xs: "25%", sm: "17%"},
            pt: 3
        }}>
            <Grid container>
                <Grid item xs={12} sx={{textAlign: "center"}}>
                    <ErrorOutlineIcon color="secondary" fontSize="large" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{mt: 5}}>
                        Please verify your email before you log in.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 5}}>
                        We require a valid email address on file so that we can email you alerts when FocusRing items become due.  Check your email for a verification link to confirm your email address.  It can take up to 10 minutes for the verification email to arrive.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 5}}>
                        If you'd like to have your verification email re-sent, then please click the button below.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 5}}>
                        It can take up to 10 minutes for the verification email to arrive.  If you do not see the email in the next 10-15 minutes, please check your 'junk mail' or 'spam' folder.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{textAlign: "center"}}>
                    <Button
                        sx={{mt: 5, mb: 5}}
                        variant="contained"
                        onClick={handleResendConfirmationEmail}
                        disabled={submitted}
                    >
                        {submitted ? "Submitted" : "Resend Confirmation Email"}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}