import React, {useState} from "react";
import {Modal, Box, Typography, Grid, Divider, Button, FormControlLabel, Checkbox, Paper} from "@mui/material";
import {useCompetitionLegalAgreement} from "../../hooks";
import {LoadingSpinner} from "../../components";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

interface LegalAgreementModalProps {
    open: boolean;
    confirmAction: () => void;
    legalAgreementUuid: string | null;
    handleCancel: () => void;
}

export function LegalAgreementModal({open, confirmAction, legalAgreementUuid, handleCancel}: LegalAgreementModalProps) {

    const [agreed, setAgreed] = useState<boolean>(false);
    const {requestStatus, agreementUrl} = useCompetitionLegalAgreement(legalAgreementUuid);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: 700},
        maxHeight: 700,
        overflowY: "auto",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={open}
        >
            <Box sx={style} component="div">
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Typography variant="h4">
                            Terms Agreement
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{mb: 3}}>
                            Please read and agree to the below terms of this event.  Failure to agree to the terms will block your entry to the event.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {requestStatus === "loading" ?
                            <LoadingSpinner /> :
                            requestStatus === "complete" ?
                                <Box sx={{textAlign: "center"}} component="div">
                                    <Button
                                        component="a"
                                        target="_blank"
                                        variant="outlined"
                                        href={agreementUrl}
                                        startIcon={<PictureAsPdfIcon />}
                                    >
                                        Open in New Tab
                                    </Button>
                                </Box> :
                                <Typography variant="body1">There was an error retrieving the legal agreement.</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} sx={{my: 3}}>
                        <Paper component={Box} elevation={1} sx={{textAlign: "center", p: 2}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // disabled={requestStatus === "error"}
                                        checked={agreed}
                                        onChange={() => setAgreed(prev => !prev)}
                                    />
                                }
                                label="I have read and agree to the terms of this event."
                                name="agreeToTerms"
                            />
                        </Paper>
                    </Grid>
                    <Grid container spacing={2} item xs={12} sx={{textAlign: "center"}}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!agreed}
                                onClick={confirmAction}
                            >
                                Agree and Submit
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={agreed}
                                onClick={handleCancel}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}