import React from "react";
import {Grid, Paper, Box, Typography} from "@mui/material";
import {RequestStatus} from "../../data-types";
import {ErrorComponent, LoadingSpinner, VideoPlayer} from "../../components";
import {VideoJsPlayerOptions} from "video.js";

interface ResponseContentProps {
    responseRequestStatus: RequestStatus;
    content?: VideoResponseContent;
}

interface VideoResponseContent {
    videoPrompt: VideoJsPlayerOptions;
    videoResponse: VideoJsPlayerOptions | null;
}

export function ResponseContent({responseRequestStatus, content}: ResponseContentProps) {

    if (responseRequestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (responseRequestStatus === "error") {
        return <ErrorComponent />;
    } else {

        const videoOptions = (content) ? content.videoPrompt : null;

        return (
                <Grid container item xs={12} sm={12} md={6}>
                    <Paper elevation={2} sx={{p: 2, width: "100%"}}>
                        <Grid item xs={12}>
                            <Box component="div" sx={{m: 2, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Typography variant="h6">
                                    Video Prompt
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box component="div" sx={{height: "24px", mb: 2}} />
                            <VideoPlayer
                                options={videoOptions}
                                height={400}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box component="div" sx={{height: '100%', mt: 2, p: 2, display: "flex", flexDirection: "column", gap: 2}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Directions
                                </Typography>
                                <Typography variant="subtitle2">
                                    1.)    Watch the video prompt.
                                </Typography>
                                <Typography variant="subtitle2">
                                    2.)    Click Start Recording to begin your 1-minute max response.
                                </Typography>
                                <Typography variant="subtitle2">
                                    3.)    Review your response before saving by clicking Review and Save.
                                </Typography>
                                <Typography variant="subtitle2">
                                    4.)    Click Retry, then Start Recording to record your response again.
                                </Typography>
                            </Box>
                        </Grid>
                    </Paper>
                </Grid>
        )
    }
}