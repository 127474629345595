import React, {useState} from "react";
import {Paper, Grid, Typography, Button, Box, Tooltip, Chip} from "@mui/material";
import {Link} from "react-router-dom"
import {VideoPlaceholder, VideoPlayer} from "../../components";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import {VideoJsPlayerOptions} from "video.js";
import {InstitutionGrouping} from "./InstitutionGrouping";
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ErrorIcon from '@mui/icons-material/Error';

interface OptionalStepCardProps {
    institutionGrouping: InstitutionGrouping;
    roundId: string | undefined;
}
export function OptionalStepCard({institutionGrouping, roundId}: OptionalStepCardProps) {

    const [selected, setSelected] = useState<number>(0);
    const videoOptions: VideoJsPlayerOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        sources: institutionGrouping.prompts[selected]?.sources ?? [],
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    const handleChipClick = (index: number) => {
        setSelected(index);
    }

    return (
        <Paper elevation={1}>
            <Grid container justifyContent="center" alignItems="center" sx={{mb: 5}}>
                {(institutionGrouping.prompts[selected].videoId === undefined) ?
                    <Tooltip title={"You don't have access to this video."}>
                        <Grid item xs={9} sm={3}>
                            <VideoPlaceholder height={225}>
                                <DoDisturbIcon fontSize="large" />
                            </VideoPlaceholder>
                        </Grid>
                    </Tooltip> :
                    <Grid item xs={9} sm={3}>
                        <VideoPlayer
                            options={videoOptions}
                            height={225}
                        />
                    </Grid>
                }
                <Grid item xs={3} sm={3} sx={{p: 2}}>
                    <Box component="div" sx={{display: "flex", alignItems: "center", my: 3}}>
                        <BusinessIcon />
                        <Typography variant="h6" sx={{px: 3}}>
                            {institutionGrouping.institutionName}
                        </Typography>
                    </Box>
                    <Box component="div" sx={{display: "flex", alignItems: "center", my: 3}}>
                        <CheckCircleIcon fontSize="small" />
                        <Typography variant="subtitle2" sx={{px: 3}}>
                            {`Responses published:  ${institutionGrouping.prompts[selected].numberPublishedResponses} / ${institutionGrouping.prompts[selected].numberResponses}`}
                        </Typography>
                    </Box>
                    {institutionGrouping.prompts[selected].numberReportedResponses > 0 ?
                        <Box component="div" sx={{display: "flex", alignItems: "center", my: 3}}>
                            <ErrorIcon fontSize="small" color="secondary" />
                            <Typography variant="subtitle2" sx={{px: 3}}>
                                Reports available
                            </Typography>
                        </Box> :
                        null
                    }
                    <Box component="div" sx={{display: "flex", alignItems: "center", my: 3}}>
                        <Typography variant="subtitle2" sx={{px: 3}}>
                            Prompts:
                        </Typography>
                        {institutionGrouping.prompts.map((c, i) =>
                            <Chip
                                key={i}
                                label={i + 1}
                                onClick={() => handleChipClick(i)}
                                color={selected === i ? "primary" : "default"}
                                sx={{mr: 1}}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid container spacing={2} item xs={12} sm={6} sx={{textAlign: "center", p: 2}}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<OpenInNewIcon />}
                            component={Link}
                            to={`/admin/videoPrompts/detail/${institutionGrouping.prompts[selected].videoId}`}
                            disabled={!institutionGrouping.prompts[selected].videoId}
                        >
                            View Prompt
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<KeyboardVoiceIcon />}
                            component={Link}
                            to={`/admin/round/detail/${roundId}/scriptStep/detail/${institutionGrouping.prompts[selected].scriptStepId}`}
                            disabled={!institutionGrouping.prompts[selected].videoId}
                        >
                            View Responses
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}