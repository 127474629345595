import React, {useState} from "react";
import {Grid, Typography, Button, Paper, Box} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {TutorialModal} from "../../components";
import ResponseTutorialVideo from "../../video/response_tutorial.mp4";

interface VideoResponseMeta {
    responseId: number;
    saved: boolean;
    institutionName: string | null;
}

interface ResponseMetaProps {
    selectedResponse: number;
    responseMeta: VideoResponseMeta[];
    handleSwitchPrompt: (responseId: number) => void;
    disableAll: boolean;
    resetRecording: () => void;
    submit: () => void;
}

export function ResponseMeta({selectedResponse, responseMeta, handleSwitchPrompt, disableAll, submit, resetRecording}: ResponseMetaProps) {

    const [tutorialOpen, setTutorialOpen] = useState<boolean>(false);
    const canSubmit = responseMeta.every(c => c.saved);

    return (
        <>
            <Grid container spacing={2} sx={{mb: 5}} alignItems="center" justifyContent="space-between">
                <Grid item xs={4}>
                    <Typography variant="h4">
                        Prompts
                    </Typography>
                </Grid>
                <Grid item xs={8} container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setTutorialOpen(true)}
                            startIcon={<PlayCircleIcon />}
                        >
                            Tutorial
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submit}
                            disabled={!canSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mb: 5}} alignItems="center">
                {responseMeta.map((c, i) =>
                    <Grid
                        item
                        key={i}
                    >
                        <Paper
                            elevation={1}
                            component={Button}
                            onClick={() => {
                                handleSwitchPrompt(c.responseId);
                                resetRecording();
                            }}
                            sx={c.responseId === selectedResponse ? {border: "2px solid green", p: 1} : {border: "2px solid rgba(0, 0, 0, 0.0)", p: 1}}
                            disabled={disableAll}
                        >
                            <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{p: 1}}>
                                <Grid item>
                                    <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                                        <Typography variant="h6">
                                            {i + 1}
                                        </Typography>
                                        {c.saved ?
                                            <CheckCircleIcon color="primary" fontSize="small"  sx={{ml: 3}} /> :
                                            null
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <TutorialModal
                open={tutorialOpen}
                setOpen={setTutorialOpen}
                video={ResponseTutorialVideo}
                title={"How-to:  Response Phase"}
            >
                <Typography variant="subtitle2" sx={{mb: 2, mt: 5}}>
                    During the Response Phase, you will respond to one or more prompts with video.
                </Typography>
                <Typography variant="subtitle2" sx={{mb: 5}}>
                    Please see the included video for more instruction.
                </Typography>
            </TutorialModal>
        </>
    )
}