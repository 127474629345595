import React from "react";
import {CompetitionCardProps} from "../../data-types";
import {
    Card, CardContent, CardHeader, Box, Avatar,
    Grid, Typography, Button, List, ListItemIcon,
    ListItem, ListItemText, Paper, Chip, useTheme,
    Tooltip
} from "@mui/material";
import {RankIcon} from "../../icons";
import ReplyIcon from '@mui/icons-material/Reply';
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PollIcon from "@mui/icons-material/Poll";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import BusinessIcon from '@mui/icons-material/Business';
import {parseISO, formatDistance, isAfter, format, subHours} from "date-fns";
import {Link} from "react-router-dom";

export function ActiveEventCard({competitionMeta, roundStatus, userStatistics, participantStatus, handleSelectRoundModalOpen, handleUnenrollClick, position}: CompetitionCardProps) {
    const theme = useTheme();
    let now = new Date();

    let phaseIcon, phaseTextTop, phaseTextBottom, actionButton;

    if (roundStatus) {

        if (roundStatus.optionalScriptSteps && roundStatus.optionalScriptSteps.length !== 0) {
            // have to open the modal and select institutions
            phaseIcon = <BusinessIcon />;
            phaseTextTop = "Please choose which institutions you want to respond to";
            phaseTextBottom = `Due:  ${format(parseISO(roundStatus.responseEndDate + "Z"), "Pp")} (${formatDistance(parseISO(roundStatus.responseEndDate + "Z"), now)})`;
            actionButton =
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{mb: 2}}
                    onClick={() => handleSelectRoundModalOpen(roundStatus.optionalScriptSteps)}
                >
                    Select Institutions
                </Button>

        } else {

            switch (roundStatus.phase) {
                case "review": {
                    phaseIcon = <PollIcon />;
                    if (roundStatus.roundSubmitted) {
                        phaseTextTop = "All done!";
                        phaseTextBottom = "Thank you for submitting your reviews."

                    } else {
                        phaseTextTop = `${roundStatus.todo!.submitted} / ${roundStatus.todo!.required}  reviews completed`;
                        phaseTextBottom = `Due:  ${format(parseISO(roundStatus.reviewEndDate + "Z"), "Pp")} (${formatDistance(parseISO(roundStatus.reviewEndDate + "Z"), now)})`;
                    }
                    actionButton =
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={Boolean(roundStatus.roundSubmitted)}
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/video-review/${competitionMeta.competitionId}`}
                        >
                            Review
                        </Button>
                    break;
                }
                case "response": {
                    phaseIcon = <KeyboardVoiceIcon />;
                    if (roundStatus.roundSubmitted) {
                        phaseTextTop = "All done!";
                        phaseTextBottom = "Thank you for submitting your responses."
                    } else {
                        phaseTextTop = `${roundStatus.todo!.submitted} / ${roundStatus.todo!.required}  responses completed`;
                        phaseTextBottom = `Due:  ${format(parseISO(roundStatus.responseEndDate + "Z"), "Pp")} (${formatDistance(parseISO(roundStatus.responseEndDate + "Z"), now)})`;
                    }
                    actionButton =
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={Boolean(roundStatus.roundSubmitted)}
                            sx={{mb: 2}}
                            fullWidth
                            component={Link}
                            to={`/video-response/${competitionMeta.competitionId}`}
                        >
                            Respond
                        </Button>
                    break;
                }
                case "proxy": {
                    phaseIcon = <PollIcon />;
                    phaseTextTop = "The current round is in the proxy review phase.";
                    phaseTextBottom = "Please await the next round";
                    break;
                }
                case "standby": {
                    let nextPhase = "Response Phase";
                    let timeTop = format(parseISO(roundStatus.responseStartDate + "Z"), "Pp");
                    let timeBottom = formatDistance(parseISO(roundStatus.responseStartDate + "Z"), now);

                    if (roundStatus.hasProxy && isAfter(now, parseISO(roundStatus.reviewStartDate + "Z"))) {
                        nextPhase = "Proxy Phase";
                        timeTop = format(parseISO(roundStatus.proxyStartDate + "Z"), "Pp");
                        timeBottom = formatDistance(parseISO(roundStatus.proxyStartDate + "Z"), now);
                    } else if (isAfter(now, parseISO(roundStatus.responseStartDate + "Z"))) {
                        nextPhase = "Review Phase";
                        timeTop = format(parseISO(roundStatus.reviewStartDate + "Z"), "Pp");
                        timeBottom = formatDistance(parseISO(roundStatus.reviewStartDate + "Z"), now);
                    }

                    phaseIcon = <HourglassTopIcon />;
                    phaseTextTop = `Awaiting next phase`;
                    phaseTextBottom = `${nextPhase} starts on ${timeTop} (${timeBottom})`;
                    actionButton = null;
                    break;
                }
                case "error": {
                    phaseIcon = <ErrorIcon />;
                    phaseTextTop = `There is an error with the current event`;
                    phaseTextBottom = `Please contact the event organizer`;
                    actionButton = null;
                    break;
                }
                default: { // finished
                    phaseIcon = <CheckCircleIcon />;
                    phaseTextTop = `The current round is complete!`;
                    phaseTextBottom = `Results and rankings are now posted`;
                    actionButton =
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{width: "100%"}}
                            href="./results"
                        >
                            See Results
                        </Button>
                }
            }
        }
    }
    else {
        phaseIcon = <ErrorIcon />;
        phaseTextTop = `There are no rounds currently available`;
        phaseTextBottom = `Please wait until the next round is scheduled`;
        actionButton = null;
    }

    // can only unenroll if no rounds finished and there is either no current round or current round response phase
    // end time is after current time.
    const canUnenroll = (!competitionMeta.roundsFinished) && (!roundStatus || isAfter(parseISO(roundStatus.responseEndDate + "Z"), now));

    // unenroll is disabled if competition has a payment and first round response phase end is within 24 hours.
    const unenrollDisabled = !!competitionMeta.paymentParticipant &&
        (roundStatus && isAfter(now, subHours(parseISO(roundStatus.responseEndDate + "Z"), 24)));

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar>{position}</Avatar>
                }
                title={competitionMeta.name}
                titleTypographyProps={{
                    variant: "h5",
                    noWrap: true
                }}
                subheader={competitionMeta.paymentParticipant ?
                    `Event fee: $${(competitionMeta.paymentParticipant / 100).toFixed(2)}` :
                    ""
                }
            />
            <CardContent sx={{pt: 0}}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} md={7} justifyContent="space-around" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="body2" sx={{pl: 7}}>{competitionMeta.description}</Typography>
                            {competitionMeta.paymentParticipant ?
                                <Button
                                    sx={{ml: 7}}
                                    startIcon={<ReplyIcon />}
                                    component={Link}
                                    variant="outlined"
                                    size="small"
                                    color="info"
                                    to={`/receipt/${competitionMeta.competitionId}`}
                                >
                                    Receipt
                                </Button> :
                                null
                            }
                            <Box component="div" sx={{mt: 2, pl: 7}}>
                                {competitionMeta.tags.map((c, i) => (
                                        <Chip
                                            sx={{mr: 2, bgcolor: theme.palette.primary.light}}
                                            variant="filled"
                                            label={c.name}
                                            key={i}
                                            size="small"
                                        />
                                    )
                                )}
                            </Box>
                        </Grid>
                        {competitionMeta.roundsFinished ?
                            <Grid item container spacing={2} sx={{mt: 2}}>
                                <Grid item xs={6}>
                                    <Box component="div" sx={{textAlign: 'center'}}>
                                        <Typography variant="body1">
                                            Score
                                        </Typography>
                                        <Typography variant="h6" component="span">
                                            {userStatistics ? userStatistics.currentScore.toFixed(2) : "N/A"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box component="div" sx={{textAlign: 'center'}}>
                                        <Typography variant="body1">
                                            Rank
                                        </Typography>
                                        <RankIcon rank={userStatistics ? userStatistics.percentile : 0.5} />
                                    </Box>
                                </Grid>
                            </Grid> :
                            null
                        }
                    </Grid>
                    <Grid item xs={12} md={5} order={{xs: 3, md: 2}}>
                        {participantStatus.eliminated ?
                            <Box component="div"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    pt: 3,
                                    pb: 3,
                                }}
                            >
                                <Typography variant="body1" sx={{textAlign: "center", mb: 2}}>
                                    You have been disqualified from this event.
                                </Typography>
                                <Typography variant="body1">
                                    {`Reason:  ${participantStatus.eliminationReason}`}
                                </Typography>
                            </Box> :
                            <>
                                <List>
                                    <Paper elevation={3}>
                                        <ListItem
                                            sx={{mb: 1, borderRadius: 1}}
                                            dense={true}
                                            secondaryAction={(roundStatus && (roundStatus.phase === "response" || roundStatus.phase === "review") && (!roundStatus.roundSubmitted)) ? <Tooltip title="Please Complete and Submit"><ErrorIcon color="secondary"/></Tooltip> : null}
                                        >
                                            <ListItemIcon>
                                                {phaseIcon}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={phaseTextTop}
                                                secondary={phaseTextBottom}
                                            />
                                        </ListItem>
                                    </Paper>
                                </List>
                                {actionButton}
                                {canUnenroll ?
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        disabled={unenrollDisabled}
                                        onClick={() => {
                                            handleUnenrollClick && handleUnenrollClick(competitionMeta.competitionId);
                                        }}
                                    >
                                        Unenroll
                                    </Button> :
                                    null
                                }
                            </>
                        }
                        {competitionMeta.roundsFinished ?
                            <>
                                <Button
                                    variant="outlined"
                                    color="info"
                                    sx={{mb: 2}}
                                    fullWidth
                                    component={Link}
                                    to={`/myResults/${competitionMeta.competitionId}/reviewsOfMe`}
                                >
                                    Reviews of Me
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="info"
                                    sx={{mb: 2}}
                                    fullWidth
                                    component={Link}
                                    to={`/myResults/${competitionMeta.competitionId}/results`}
                                >
                                    My Results
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="info"
                                    sx={{mb: 2}}
                                    fullWidth
                                    component={Link}
                                    to={`/myResults/${competitionMeta.competitionId}/rankings`}
                                >
                                    Current Rankings
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="info"
                                    sx={{mb: 2}}
                                    fullWidth
                                    component={Link}
                                    to={`/leaders/${competitionMeta.competitionId}`}
                                >
                                    View Leaders
                                </Button>
                            </> :
                            null
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}