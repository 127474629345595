import {ChangeEvent, useState, useCallback} from "react";
import {CreateAccountState} from "../../data-types";
import {DataAccess, DataFormatting} from "../../util";

type QueryParams = {
    [key: string]: string;
};
export const useAccountCreate = () => {
    const [accountState, setAccountState] = useState<CreateAccountState>({
        username: "",
        password: "",
        confirmPassword: "",
        firstname: "",
        lastname: "",
        email: ""
    });

    const [params, setParams] = useState<QueryParams>({});

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setAccountState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, []);

    const handleQueryParams = useCallback((params: URLSearchParams) => {
        const email = params.get("email");

        const _params: QueryParams = {};
        params.forEach((v, k) => {
            _params[k] = v
        })

        setParams(_params);
        if (email) {
            setAccountState(prev => {
                return {
                    ...prev,
                    email: email
                }
            })
        }
    }, []);

    const createAccount = useCallback(async (newAccount: CreateAccountState) => {
        const isAccountValid = () => {
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (newAccount.firstname === "") {
                throw new Error("Please enter your first name");
            }
            if (newAccount.lastname === "") {
                throw new Error("Please enter your last name");
            }
            if (!emailRegex.test(newAccount.email)) {
                throw new Error("Please enter a valid email address");
            }
            if (newAccount.username === "") {
                throw new Error("Please give yourself a username");
            }
            // if (newAccount.password === "") {
            //     throw new Error("Please enter a password");
            // }
            if (newAccount.password !== newAccount.confirmPassword) {
                throw new Error("Please check your password and confirm it matches.");
            }
            DataFormatting.isValidPassword(newAccount.password);
        };

        await isAccountValid();
        const data = {createUser: newAccount};
        await DataAccess.post("/api/user/create.json", {data: data});
    }, []);

    return {
        accountState: accountState,
        handleChange: handleChange,
        createAccount: createAccount,
        handleQueryParams: handleQueryParams,
        params: params
    }
}