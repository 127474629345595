import {useState, useEffect} from "react";
import {RequestStatus, VideoSource} from "../../data-types";
import {DataAccess} from "../../util";

interface ScriptDetailHeader {
    promptVideoId: number;
    promptVideoUuid: string;
    promptSources: VideoSource[];
    competitionName?: string;
    scriptName?: string;
    roundNumber?: number;
    heatSize?: number;
}

export const useScriptStepDetail = (roundId: string | undefined, scriptStepId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [scriptDetailHead, setScriptDetailHead] = useState<ScriptDetailHeader>({
        promptVideoId: -1,
        promptVideoUuid: "",
        promptSources: [],
        competitionName: "",
        scriptName: "",
        roundNumber: -1,
        heatSize: -1
    });

    useEffect(() => {

        const headerController = new AbortController();

        const getHeader = async () => {
            if (scriptStepId) {
                const r = await DataAccess.get(`/api/scriptStep/${roundId}/${scriptStepId}/getDetailHeader.json`, {signal: headerController.signal});
                setScriptDetailHead(r.scriptStepDetail);
            }
        }

        getHeader()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                setRequestStatus("error");
                console.log(e);
            })

        return () => {
            headerController.abort();
        }

    }, [scriptStepId]);

    return {
        requestStatus: requestStatus,
        scriptStepDetail: scriptDetailHead
    }
}