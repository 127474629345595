import React from "react";
import {DateProgressBarProps} from "../../data-types";
import {Grid, LinearProgress, Typography} from "@mui/material";
import {parseISO, formatDistance, getUnixTime, isAfter} from "date-fns";

export function DateProgressBar({start, end}: DateProgressBarProps) {
    // dates
    let now = new Date();
    let startDate = parseISO(start + "Z");
    let endDate = parseISO(end + "Z");

    // dates in unix
    let nowUnix = getUnixTime(now);
    let startUnix = getUnixTime(startDate);
    let endUnix = getUnixTime(endDate);

    // intervals
    let startEndInterval = endUnix - startUnix;
    let nowStartInterval = nowUnix - startUnix;

    // progress value 0 - 100
    let progressValue = Math.min(100 * (Math.max(nowStartInterval, 0) / startEndInterval), 100);
    let distanceString;
    // if now is before start, then show "starts in x"
    if (nowStartInterval < 0) {
        distanceString = `Enrollment starts in ${formatDistance(startDate, now)}`;
    } else if (isAfter(endDate, now)) {
        distanceString = `Ends in ${formatDistance(now, endDate)}`;
    } else {
        distanceString = `Ended ${formatDistance(endDate, now, {addSuffix: true})}`;
    }

    return (
        <Grid container item spacing={1} justifyContent="center" alignItems="center" sx={{mt: 3}}>
            <Grid item xs={0} sm={2} md={2} />
            <Grid item xs={12} sm={8} md={8} sx={{textAlign: 'center'}}>
                <LinearProgress
                    value={progressValue}
                    color={progressValue < 100 ? "primary" : "secondary"}
                    variant="determinate"
                />
                <Typography variant="subtitle2" color={progressValue < 100 ? "primary" : "secondary"} >
                    {distanceString}
                </Typography>
            </Grid>
            <Grid item xs={0} sm={2} md={2} />
        </Grid>
    )
}
