import React from "react";
import {Grid, Container, Card, CardContent, CardActionArea, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export function ReviewerDashboard() {

    return (
        <Container sx={{mb: 10}}>
            <Typography variant="h4" sx={{mb: 8}}>
                Reviewer Dashboard
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <Card>
                        <CardActionArea component={Link} to={'/reviewer/competitions'}>
                            <CardContent>
                                <Typography variant="h4">
                                    Events
                                </Typography>
                                <Typography variant="body1">
                                    Record proxy evaluations for selected Events
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}