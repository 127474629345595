import {useState, useEffect} from "react";
import {AdminCompetitionDetail, RequestStatus} from "../../data-types";
import {DataAccess} from "../../util";

export const useCompetitionDetail = (competitionId: string | undefined) => {

    const [requestStatus, setRequestStatus] = useState<RequestStatus>("loading");
    const [competitionDetail, setCompetitionDetail] = useState<AdminCompetitionDetail | null>(null);

    useEffect(() => {

        const controller = new AbortController();
        const getCompetitionDetail = async () => {
            if (competitionId) {
                const r = await DataAccess.get(`/api/competition/${competitionId}/detailAdmin.json`, {signal: controller.signal});
                setCompetitionDetail(r);
            }
        }

        getCompetitionDetail()
            .then(_ => {
                setRequestStatus("complete");
            })
            .catch(e => {
                console.log(e);
                setRequestStatus("error");
            });

        return ( )=> {
            controller.abort();
        }

    }, [competitionId]);

    return {
        requestStatus: requestStatus,
        competitionDetail: competitionDetail
    }
}