import React, {useState} from "react";
import {Grid, Modal, Box, TextField, Button, Typography} from "@mui/material";
import {ReviewGroupModalProps} from "../../data-types";
import {DataAccess} from "../../util";
import {useNavigate} from "react-router-dom";

export function CopyReviewGroupModal({reviewGroup, setReviewGroup}: ReviewGroupModalProps) {
    const navigate = useNavigate();
    const [newGroupName, setNewGroupName] = useState<string>("");
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const submit = () => {
        if (reviewGroup && newGroupName !== "") {
            DataAccess.post(`/api/reviewGroup/${reviewGroup.groupId}/copy.json`, {data: {groupName: newGroupName}})
                .then(r => {
                    setReviewGroup(null);
                    navigate(0)
                })
                .catch(e => {
                    console.log(e);
                })
        }
    }

    return (
        <Modal
            open={!!reviewGroup}
            onClose={() => {
                setReviewGroup(null);
                setNewGroupName("");
            }}
        >
            <Box sx={style} component="div">
                <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {reviewGroup ? `Copy:  ${reviewGroup.groupName}` : ``}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="New Group Name"
                            fullWidth={true}
                            name="newGroupName"
                            value={newGroupName}
                            inputProps={{size: 40}}
                            onChange={(e) => setNewGroupName(e.target.value)}
                            sx={{mb: 3}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => submit()}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}