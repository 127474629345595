import React from "react";
import {Grid, Box, Typography, useMediaQuery, Tooltip} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {VideoPlayer} from "../../components";
import {ReviewSectionProps} from "../../data-types";
import {DataFormatting} from "../../util";
import {rawScoreColDefs} from "./ColDefs";

type AttributeRow = {
    [key: string]: number;
}
export function ReviewSection({review}: ReviewSectionProps) {

    const isSmall = useMediaQuery('(max-width: 900px)');
    const responseVideoOptions = DataFormatting.videoToVideoJSOptions(review.videoResponse);
    const promptVideoOptions = DataFormatting.videoToVideoJSOptions(review.videoPrompt);

    // [{attributeName1: score, attributeName2: score, ... etc}, ...]
    let attributeData = review.reviews.reduce<AttributeRow[]>((p, c) => {
        if (c.attributes.length === 0) {
            return p;
        } else {
            let a: AttributeRow = c.attributes.reduce<AttributeRow>((pp, cc) => {
                return {...pp, [cc.attributeName]: cc.attributeScore}
            }, {});
            return [...p, a];
        }
    }, []);

    let attributeColDefs: GridColDef[];
    if (attributeData.length === 0) {
        attributeColDefs = [];
    } else {
        // map keys of first data object to GridColDef
        attributeColDefs = Object.keys(attributeData[0]).map(c => {
            return {field: c, headerName: c.charAt(0).toUpperCase() + c.slice(1), flex: 1}
        })
    }

    // add an id field to each AttributeRow
    attributeData = attributeData.map((c, i) => {
        return {...c, id: i};
    })

    return (
        <Grid container spacing={2} sx={{p: 2, mb: 5}} alignItems="flex-start">
            <Grid item xs={12} sm={12} md={6}>
                <Typography variant="body1" sx={{mb: 2}}>
                    {`Prompt:  ${review.videoPrompt.name}`}
                </Typography>
                {
                    review.didNotFinishReviews ?
                        <Tooltip
                            title="The scores for this response have been reduced because this user did not complete all their reviews."
                            placement="top"
                        >
                            <Box component="div" sx={{my: 2}}>
                                <Typography variant="subtitle2">
                                    <ErrorIcon color="secondary" sx={{verticalAlign: "middle"}} /> Incomplete Reviews
                                </Typography>
                            </Box>
                        </Tooltip> :
                        null
                }
                {
                    review.reportedDockScore ?
                        <Tooltip
                            title="This response was reported by another user.  The creator of the event has decided to reduce these scores."
                            placement="top"
                        >
                            <Box component="div" sx={{my: 2}}>
                                <Typography variant="subtitle2">
                                    <ErrorIcon color="secondary" sx={{verticalAlign: "middle"}} /> Reported Response
                                </Typography>
                            </Box>
                        </Tooltip> :
                        null
                }
                <VideoPlayer
                    options={responseVideoOptions}
                />
            </Grid>
            <Grid container item xs={12} sm={12} md={6} sx={{p: 2}}>
                {/*  Prompt video hidden on mobile  */}
                {isSmall ?
                    null :
                    <Grid container item xs={0} sm={0} md={12} sx={{mb: 2}}>
                        <Grid item xs={0} sm={0} md={4} />
                        <Grid item xs={0} sm={0} md={4}>
                            <VideoPlayer
                                options={promptVideoOptions}
                                height={140}
                            />
                        </Grid>
                        <Grid item xs={0} sm={0} md={4} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <DataGrid
                        columns={rawScoreColDefs}
                        rows={review.reviews}
                        getRowId={(row) => row.reviewId}
                        initialState={{pagination: {paginationModel: {pageSize: 6}}}}
                        pageSizeOptions={[6, 12, 24]}
                        autoHeight={true}
                        sx={{mb: 3}}
                    />
                    {attributeData.length === 0 ?
                        <Typography variant="h6">
                            No attribute data to show currently.
                        </Typography> :
                        <DataGrid
                            columns={attributeColDefs}
                            rows={attributeData}
                            autoHeight={true}
                            initialState={{pagination: {paginationModel: {pageSize: 6}}}}
                            pageSizeOptions={[6, 12, 24]}
                        />
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}