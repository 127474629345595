import React, {useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {VideoReviewContent} from "./VideoReviewConfig";
import {useVideoReviewConfig} from "./useVideoReviewConfig";
import {ErrorComponent, LoadingSpinner, ConfirmActionModal} from "../../components";
import {toast} from "react-toastify";
import {formatError} from "../../util";
import {ContentSection} from "./ContentSection";
import {MetaSection} from "./MetaSection";
import {ReportVideoModal} from "./ReportVideoModal";

interface VideoReviewPageProps {
    isProxy: boolean;
}

export function VideoReview({isProxy}: VideoReviewPageProps) {
    const {competitionId} = useParams();
    const navigate = useNavigate();
    const {
        metaRequestStatus,
        reviewRequestStatus,
        promptToggle,
        videoReviewState,
        handlePromptChange,
        handleResponderChange,
        handleScoreChange,
        handleCommentChange,
        handleSaveReview,
        handleHideReview,
        handleSubmit,
        selectedPrompt,
        selectedResponder,
        toggleView,
        standardAttributes,
        handleStandardAttributeChange
    } = useVideoReviewConfig(competitionId, isProxy);

    // reporting review state
    const [reportedReviewId, setReportedReviewId] = useState<number>(NaN);
    const [reportModalOpen, setReportModalOpen] = useState<boolean>(false);
    const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);

    // filter content for ContentSection
    let _content: VideoReviewContent[];
    if (promptToggle) {
        _content = (isNaN(selectedPrompt)) ? [] : videoReviewState.filter(c => c.promptId === selectedPrompt);
    } else {
        _content = (isNaN(selectedResponder)) ? [] : videoReviewState.filter(c => c.revieweeId === selectedResponder);
    }

    const handleToggle = async () => {
        if (promptToggle) {
            await handleResponderChange(selectedResponder);
        } else {
            await handlePromptChange(selectedPrompt);
        }
        toggleView();
    }

    // wrap with toast
    const handleSave = (content: VideoReviewContent) => {
        handleSaveReview(content)
            .then(_ => toast.success("Review Saved Successfully"))
            .catch(e => {
                console.log(e);
                toast.error(formatError(e));
            })
    }

    const submit = () => {
        handleSubmit()
            .then(_ => {
                toast.success("Your reviews have been submitted.");
                navigate("/myEvents");
            })
            .catch(e => {
                console.log(e)
                toast.error(formatError(e));
            });
    }

    const handleStartReport = (reviewId: number) => {
        setReportedReviewId(reviewId);
        setReportModalOpen(true);
    }

    const handleReport = (comments: string) => {
        const reportedContent = videoReviewState.find(c => c.reviewId === reportedReviewId);
        if (reportedContent && reportedContent.review) {
            // to avoid score of 0, set raw = 1.0 if 0
            if (reportedContent.review.rawScore === 0) {
                reportedContent.review.rawScore = 1.0
            }
            const content: VideoReviewContent = {...reportedContent, review: {...reportedContent.review, isReported: true, comments: comments}};
            handleSaveReview(content)
                .then(_ => toast.success("Thank you for submitting this report."))
                .catch(e => {
                    toast.error("There was a problem with reporting this review.");
                    console.log(e);
                })
        }
        setReportedReviewId(NaN);
        setReportModalOpen(false);
    }

    const handleOpenSubmit = () => {
        setSubmitModalOpen(true);
    }

    if (metaRequestStatus === "loading") {
        return <LoadingSpinner />
    } else if (metaRequestStatus === "error") {
        return <ErrorComponent />
    } else {
        return (
            <Box component="div" sx={{mt: -8}}>

                {/* Review Toggle Header */}
                <MetaSection
                    content={videoReviewState}
                    promptToggle={promptToggle}
                    selectedPrompt={selectedPrompt}
                    selectedResponder={selectedResponder}
                    handleHideReview={handleHideReview}
                    handleToggle={handleToggle}
                    toggleHandler={promptToggle ? handlePromptChange : handleResponderChange}
                    submit={handleOpenSubmit}
                />

                {/* Review Content */}
                <ContentSection
                    promptToggle={promptToggle}
                    contentRequestStatus={reviewRequestStatus}
                    content={_content}
                    handleScoreChange={handleScoreChange}
                    handleCommentChange={handleCommentChange}
                    handleSaveReview={handleSave}
                    handleStartReport={handleStartReport}
                    standardAttributes={standardAttributes}
                    handleStandardAttributeChange={handleStandardAttributeChange}
                />

                {/* Modals */}
                <ReportVideoModal
                    open={reportModalOpen}
                    setOpen={setReportModalOpen}
                    action={() => {}}
                    reportAction={handleReport}
                />
                <ConfirmActionModal open={submitModalOpen} setOpen={setSubmitModalOpen} action={submit}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Submit Reviews
                            </Typography>
                            <Typography variant="subtitle2">
                                Are you sure you want to submit reviews?  You will not be able to change your submissions once you confirm.
                            </Typography>
                        </Grid>
                    </Grid>
                </ConfirmActionModal>
            </Box>
        )
    }
}