import {useState, useCallback} from "react";
import CollegenetBlue from "../../img/backgrounds/cn_blue.jpg";
import CollegenetWhite from "../../img/backgrounds/cn_white.jpg";
import CollegenetClouds from "../../img/backgrounds/cn_clouds.jpg";

export const useVirtualBackground = () => {

    const [backgrounds, setBackgrounds] = useState<string[]>([
        CollegenetWhite, CollegenetBlue, CollegenetClouds
    ]);

    return {
        backgrounds: backgrounds
    }
}