import React, {ChangeEvent, useState} from "react";
import {Grid, Slider, Typography, Paper, Button, TextField, Collapse, Divider, IconButton, useMediaQuery, Chip} from "@mui/material";
import {VideoPlayer} from "../../components";
import {VideoReviewContent, StandardAttribute} from "./VideoReviewConfig";
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {MovieLabeledIcon, PersonLabeledIcon} from "../../icons/labeled-icons";

interface VideoReviewSectionProps {
    content: VideoReviewContent;
    promptToggle: boolean;
    handleScoreChange: (reviewId: number, attributeName: string, newScore: number) => void;
    handleCommentChange: (reviewId: number, comments: string) => void;
    handleSaveReview: (content: VideoReviewContent) => void;
    handleStartReport: (reviewId: number) => void;
    standardAttributes: StandardAttribute[];
    handleStandardAttributeChange: (reviewId: number, attribute: StandardAttribute) => void;
}

export function VideoReviewSection({content, promptToggle, handleSaveReview, handleScoreChange, handleStartReport, handleCommentChange, standardAttributes, handleStandardAttributeChange}: VideoReviewSectionProps) {

    // media query to render prompt video if prompt toggle is false
    const isSmall = useMediaQuery('(max-width: 900px)');
    const label = promptToggle ? content.revieweeLabel : content.promptLabel;

    // attribute collapse
    const [attributeCollapse, setAttributeCollapse] = useState<boolean>(false);
    const [standardAttributeCollapse, setStandardAttributeCollapse] = useState<boolean>(false);

    const overallScore = content.review!.rawScore;
    const attributeScores = content.review!.attributes || [];
    const isReported = content.review!.isReported;
    const isHidden = !!content.hidden;

    const commentHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        handleCommentChange(content.reviewId, value);
    }

    if (isReported || isHidden) {
        return null;
    } else {
        return (
            <Grid container sx={{mb: 8}} spacing={2} id={`review-${content.reviewId}`} alignItems="center">
                {(promptToggle || isSmall) ?
                    <Grid item xs={0} sm={0} md={1}>
                        <PersonLabeledIcon label={label} accessibilityText={"Responder " + label} />
                    </Grid> :
                    <Grid item xs={0} sm={0} md={2} sx={{textAlign: "center"}}>
                        <MovieLabeledIcon label={label} accessibilityText={"Prompt " + label} />
                        <VideoPlayer
                            options={content.videoPrompt || null}
                            height={150}
                        />
                    </Grid>
                }
                <Grid item container xs={12} sm={12} md={promptToggle ? 11 : 10} alignItems="start" spacing={2}>
                    <Grid item xs={12} sm={12} md={5}>
                        <Paper elevation={2} sx={{p: 2, textAlign: "center"}}>
                            <VideoPlayer
                                options={content.videoResponse || null}
                                height={300}
                            />
                        </Paper>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={6}>
                        {/* Raw Score */}
                        <Grid item xs={12}>
                            <Paper elevation={1} sx={{py: 2, px: 4}}>
                                <Typography variant="h6" sx={{mb: 2}}>
                                    Score
                                </Typography>
                                <Slider
                                    valueLabelDisplay="auto"
                                    step={0.5}
                                    min={1}
                                    marks
                                    name="overall"
                                    max={10}
                                    color={overallScore === 0 ? "success" : "primary"}
                                    aria-label={`overall-score-${content.reviewId}`}
                                    value={overallScore === 0 ? 5 : overallScore}
                                    onChange={(_, v) => handleScoreChange(content.reviewId, "overall", v as number)}
                                />
                            </Paper>
                        </Grid>
                        {attributeScores.length !== 0 ?
                            <Grid item xs={12}>
                                <Grid container alignItems="center" sx={{mt: 2}}>
                                    <Grid item>
                                        <Typography variant="subtitle2">
                                            Custom Attributes
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            color="info"
                                            onClick={() => setAttributeCollapse(prev => !prev)}
                                        >
                                            {attributeCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Divider sx={{my: 1}} />
                            </Grid> :
                            null
                        }
                        <Grid item xs={12}>
                            <Collapse in={attributeCollapse}>
                                <Grid container spacing={2}>
                                    {attributeScores.map((c, i) =>
                                        <Grid item xs={6} sm={6} md={4} key={i}>
                                            <Paper elevation={c.attributeScore ? 1 : 0} sx={{p: 3, textAlign: "center"}}>
                                                <Slider
                                                    orientation="vertical"
                                                    valueLabelDisplay="auto"
                                                    color={c.attributeScore ? "primary" : "success"}
                                                    step={0.5}
                                                    min={1}
                                                    marks
                                                    max={10}
                                                    aria-label={`${c.attributeName}-score-${content.reviewId}`}
                                                    sx={{height: 150}}
                                                    value={c.attributeScore ? c.attributeScore : 5}
                                                    onChange={(_, v) => handleScoreChange(content.reviewId, c.attributeName, v as number)}
                                                />
                                                <Typography variant="subtitle2" sx={c.attributeScore ? {my: 2} : {color: "gray", my: 2}}>
                                                    {c.attributeName}
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    )}
                                </Grid>
                            </Collapse>
                        </Grid>
                        {standardAttributes.length !== 0 ?
                            <Grid item xs={12}>
                                <Grid container alignItems="center" sx={{mt: 2}}>
                                    <Grid item>
                                        <Typography variant="subtitle2">
                                            Standard Attributes
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            color="info"
                                            onClick={() => setStandardAttributeCollapse(prev => !prev)}
                                        >
                                            {standardAttributeCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Divider sx={{my: 1}} />
                            </Grid> :
                            null
                        }
                        <Grid item xs={12}>
                            <Collapse in={standardAttributeCollapse}>
                                <Grid container spacing={2}>
                                    {standardAttributes.map((c) => {
                                        const isSelected = content.review?.standardAttributes.some(a => a.attributeId === c.attributeId);
                                        return (
                                            <Grid item key={c.attributeId}>
                                                <Chip
                                                    label={c.attributeName}
                                                    onClick={() => handleStandardAttributeChange(content.reviewId, c)}
                                                    color={isSelected ? "primary" : "default"}
                                                />
                                            </Grid>
                                        )}
                                    )}
                                </Grid>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Comments"
                                name="comments"
                                fullWidth={true}
                                multiline
                                rows={2}
                                value={content.review!.comments}
                                inputProps={{size: 40}}
                                onChange={commentHandler}
                                sx={{my: 3}}
                            />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={8}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    disabled={!content.modified}
                                    onClick={() => handleSaveReview(content)}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    fullWidth
                                    startIcon={<ErrorIcon />}
                                    onClick={() => handleStartReport(content.reviewId)}
                                >
                                    Report
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}