import React from "react";
import {Grid, Typography, Paper} from "@mui/material";
import {ParticipantStatus, UserStatistics} from "../../data-types";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {RankIcon} from "../../icons";

interface ResultsCompetitionStatusProps {
    participantStatus: ParticipantStatus | undefined;
    userStatistics: UserStatistics | undefined;
}

export function ResultsCompetitionStatus({participantStatus, userStatistics}: ResultsCompetitionStatusProps) {

    return (
        <Grid container spacing={2}>
            {participantStatus ?
                <>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={1} sx={{p: 3, textAlign: "center"}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                {`${participantStatus.numParticipantsRemaining} / ${participantStatus.numParticipants}`}
                            </Typography>
                            <Typography variant="subtitle2">
                                Participants Remaining
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={1} sx={{p: 3, textAlign: "center"}}>
                            {participantStatus.eliminated ?
                                <CancelIcon color="secondary" /> :
                                <CheckCircleIcon color="primary" />
                            }
                            <Typography variant="subtitle2" sx={{mt: 2}}>
                                {participantStatus.eliminated ? "Eliminated" : "Active"}
                            </Typography>
                        </Paper>
                    </Grid>
                </> :
                null
            }
            {userStatistics ?
                <>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={1} sx={{p: 3, textAlign: "center"}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                {userStatistics.currentScore.toFixed(2)}
                            </Typography>
                            <Typography variant="subtitle2">
                                Total Score
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper elevation={1} sx={{p: 3, textAlign: "center"}}>
                            <RankIcon rank={userStatistics.percentile} />
                            <Typography variant="subtitle2" sx={{mt: 2}}>
                                Rank
                            </Typography>
                        </Paper>
                    </Grid>
                </> :
                null
            }
        </Grid>
    )
}