import {useState, useEffect, useCallback} from "react";
import {RequestStatus, Video, VideoSource} from "../../data-types";
import {DataAccess, DataFormatting} from "../../util";
import {VideoJsPlayerOptions} from "video.js";

interface VideoResponseMeta {
    responseId: number;
    saved: boolean;
    institutionName: string | null;
}

interface VideoResponseContent {
    videoPrompt: VideoJsPlayerOptions;
    videoResponse: VideoJsPlayerOptions | null;
}

interface ResponseContent {
    [key: number]: VideoResponseContent;
}

export const useVideoResponseConfig = (competitionId: string | undefined) => {

    const [metaRequestStatus, setMetaRequestStatus] = useState<RequestStatus>("loading");
    const [responseRequestStatus, setResponseRequestStatus] = useState<RequestStatus>("loading");
    const [responseMeta, setResponseMeta] = useState<VideoResponseMeta[]>([]);
    const [responseContent, setResponseContent] = useState<ResponseContent>({});
    const [selectedResponse, setSelectedResponse] = useState<number>(NaN);

    useEffect(() => {
        const getMeta = async () => {
            if (competitionId) {
                const r = await DataAccess.get(`/api/response/${competitionId}/getMeta.json`);
                const responseMeta: VideoResponseMeta[] = r.responseMeta;
                let firstUnsavedResponseId;
                if (responseMeta.length > 0) {
                    firstUnsavedResponseId = responseMeta.find(c => !c.saved)?.responseId || responseMeta[0].responseId;
                } else {
                    firstUnsavedResponseId = NaN;
                }
                setResponseMeta(responseMeta);
                setMetaRequestStatus("complete");
                return firstUnsavedResponseId;
            } else {
                return NaN;
            }
        }

        const getFirstResponse = async (responseId: number) => {
            if (!isNaN(responseId)) {
                const r = await DataAccess.get(`/api/response/${responseId}/getContent.json`);
                const {videoPrompt, videoResponse}: {videoPrompt: Video, videoResponse: Video} = r.responseContent;
                const promptOptions = DataFormatting.videoToVideoJSOptions(videoPrompt);
                const responseOptions = (videoResponse) ? DataFormatting.videoToVideoJSOptions(videoResponse) : null;
                setResponseContent({[responseId]: {videoPrompt: promptOptions, videoResponse: responseOptions}});
                setSelectedResponse(responseId);
                setResponseRequestStatus("complete");
            } else {
                throw new Error("No responses to get");
            }
        }

        getMeta()
            .catch(e => {
                console.log(e);
                setMetaRequestStatus("error");
                return NaN;
            })
            .then(responseId => getFirstResponse(responseId))
            .catch(_ => setResponseRequestStatus("error"));

    }, [competitionId])

    const handleSwitchPrompt = useCallback((responseId: number) => {

        const getContent = async (responseId: number) => {
            setResponseRequestStatus("loading");
            const r = await DataAccess.get(`/api/response/${responseId}/getContent.json`);
            const {videoPrompt, videoResponse}: {videoPrompt: Video, videoResponse: Video | null} = r.responseContent;
            const promptOptions = DataFormatting.videoToVideoJSOptions(videoPrompt);
            const responseOptions = (videoResponse) ? DataFormatting.videoToVideoJSOptions(videoResponse) : null;
            setResponseContent(prev => {
                return {
                    ...prev,
                    [responseId]: {videoPrompt: promptOptions, videoResponse: responseOptions}
                }
            });
            setResponseRequestStatus("complete");
        };

        const exists = Object.keys(responseContent).some(k => responseId === parseInt(k));
        if (!exists) {
            getContent(responseId)
                .then(_ => {
                    setResponseRequestStatus("complete");
                })
                .catch(e => {
                    console.log(e);
                    setResponseRequestStatus("error");
                })
                .finally(() => setSelectedResponse(responseId));
        } else {
            setSelectedResponse(responseId);
        }
    }, [responseContent]);

    const handleSaveSuccess = useCallback((responseId: number, source: VideoSource) => {
        const responseOptions = DataFormatting.videoSourceToVideoJSOptions(source);
        setResponseMeta(prev => prev.map(m => {
            return (m.responseId === responseId) ? {...m, saved: true} : m;
        }));

        setResponseContent(prev => {
            return {...prev, [responseId]: {...prev[responseId], videoResponse: responseOptions}};
        })
    }, []);

    return {
        responseRequestStatus: responseRequestStatus,
        metaRequestStatus: metaRequestStatus,
        responseMeta: responseMeta,
        responseContent: responseContent,
        selectedResponse: selectedResponse,
        handleSwitchPrompt: handleSwitchPrompt,
        handleSaveSuccess: handleSaveSuccess
    }
}