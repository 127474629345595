import React from "react";
import {Grid, Modal, Box, Typography, Divider, Button} from "@mui/material";
import {ModalProps} from "../../data-types";

export function EliminationModal({open, setOpen, action}: ModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style} component="div">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Eliminate User
                        </Typography>
                        <Divider sx={{mt: 2, mb: 2}} />
                    </Grid>
                    <Grid item xs={12} sx={{mb: 2}}>
                        <Typography variant="h6">
                            Are you sure you want to eliminate this user?
                        </Typography>
                        <Typography variant="subtitle2">
                            You cannot undo this after you click confirm.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                action();
                                setOpen(false);
                            }}
                        >
                            Confirm
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}