import React, {useState} from "react";
import {Grid, Card, CardContent, Tooltip, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Typography, Container, ClickAwayListener, IconButton,
    Box} from "@mui/material";
import {RankIcon} from "../../icons";
import {Competition, RequestStatus} from "../../data-types";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Link} from "react-router-dom";
import {LoadingSpinner} from "../../components";

interface RankingSummaryProps {
    competitions: Competition[];
    requestStatus: RequestStatus;
}
export function RankingSummary({competitions, requestStatus}: RankingSummaryProps) {

    const [openTooltip, setOpenTooltip] = useState<boolean>(false);
    const countCompetitionsWithRankings = competitions.reduce((p, c) => {return p + (c.userStatistics === null ? 0 : 1)}, 0);

    return (
        <Paper component={Container} elevation={0} sx={{pb: 4, minHeight: 230}}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{pb: 4, pt: 2}}>
                <Grid item>
                    <Typography variant="h6" component="h2">
                        Rankings
                    </Typography>
                    <Typography variant="subtitle2" color="gray">
                        Events you've completed
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            {requestStatus === "loading" ?
                <LoadingSpinner /> :
                (requestStatus === "error") ?
                    null :
                    countCompetitionsWithRankings === 0 ?
                        <Card>
                            <CardContent>
                                <Typography variant="subtitle2" component="span">
                                    No rankings to display yet.
                                </Typography>
                            </CardContent>
                        </Card> :
                        <Paper elevation={1}>
                            <TableContainer>
                                <Table sx={{maxWidth: 700}}>
                                    <TableHead sx={{backgroundColor: "#383838"}}>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
                                                    Event
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Box component="div" sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                    <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
                                                        Rank
                                                    </Typography>
                                                    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                                                        <Tooltip
                                                            PopperProps={{disablePortal: true}}
                                                            onClose={() => setOpenTooltip(false)}
                                                            open={openTooltip}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            placement="top-end"
                                                            title={<>
                                                                <Typography variant="subtitle2" sx={{mb: 2}}>
                                                                    At the end of each round, your scores are compiled into a Composite Score and you are assigned a ranking.  Your ranking corresponds to your Score Percentile.
                                                                </Typography>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.981} />
                                                                        <Typography variant="subtitle2">
                                                                            98th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.961} />
                                                                        <Typography variant="subtitle2">
                                                                            96th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.941} />
                                                                        <Typography variant="subtitle2">
                                                                            94th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.921} />
                                                                        <Typography variant="subtitle2">
                                                                            92th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.901} />
                                                                        <Typography variant="subtitle2">
                                                                            90th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.801} />
                                                                        <Typography variant="subtitle2">
                                                                            80th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.701} />
                                                                        <Typography variant="subtitle2">
                                                                            70th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.601} />
                                                                        <Typography variant="subtitle2">
                                                                            60th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.501} />
                                                                        <Typography variant="subtitle2">
                                                                            50th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} sx={{textAlign: "center"}}>
                                                                        <RankIcon rank={0.1} />
                                                                        <Typography variant="subtitle2">
                                                                            &lt; 50th percentile
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </>}
                                                        >
                                                            <IconButton onClick={() => setOpenTooltip(true)} size="small">
                                                                <HelpOutlineIcon color="disabled" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ClickAwayListener>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {competitions.map((c, i) => {
                                            if (c.userStatistics) {
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="subtitle2"
                                                                component={Link}
                                                                to={`/myResults/${c.competitionMeta!.competitionId}/rankings`}
                                                                sx={{color: "white"}}
                                                            >
                                                                {c.competitionMeta!.name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <RankIcon rank={c.userStatistics.percentile} />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
            }
        </Paper>
    )
}
