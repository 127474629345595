import React from "react";
import {Button, Container, Divider, Grid, Typography, Paper, CircularProgress} from "@mui/material";
import {useParams} from "react-router-dom";
import {ErrorComponent} from "../../components";
import {useRoundConfig} from "../../hooks";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {toast} from "react-toastify";
import {formatError} from "../../util";

export function AdminRoundConfig() {

    const {roundId} = useParams();
    const {
        requestStatus,
        roundConfig,
        submitRoundConfig,
        handleDateChange
    } = useRoundConfig(roundId);

    const handleSubmit = () => {
        submitRoundConfig(roundConfig)
            .then(_ => {
                toast.success("Round Configuration successfully updated");
            })
            .catch(e => {
                toast.error(formatError(e));
            })
    }

    if (requestStatus === "loading") {
        return <CircularProgress color="primary" />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container>
                <Grid container sx={{mt: 2, mb: 5}}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Round Configuration
                        </Typography>
                        <Divider sx={{my: 3}} />
                    </Grid>
                    <Paper component={Grid} elevation={3} container item xs={12} sm={12} md={6} sx={{p: 2}} alignItems="center">
                        <Grid item xs={12} sx={{mb: 2}}>
                            <Typography variant="h6">
                                Rankings Release Date
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{mb: 2}}>
                            <Typography variant="subtitle2">
                                Rankings for a round of an event will be released at the conclusion of the review phase, unless a date is specified below.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} sx={{p: 2}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Rankings Release Date"
                                    onChange={(value) => handleDateChange("rankingsReleaseDate", value)}
                                    value={roundConfig.rankingsReleaseDate}
                                    sx={{width: "100%"}}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{p: 2}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                sx={{width: "100%"}}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Paper>
                </Grid>
            </Container>
        );
    }
}