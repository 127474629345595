import {useState, useCallback, ChangeEvent} from "react";
import {parse} from "@plussub/srt-vtt-parser";
import {DataFormatting, DataAccess} from "../../util";
import {Video} from "../../data-types";

export const useEditTranscript = (transcript: string) => {

    const [entries, setEntries] = useState(parse(transcript).entries);
    const [isModified, setIsModified] = useState<boolean>(false);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
        const {name, value} = e.target;
        setEntries(prev => prev.map(c => {
            if (c.id === id) {
                return {...c, text: value}
            } else {
                return c;
            }
        }));
        setIsModified(true);
    }, []);

    const handleAddEntry= useCallback((from: number, to: number, text: string) => {
        if (from >= to) {
            throw new Error("From must come before To");
        }

        // can't add empty entry
        if (text === "") {
            throw new Error("Can't add an empty entry");
        }

        let newEntryIndex = 0;
        // check for overlap and find new index in one loop
        for (let entry of entries) {
            if (from < entry.to && entry.from < to) {
                throw new Error(`New entry overlaps with entry ${entry.id}:  ${entry.text}`);
            }
            if (from >= entry.to) {
                newEntryIndex += 1;
            }
        }

        // insert new entry.  map to new ids
        setEntries(prev => {
            const s1 = prev.slice(0, newEntryIndex);
            const entry = [{id: newEntryIndex.toString(), text: text, from: from, to: to}];
            const s2 = prev.slice(newEntryIndex);

            return s1.concat(entry).concat(s2).map((c, i) => {
                return {...c, id: (i + 1).toString()}
            });
        });
        setIsModified(true);
    }, [entries]);


    const handleDeleteEntry = useCallback((id: string) => {
        setEntries(prev => prev.filter(c => c.id !== id)
            .map((c, i) => {
                return {...c, id: (i + 1).toString()}
            }));
        setIsModified(true);
    }, []);

    const submit = useCallback(async (video: Video) => {
        const vttString = DataFormatting.serializeToVtt(entries);
        const data = {newVtt: vttString};
        const query = (video.type === "response") ?
            `/api/response/${video.videoId}/editTranscript.json` :
            `/api/videoPrompt/${video.videoId}/editTranscript.json`;
        await DataAccess.post(query, {data: data});
    }, [entries]);

    return {
        entries: entries,
        isModified: isModified,
        handleChange: handleChange,
        handleAddEntry: handleAddEntry,
        handleDeleteEntry: handleDeleteEntry,
        submit: submit
    }
}