import React, {useState} from "react";
import {
    AppBar,
    Badge,
    Box, Button,
    Collapse,
    Container,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Tooltip,
    Typography
} from "@mui/material";
import MovieIcon from "@mui/icons-material/Movie";
import PersonIcon from "@mui/icons-material/Person";
import LoopIcon from "@mui/icons-material/Loop";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ToggleButton} from "./ToggleButton";
import {ResponseButton} from "./ResponseButton";
import {_ToggleDataMap, ToggleButtonData, VideoReviewContent} from "./VideoReviewConfig";
import {TutorialModal} from "../../components";
import ReviewTutorialModal from "../../video/review_tutorial.mp4";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

interface MetaSectionProps {
    content: VideoReviewContent[];
    promptToggle: boolean;
    selectedPrompt: number;
    selectedResponder: number;
    handleHideReview: (content: VideoReviewContent) => void;
    handleToggle: () => Promise<void>;
    toggleHandler: (id: number) => Promise<void>;
    submit: () => void;
}

export function MetaSection({content, promptToggle, selectedPrompt,selectedResponder, handleHideReview, handleToggle, toggleHandler, submit}: MetaSectionProps) {

    const [tutorialOpen, setTutorialOpen] = useState<boolean>(false);

    // collapse elements
    const [toggleCollapse, setToggleCollapse] = useState<boolean>(false);
    const [responseCollapse, setResponseCollapse] = useState<boolean>(false);

    const canSubmit = content.reduce<boolean>((p, c) => c.saved && p, true);
    const countSaved = content.reduce((p, c) => p + (c.saved ? 1 : 0), 0);
    const countRequired = content.length;
    const progressValue = (countSaved / countRequired) * 100;

    const contentToAggregateMap = (key: "promptId" | "revieweeId", state: VideoReviewContent[]): ToggleButtonData[] => {
        const toggles: ToggleButtonData[] =  Object.values(
            state
                .reduce<_ToggleDataMap>((p, c) => {
                    const id: number = c[key];
                    const s: number = (c.saved) ? 1 : 0;
                    const m = Boolean(c.modified);
                    const oldContent: ToggleButtonData = p[id];
                    const newContent = {
                        label: (key === "promptId") ? c.promptLabel : c.revieweeLabel,
                        id: id,
                        savedCount: ((oldContent) ? oldContent.savedCount : 0) + s,
                        requiredCount: ((oldContent) ? oldContent.requiredCount : 0) + 1,
                        hasModified: ((oldContent) ? oldContent.hasModified : false) || m,
                        institutionName: c.institutionName
                    };
                    return {...p, [id]: newContent};
                }, {})
        );

        return toggles.sort((i, j) => (i.label > j.label) ? 1 : -1);
    }

    let _toggleData: ToggleButtonData[];
    let _content: VideoReviewContent[];

    // configure top-row toggles based on state of promptToggle
    if (promptToggle) {
        _toggleData = contentToAggregateMap("promptId", content);
        _content = (isNaN(selectedPrompt)) ? [] : content.filter(c => c.promptId === selectedPrompt);
    } else {
        _toggleData = contentToAggregateMap("revieweeId", content);
        _content = (isNaN(selectedResponder)) ? [] : content.filter(c => c.revieweeId === selectedResponder);
    }

    return (
        <AppBar position="relative" sx={{mb: 5, p: 2}} elevation={0}>
            <Container>
                <Grid container alignItems="center" sx={{mb: 2}} spacing={2}>
                    <Grid item container xs={12}>
                        <Grid item container sx={{mb: 4}} spacing={1} xs={12} alignItems="center">
                            {/* Prompts/Responders + toggle + collapse -- above Divider*/}
                            <Grid item container xs={12} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                                        {promptToggle ? <MovieIcon /> : <PersonIcon />}
                                        <Typography variant="subtitle2" sx={{ml: 2}}>
                                            {promptToggle ? "Prompts" : "Responders"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Tooltip
                                        title={`Toggle to ${promptToggle ? "Responder" : "Prompt"} View`}
                                    >
                                        <IconButton
                                            onClick={handleToggle}
                                            color="info"
                                            size="large"
                                        >
                                            <Badge
                                                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                                                badgeContent={<LoopIcon />}
                                            >
                                                {promptToggle ? <PersonIcon /> : <MovieIcon />}
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>
                                    <IconButton
                                        onClick={() => setToggleCollapse(prev => !prev)}
                                        color="info"
                                        size="large"
                                    >
                                        {toggleCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{my: 1}} />
                                </Grid>
                            </Grid>
                            <Collapse in={toggleCollapse}>
                                <Grid item container spacing={2}>
                                    {_toggleData.map((c, i) =>
                                        <ToggleButton
                                            key={i}
                                            label={c.label}
                                            data={c}
                                            isSelected={promptToggle ? c.id === selectedPrompt : c.id === selectedResponder}
                                            toggleHandler={toggleHandler}
                                        />
                                    )}
                                </Grid>
                            </Collapse>
                        </Grid>
                        <Grid item container sx={{mb: 4}} spacing={2} xs={12}>
                            <Grid item container xs={12} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                                        {promptToggle ? <PersonIcon /> : <MovieIcon />}
                                        <Typography variant="subtitle2" sx={{ml: 2}}>
                                            Responses
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={() => setResponseCollapse(prev => !prev)}
                                        color="info"
                                        size="large"
                                    >
                                        {responseCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{my: 1}} />
                                </Grid>
                            </Grid>
                            <Collapse in={responseCollapse}>
                                <Grid item container xs={12} spacing={1}>
                                    {_content.map((c, i) =>
                                        <ResponseButton
                                            key={i}
                                            label={promptToggle ? c.revieweeLabel : c.promptLabel}
                                            content={c}
                                            handleHideReview={handleHideReview}
                                        />
                                    )}
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={2} alignItems="center" justifyContent="flex-end">
                        <Grid item xs={8} sx={{px: 4}}>
                            <LinearProgress variant="determinate" value={progressValue} sx={{borderRadius: 5, height: 10}}/>
                            <Typography variant="subtitle2" sx={{fontSize: 12, textAlign: "right"}}>
                                {`(${countSaved} / ${countRequired})`}
                            </Typography>
                        </Grid>
                        <Grid item container xs={4} spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => setTutorialOpen(true)}
                                    startIcon={<PlayCircleIcon />}
                                >
                                    Tutorial
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={submit}
                                    disabled={!canSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <TutorialModal
                open={tutorialOpen}
                setOpen={setTutorialOpen}
                video={ReviewTutorialModal}
                title={"How-to:  Review Phase"}
            >
                <Typography variant="subtitle2" sx={{mb: 2, mt: 5}}>
                    During the Review Phase, you evaluate your peers' video responses.
                </Typography>
                <Typography variant="subtitle2" sx={{mb: 5}}>
                    Please see the included video for more instruction.
                </Typography>
            </TutorialModal>
        </AppBar>
    )
}