import React, {useState} from "react";
import {Box, Button, Container, Grid, TextField, Typography, useTheme} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import FocusRingLogo from "../../img/focus_ring_logo.png";
import {Link} from "react-router-dom";
import {DataAccess} from "../../util";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export function PasswordRecovery() {

    const theme = useTheme();
    const [username, setUsername] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        DataAccess.postFormString("/api/user/initPasswordReset.json", `username_or_email=${username}`)
            .then(_ => {
                setSubmitted(true);
                setLoading(false);
            })
            .catch(_ => {
                setSubmitted(true);
                setLoading(false);
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setUsername(value);
    }

    return (
        <Container sx={{
            bgcolor: theme.palette.background.paper,
            width: "100%",
            minWidth: "350px",
            maxWidth: "500px",
            mt: "17%"
        }}>
            {submitted ?
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <CheckCircleOutlineIcon color="primary" fontSize="large" />
                        <Typography variant="h6" sx={{my: 5}}>
                            Thank you.  We have sent an email to the email address corresponding to this account if it exists
                        </Typography>
                        <Typography variant="body1">
                            If you do not see the email in a few minutes, please check your 'junk mail' or 'spam' folder.
                        </Typography>
                        <Button
                            component={Link}
                            to="/login"
                            sx={{mt: 5, mb: 5}}
                        >
                            Return to Login Page
                        </Button>
                    </Grid>
                </Grid> :
                <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{pl: 0}} >
                        <Box
                            component="img"
                            sx={{
                                width:190
                            }}
                            alt="FocusRing"
                            src={FocusRingLogo}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            Enter your username or email below.  If your account exists, we will send you an email containing a link to reset your password.
                        </Typography>
                    </Grid>
                    {/* We need to wrap this in a form to be able to submit on enter */}
                    <form onSubmit={handleSubmit} style={{paddingLeft: "24px"}}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                label="Username or Email"
                                name="username_or_email"
                                fullWidth={true}
                                inputProps={{size: 35}}
                                onChange={handleChange}
                                sx={{mt: 5}}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{textAlign: 'center', mb: 4}}>
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                                sx={{mt: 5}}
                                loading={loading}
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </form>
                </Grid>
            }
        </Container>
    )
}