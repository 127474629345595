import {useState, useCallback, ChangeEvent} from "react";
import {CreateVideoPromptState, RecordingConfig, FileUploadConfig} from "../../data-types";
import {DataAccess} from "../../util";
import {AxiosProgressEvent} from "axios";

export const useVideoPromptCreate = () => {

    const [promptState, setPromptState] = useState<CreateVideoPromptState>({
        promptName: "",
        isPublic: false
    });
    const [uploadProgress, setUploadProgress] = useState<number>(NaN);

    const handleTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPromptState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, []);

    const handleCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setPromptState(prev => {
            return {
                ...prev,
                isPublic: e.target.checked
            }
        })
    }, []);

    const createVideoPrompt = useCallback(async (promptState: CreateVideoPromptState, recordingConfig: RecordingConfig) => {
        const isValidPrompt = async () => {
            if (promptState.promptName === "") {
                throw new Error("Please give this prompt a name");
            }
            if (recordingConfig.recordingData.length === 0) {
                throw new Error("You have not recorded anything yet.");
            }
        };

        const handleUploadProgress = (e: AxiosProgressEvent) => {
            // if for some reason the event total is undefined, then pass 1,
            // which will yield a progress > 100 and an indeterminate
            // progress bar in ui
            const total = e.total || 1;
            setUploadProgress(Math.round((e.loaded / total) * 100));
        }

        await isValidPrompt();
        const data = new Blob(recordingConfig.recordingData, {type: recordingConfig.mimeType});
        const formData = new FormData();
        formData.append("file", data);
        formData.append("name", promptState.promptName);
        formData.append("size", recordingConfig.time.toString());
        formData.append("contentType", recordingConfig.mimeType.split(";")[0]);
        formData.append("isPublic", promptState.isPublic.toString());

        await DataAccess.post("/api/videoPrompt/create.json", {data: formData}, {onUploadProgress: handleUploadProgress})
    }, []);

    const uploadVideoPrompt = useCallback(async (promptState: CreateVideoPromptState, uploadConfig: FileUploadConfig) => {
        const isValidPrompt = async () => {
            if (promptState.promptName === "") {
                throw new Error("Please give this prompt a name.");
            }
            if (!uploadConfig) {
                throw new Error("You have not uploaded anything yet.");
            }
        };

        const handleUploadProgress = (e: AxiosProgressEvent) => {
            // if for some reason the event total is undefined, then pass 1,
            // which will yield a progress > 100 and an indeterminate
            // progress bar in ui
            const total = e.total || 1;
            setUploadProgress(Math.round((e.loaded / total) * 100));
        }

        await isValidPrompt();
        const formData = new FormData();
        formData.append("file", uploadConfig.file);
        formData.append("name", promptState.promptName);
        formData.append("size", uploadConfig.size.toString());
        formData.append("contentType", uploadConfig.mimeType);
        formData.append("isPublic", promptState.isPublic.toString());

        await DataAccess.post("/api/videoPrompt/create.json", {data: formData}, {onUploadProgress: handleUploadProgress});
    }, []);

    return {
        promptState: promptState,
        handleTextChange: handleTextChange,
        handleCheckboxChange: handleCheckboxChange,
        createVideoPrompt: createVideoPrompt,
        uploadVideoPrompt: uploadVideoPrompt,
        uploadProgress: uploadProgress
    }
}