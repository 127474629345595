import React, {useCallback, useState} from "react";
import {Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, TextField, Tooltip} from "@mui/material";
import {FileDropUpload, SubmitResponseModal} from "../../components";
import {FileUploadConfig, VideoSource} from "../../data-types";
import {useNavigate} from "react-router-dom";
import {useVideoPromptCreate} from "../../hooks";
import {toast} from "react-toastify";
import {formatError} from "../../util";
import {VideoJsPlayer} from "video.js";

export function AdminUploadVideoPrompt() {

    const navigate = useNavigate();
    const [videoUploadConfig, setVideoUploadConfig] = useState<FileUploadConfig | null>(null);
    const {promptState, handleTextChange, handleCheckboxChange, uploadVideoPrompt} = useVideoPromptCreate();
    const [uploadedVideoSource, setUploadedVideoSource] = useState<VideoSource>({src: "", type: ""});
    const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);

    const handleSubmit = async () => {
        try {
            if (videoUploadConfig) {
                await uploadVideoPrompt(promptState, videoUploadConfig);
                navigate("/admin/videoPrompts");
            }
        } catch(e) {
            console.log(e);
            toast.error(formatError(e));
        }
    }

    const setUploadSource = () => {
        URL.revokeObjectURL(uploadedVideoSource.src);
        const url = URL.createObjectURL(videoUploadConfig!.file);
        setUploadedVideoSource({src: url, type: videoUploadConfig!.mimeType});
    }

    const handlePlayerReady = useCallback((player: VideoJsPlayer) => {
        const setDuration = (p: VideoJsPlayer) => {
            const duration = p.duration();
            // only update if size videoUploadConfig is null OR
            // size is NaN.  Otherwise, page will re-render infinitely
            if (!videoUploadConfig || isNaN(videoUploadConfig.size)) {
                setVideoUploadConfig(prev => {
                    return (prev) ? {...prev, size: duration} : null;
                })
            }
        }

        // run this listener only if we're doing upload so that we can update the
        // state with the uploaded video's duration.  This isn't called for recorded
        // videos since useRecording hook uses a timer hook to hold duration
        player.one('loadedmetadata', () => {setDuration(player)});
    }, [videoUploadConfig]);

    return (
        <Container sx={{mb: 10}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                    <FileDropUpload
                        maxFileSize={300000000}
                        acceptedFileTypes={{"VIDEO/MP4": [".mp4"]}}
                        uploadConfig={videoUploadConfig}
                        setUploadConfig={setVideoUploadConfig}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        required
                        label="Prompt Name"
                        fullWidth={true}
                        name="promptName"
                        value={promptState.promptName}
                        inputProps={{size: 40}}
                        onChange={handleTextChange}
                    />
                    <FormGroup>
                        <Tooltip
                            title="If you make this video public, all other admins will be able to view it and use it after you click submit."
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={promptState.isPublic}
                                        onChange={handleCheckboxChange}
                                        name="isPublic"
                                    />
                                }
                                label="Make public?"
                            />

                        </Tooltip>
                    </FormGroup>
                    <Box sx={{textAlign: "center", mt: 3}} component="div">
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!videoUploadConfig || promptState.promptName.length === 0}
                            onClick={() => {
                                setUploadSource();
                                setSubmitModalOpen(true);
                            }}
                        >
                            Review and Submit
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <SubmitResponseModal
                videoSource={uploadedVideoSource}
                open={submitModalOpen}
                setOpen={setSubmitModalOpen}
                action={handleSubmit}
                handlePlayerReady={handlePlayerReady}
                loading={false}
            />
        </Container>
    )
}