import React from "react";
import {Invitation} from "../../data-types";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import ErrorIcon from '@mui/icons-material/Error';
import competition_placeholder from "../../img/competition_placeholder.jpg";
import {
    Typography,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Button,
    Box,
    Tooltip
} from "@mui/material";
import {parseISO, isAfter, subHours} from "date-fns";

interface UserInvitationListItemProps {
    invitation: Invitation;
    initAccept: (competitionId: number) => void;
    reject: (competitionId: number) => void;
}

export function UserInvitationListItem({invitation, initAccept, reject}: UserInvitationListItemProps) {

    let style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 1
    };

    const enrollmentDeadline = parseISO(invitation.firstRoundResponseEnd + "Z");
    const now = new Date();
    const enrollmentDisabled = (invitation.paymentParticipant > 0) && isAfter(now, subHours(enrollmentDeadline, 3));
    const enrollmentWarning = (invitation.paymentParticipant > 0) && isAfter(now, subHours(enrollmentDeadline, 24)) && !enrollmentDisabled;

    let secondaryAction;
    switch(invitation.status) {
        case "accepted": {
            secondaryAction =
                <Box component="div" sx={style}>
                    <CheckCircleIcon color="primary" />
                    <Typography variant="subtitle2">
                        Accepted
                    </Typography>
                </Box>
            break;
        }
        case "rejected": {
            secondaryAction =
                <Box component="div" sx={style}>
                    <CancelIcon color="secondary" />
                    <Typography variant="subtitle2">
                        Rejected
                    </Typography>
                </Box>
            break;
        }
        case "pending": {
            secondaryAction =
                <Box component="div" sx={{...style, flexDirection: "column"}}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={enrollmentDisabled}
                        onClick={() => initAccept(invitation.competitionId)}
                    >
                        Accept
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => reject(invitation.competitionId)}
                    >
                        Reject
                    </Button>
                </Box>
            break;
        }
        default: {
            secondaryAction =
                <Box component="div" sx={style}>
                    <TimerOffIcon color="disabled" />
                    <Typography variant="subtitle2">
                        Expired
                    </Typography>
                </Box>
            break;
        }
    }

    return (
        <ListItem
            sx={{mt: 2, bgcolor: 'background.paper', height: 125}}
        >
            <ListItemAvatar>
                <Avatar
                    src={competition_placeholder}
                    alt="event-avatar"
                />
            </ListItemAvatar>
            <ListItemText
                disableTypography
                primary={
                    <Typography variant="body1" noWrap>
                        {invitation.competitionName}
                    </Typography>
                }
                secondary={invitation.paymentParticipant ?
                    <Box component="div" sx={{display: "flex", alignItems: "center"}}>
                        {enrollmentWarning ?
                            <Tooltip
                                enterTouchDelay={300}
                                title="Please note, responses are due in less than 24 hours.  You will be ineligible for a refund if you enroll and don't complete the responses."
                            >
                                <ErrorIcon color="warning" sx={{mr: 1}} />
                            </Tooltip> :
                            null
                        }
                        <Typography variant="subtitle2">
                            {`Event fee: $${(invitation.paymentParticipant / 100).toFixed(2)}`}
                        </Typography>
                    </Box> :
                    null
                }
            />
            {secondaryAction}
        </ListItem>
    )
}