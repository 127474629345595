import React, {useState} from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import {
    Container,
    FormControl,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    Select,
    TextField,
    Typography,
    MenuItem, IconButton, Chip, Button, Box,
    ListItem, ListItemText, ClickAwayListener, Tooltip, InputAdornment
} from "@mui/material";
import {addMinutes} from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {useRoundCreate, useScripts, useReviewGroups} from "../../hooks";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from "@mui/icons-material/Add";
import {toast} from "react-toastify";
import {formatError} from "../../util";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export function AdminCreateRound() {
    const navigate = useNavigate();
    const {competitionId, roundNumber} = useParams();
    const {
        roundState,
        handleChange,
        handleDateChange,
        handleSelectChange,
        handleAddAttribute,
        handleRemoveAttribute,
        handleCheckboxChange,
        createRound
    } = useRoundCreate(competitionId, roundNumber);
    const {scripts: createdScripts} = useScripts("created");
    const {scripts: sharedScripts} = useScripts("shared");
    const {reviewGroups} = useReviewGroups();
    const [dateTooltipOpen, setDateTooltipOpen] = useState<boolean>(false);

    const usableSharedScripts = sharedScripts.filter(c => c.isFinalized && c.canShare);
    const usableCreatedScripts = createdScripts.filter(c => !c.isPublic);
    const usableScripts = [...usableCreatedScripts, ...usableSharedScripts];

    const [attribute, setAttribute] = useState<string>("");
    const handleAttributeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setAttribute(value);
    }

    const submit = async () => {
        try {
            await createRound(roundState);
            toast.success("Round successfully created");
            navigate("/admin/events");
        } catch(e) {
            console.log(e);
            toast.error(formatError(e));
        }
    }

    return (
        <Container sx={{
            width: "100%",
            p: 5
        }}>
            <Typography
                variant="h5"
                sx={{mb: 4}}
            >
                1.  Round parameters
            </Typography>
            <Grid container spacing={2} sx={{mb: 12}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Heat Size"
                        type="number"
                        fullWidth={true}
                        name="heatSize"
                        error={roundState.heatSize < 1}
                        helperText={(roundState.heatSize < 1) ? "Heat size must be at least 1" : ""}
                        value={roundState.heatSize}
                        inputProps={{size: 40}}
                        onChange={handleChange}
                        sx={{mb: 3}}
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={roundState.allowReduceHeatSize} onChange={handleCheckboxChange} />}
                            label="Automatically reduce heat size to improve round performance"
                            name="allowReduceHeatSize"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Elimination Rate"
                        type="number"
                        error={roundState.eliminationRate < 0 || roundState.eliminationRate > 1}
                        helperText={(roundState.eliminationRate < 0 || roundState.eliminationRate > 1) ?
                            "Elimination rate must be between 0.0 and 1.0" : ""
                        }
                        fullWidth={true}
                        name="eliminationRate"
                        value={roundState.eliminationRate}
                        inputProps={{size: 40, step: 0.1}}
                        onChange={handleChange}
                        sx={{mb: 3}}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{mb: 4}}>
                <Grid item>
                    <Typography variant="h5">
                        2(a).  Response/Review phase start/end
                    </Typography>
                </Grid>
                <Grid item>
                    <ClickAwayListener onClickAway={() => setDateTooltipOpen(false)}>
                        <Tooltip
                            PopperProps={{disablePortal: true}}
                            onClose={() => setDateTooltipOpen(false)}
                            open={dateTooltipOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="bottom-end"
                            title={<>
                                <Typography variant="subtitle2" sx={{mb: 2}}>
                                    Please ensure the following conditions are met when selecting dates for this round.
                                </Typography>
                                <Typography variant="subtitle2" sx={{mb: 1}}>
                                    1. Response End and Review Start are at least 1 minute apart.
                                </Typography>
                                <Typography variant="subtitle2" sx={{mb: 1}}>
                                    2. Response Start/End and Review Start/End don't overlap with any previously scheduled rounds for this event.
                                </Typography>
                                <Typography variant="subtitle2" sx={{mb: 1}}>
                                    3. Response Start/End and Review Start/End don't overlap and are all scheduled before Event End date.
                                </Typography>
                                <Typography variant="subtitle2" sx={{mb: 1}}>
                                    4. (Optional) If scheduling a Proxy Review phase, ensure Proxy Review Start occurs at least one minute after Review End.  Proxy Review Start and End must occur before Event End date.
                                </Typography>
                            </>}
                        >
                            <IconButton onClick={() => setDateTooltipOpen(true)} size="small">
                                <HelpOutlineIcon color="disabled" />
                            </IconButton>
                        </Tooltip>
                    </ClickAwayListener>
                </Grid>
            </Grid>
            <Grid container spacing={4} sx={{mb: 12}} alignItems="center" justifyContent="center">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={2} item sm={12} xs={12} md={6}>
                        <Grid item xs={12} sm={12} md={6}>
                            <DateTimePicker
                                label="Response Start Date"
                                onChange={(value) => handleDateChange("responseStartDate", value)}
                                value={roundState.responseStartDate}
                                disablePast={true}
                                sx={{width: "100%"}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <DateTimePicker
                                label="Response End Date"
                                onChange={(value) => handleDateChange("responseEndDate", value)}
                                value={roundState.responseEndDate}
                                minDate={addMinutes(roundState.responseStartDate, 5)}
                                disablePast={true}
                                sx={{width: "100%"}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Remind users before end of phase?"
                                name="responseReminderDaysBefore"
                                fullWidth
                                value={roundState.responseReminderDaysBefore}
                                onChange={handleChange}
                                sx={{mt: 3}}
                            >
                                <MenuItem value={0}>
                                    Don't Send Reminder
                                </MenuItem>
                                <MenuItem value={1}>
                                    1 day
                                </MenuItem>
                                <MenuItem value={2}>
                                    2 days
                                </MenuItem>
                                <MenuItem value={7}>
                                    7 days
                                </MenuItem>
                            </TextField>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} item sm={12} xs={12} md={6}>
                        <Grid item xs={12} sm={12} md={6}>
                            <DateTimePicker
                                label="Review Start Date"
                                onChange={(value) => handleDateChange("reviewStartDate", value)}
                                value={roundState.reviewStartDate}
                                disablePast={true}
                                minDate={addMinutes(roundState.responseEndDate, 5)}
                                sx={{width: "100%"}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <DateTimePicker
                                label="Review End Date"
                                onChange={(value) => handleDateChange("reviewEndDate", value)}
                                value={roundState.reviewEndDate}
                                disablePast={true}
                                minDate={addMinutes(roundState.reviewStartDate, 5)}
                                sx={{width: "100%"}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Remind users before end of phase?"
                                name="reviewReminderDaysBefore"
                                onChange={handleChange}
                                fullWidth
                                value={roundState.reviewReminderDaysBefore}
                                sx={{mt: 3}}
                            >
                                <MenuItem value={0}>
                                    Don't Send Reminder
                                </MenuItem>
                                <MenuItem value={1}>
                                    1 day
                                </MenuItem>
                                <MenuItem value={2}>
                                    2 days
                                </MenuItem>
                                <MenuItem value={7}>
                                    7 days
                                </MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Grid>
            <Typography
                variant="h5"
                sx={{mb: 4}}>
                2(b).  Proxy Phase
            </Typography>
            <Grid container spacing={2} sx={{mb: 10}}>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={roundState.hasProxy} onChange={handleCheckboxChange} />}
                            label="Include Proxy Phase"
                            name="hasProxy"
                        />
                    </FormGroup>
                </Grid>
                {roundState.hasProxy ?
                    <Grid container item spacing={2} xs={12}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                label="Proxy Heat Size"
                                type="number"
                                fullWidth={true}
                                name="proxyHeatSize"
                                error={roundState.proxyHeatSize < 1}
                                helperText={(roundState.proxyHeatSize < 1) ? "Heat size must be at least 1" : ""}
                                value={roundState.proxyHeatSize}
                                inputProps={{size: 40}}
                                onChange={handleChange}
                                sx={{mb: 3}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                label="Percent To Proxy"
                                type="number"
                                error={roundState.percentToProxy < 0 || roundState.percentToProxy > 100}
                                helperText={(roundState.percentToProxy < 0 || roundState.percentToProxy > 100) ?
                                    "Percent to proxy must be between 0 and 100" : ""
                                }
                                fullWidth={true}
                                name="percentToProxy"
                                value={roundState.percentToProxy}
                                inputProps={{size: 40, step: 0.1}}
                                onChange={handleChange}
                                sx={{mb: 3}}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Proxy Start Date"
                                    onChange={(value) => handleDateChange("proxyStartDate", value)}
                                    value={roundState.proxyStartDate}
                                    minDate={addMinutes(roundState.reviewEndDate, 5)}
                                    disablePast={true}
                                />
                                <DateTimePicker
                                    label="Proxy End Date"
                                    onChange={(value) => handleDateChange("proxyEndDate", value)}
                                    value={roundState.proxyEndDate}
                                    minDate={addMinutes(roundState.proxyStartDate, 5)}
                                    disablePast={true}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl sx={{width: 400}}>
                                <InputLabel id="proxyGroupSelectLabel">
                                    Review Group
                                </InputLabel>
                                <Select
                                    labelId="groupSelectLabel"
                                    id="groupSelect"
                                    value={roundState.proxyReviewGroupId === -1 ? '' : roundState.proxyReviewGroupId.toString()}
                                    label="ReviewGroup"
                                    name="proxyReviewGroupId"
                                    onChange={handleSelectChange}
                                >
                                    {reviewGroups.map((c, i) => {
                                        return (
                                            <MenuItem value={c.groupId} key={i}>
                                                {c.groupName}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid> :
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            sx={{mb: 4}}>
                            This round will NOT have a proxy phase.
                        </Typography>
                    </Grid>
                }
            </Grid>
            <Typography
                variant="h5"
                sx={{mb: 4}}>
                3.  Add Response Script
            </Typography>
            <Grid container spacing={2} sx={{mb: 10}}>
                <Grid item xs={12}>
                    {usableScripts.length === 0 ?
                        <Box component="div">
                            <Typography variant="h6">
                                You don't have any Response Scripts!
                            </Typography>
                            <Typography variant="body1" sx={{mb: 3}}>
                                You won't be able to create a round until you create a Response Script.
                            </Typography>
                            <Button
                                component={Link}
                                to="/admin/scripts/create"
                                variant="contained"
                            >
                                Create a Response Script
                            </Button>
                        </Box> :
                        <TextField
                            select
                            id="scriptSelect"
                            value={roundState.scriptId === -1 ? '' : roundState.scriptId.toString()}
                            label="Response Script"
                            name="scriptId"
                            sx={{width: {md: "50%", xs: "100%"}, mb: 2}}
                            onChange={handleChange}
                        >
                            {usableScripts.map((c, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={c.scriptId}
                                        component={ListItem}
                                        secondaryAction={
                                            <IconButton component={Link} to={`/admin/scripts/detail/${c.scriptId}`} target="_blank">
                                                <OpenInNewIcon />
                                            </IconButton>
                                        }
                                        sx={{p: 2}}
                                    >
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography variant="subtitle1" noWrap>
                                                    {c.scriptName} (created by {c.createdBy})
                                                </Typography>
                                            }
                                        />
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    }
                </Grid>
            </Grid>
            <Typography
                variant="h5"
                sx={{mb: 4}}>
                4.  Add Attributes
            </Typography>
            <Grid container sx={{my: 2}}>
                <Grid item>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={roundState.hasStandardAttributes} onChange={handleCheckboxChange} />}
                            label="Include Standard Attributes"
                            name="hasStandardAttributes"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mb: 8}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Enter attributes for this round"
                        name="attribute"
                        fullWidth={true}
                        inputProps={{size: 40}}
                        onChange={handleAttributeChange}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleAddAttribute(attribute);
                                setAttribute("");
                            }
                        }}
                        value={attribute}
                        sx={{mb: 3}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        color="primary"
                                        disabled={attribute === ""}
                                        onClick={() => {
                                            handleAddAttribute(attribute);
                                            setAttribute("");
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {roundState.attributes.include.map((c, i) => (
                        <Chip
                            sx={{mr: 2}}
                            color="primary"
                            variant="filled"
                            label={c}
                            key={i}
                            clickable={true}
                            onDelete={() => handleRemoveAttribute(c)}
                        />
                    ))}
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{mb: 12}}>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        onClick={submit}
                    >
                        Create Round
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}
