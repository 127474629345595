import {ChangeEvent, useCallback, useState, useContext} from "react";
import {LoginState, UserProfileState} from "../../data-types";
import {DataAccess, DataValidation} from "../../util";
import qs from "qs";
import {UserContext} from "../../context";

export const useLogin = () => {

    const {setUser} = useContext(UserContext);
    const [loginState, setLoginState] = useState<LoginState>({
        username: "",
        password: ""
    });

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, []);

    const login = useCallback(async (loginForm: LoginState) => {
        // this call sets the cookie in the browser that ties this to a user session
        // in the middleware
        await DataAccess.postFormString("/login", qs.stringify(loginForm));

        // this call gets the profile info with newly-set cookie and assigns it
        // to top-level user context in this app
        const user = await DataAccess.get("/api/user/profile.json") as UserProfileState;

        // if this user does not have email confirmed, DON'T set user context
        if (!user.isEmailConfirmed) {
            return `/confirmEmail/reminder/${user.email}`;
        }

        setUser(user);

        // return the link to navigate to based on the user's profile data
        if (user.role === "admin") {
            return "/admin";
        } else if (user.isReviewer) {
            return "/reviewer";
        } else if (DataValidation.isUserProfileIncomplete(user)) {
            return "/user/profile?src=login";
        } else {
            return "/";
        }
    }, [setUser]);

    return {
        loginState: loginState,
        handleChange: handleChange,
        login: login
    }
}