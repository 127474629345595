import React from "react";
import {Card, CardActionArea, Typography, Avatar, Grid} from "@mui/material";

interface InstitutionSelectCardProps {
    institutionName: string;
    institutionAvatar: string | null;
    institutionId: number | null;
    handleSelect: (id: number | null, scriptStepId?: string) => void;
    selected: boolean;
    scriptStepId?: string;
}

export function InstitutionSelectCard({institutionName, institutionId, institutionAvatar, handleSelect, selected, scriptStepId}: InstitutionSelectCardProps) {

    return (
        <Card
            sx={
                selected ?
                    {border: "3px solid green", boxSizing: "border-box"} :
                    {border: "3px solid rgba(0, 0, 0, 0.0)", boxSizing: "border-box"}
            }
        >
            <CardActionArea
                onClick={() => handleSelect(institutionId, scriptStepId)}
            >
                <Grid container spacing={2} direction="column" alignItems="center" sx={{p: 2}}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {institutionName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Avatar alt={`avatar-${institutionName}`} src={institutionAvatar ?? ""} />
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    )
}