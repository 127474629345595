import React, {useCallback} from "react";
import {toast} from "react-toastify";
import {useDropzone} from "react-dropzone";
import {Box, Typography, Paper} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {FileUploadConfig} from "../../data-types";

type AcceptedFileConfig = {
    [key: string]: string[];
}

interface FileDropUploadProps {
    maxFileSize: number;
    acceptedFileTypes: AcceptedFileConfig;
    uploadConfig: FileUploadConfig | null;
    setUploadConfig: React.Dispatch<React.SetStateAction<FileUploadConfig | null>>;
}

export function FileDropUpload({maxFileSize, acceptedFileTypes, uploadConfig, setUploadConfig}: FileDropUploadProps) {

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const uploadedFile = acceptedFiles[0];
        if (!uploadedFile || uploadedFile.size > maxFileSize) {
            toast.error("That file is either invalid or too large.  Please try another file.");
        } else {
            setUploadConfig({file: uploadedFile, mimeType: uploadedFile.type, size: NaN, fileName: uploadedFile.name});
        }
    }, [setUploadConfig, maxFileSize]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: acceptedFileTypes, maxFiles: 1});

    const acceptedExtensions = Object.values(acceptedFileTypes)
        .reduce((p, c) => [...p, ...c], [])
        .join(", ");

    return (
        <Box {...getRootProps()} sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'center',
            padding: '25px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#202020',
            color: '#ffffff',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            textAlign: "center",
            height: 220
        }}
             component="div"
        >
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <Typography variant="h3">Drop Here!</Typography> :
                    <>
                        <Typography variant="body1">
                            Drag and drop a file here, or click to select files.
                        </Typography>
                        <Typography variant="body1">
                            {`Only ${acceptedExtensions} files are currently supported.`}
                        </Typography>
                        <Box
                            component={Paper}
                            elevation={1}
                            sx={{
                                my: 2,
                                py:1,
                                px: 3,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {uploadConfig ?
                                <CheckCircleIcon color="primary" /> :
                                <CancelIcon color="secondary" />
                            }
                            <Typography variant="body1" sx={{ml: 2}}>
                                {`${uploadConfig ? uploadConfig.fileName : "Nothing uploaded"}`}
                            </Typography>
                        </Box>
                        <Typography variant="subtitle2">
                            {`Max File Size:  ${maxFileSize / 1000000} MB`}
                        </Typography>
                    </>
            }
        </Box>
    )
}