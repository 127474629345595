import {GridColDef} from "@mui/x-data-grid";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Box, Tooltip, Typography} from "@mui/material";

export const rawScoreColDefs: GridColDef[] = [
    {field: "rawScore", headerName: "Overall Score", flex: 1,
        valueGetter: (params) => {
            return (!params.row.rawScore) ? "No score yet" : parseFloat(params.row.rawScore.toFixed(2));
        },
        renderHeader: (_params) => {
            return (
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}} component="div">
                    <Typography variant="body1">
                        Overall
                    </Typography>
                    <Tooltip
                        title={
                            <Typography variant="subtitle2">
                                Each review requires an overall 1 - 10 impression score.
                            </Typography>
                        }
                    >
                        <HelpOutlineIcon color="disabled" fontSize="small" sx={{ml: 2}} />
                    </Tooltip>
                </Box>
            )
        }
    },
    {field: "rpr", headerName: "RPR", flex: 1,
        valueGetter: (params) => {
            return (!params.row.rpr) ? "Not Available" : parseFloat(params.row.rpr.toFixed(2));
        },
        renderHeader: (_params) => {
            return (
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}} component="div">
                    <Typography variant="body1">
                        RPR
                    </Typography>
                    <Tooltip
                        title={
                            <>
                                <Typography variant="subtitle2" sx={{mb: 2}}>
                                    RPR (Relative Performance Ratio) is the ratio of the Overall Score divided by the average score this reviewer gave for this prompt.  An RPR greater than 1 means this review was rated higher than the average response given.
                                </Typography>
                                <Typography variant="subtitle2">
                                    RPR is calculated at the conclusion of a round.
                                </Typography>
                            </>
                        }
                    >
                        <HelpOutlineIcon color="disabled" sx={{ml: 2}} fontSize="small" />
                    </Tooltip>
                </Box>
            )
        }
    },
    {field: "rps", headerName: "RPS", flex: 1,
        valueGetter: (params) => {
            return (!params.row.rps) ? "Not Available" : params.row.rps
        },
        renderHeader: (_params) => {
            return (
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}} component="div">
                    <Typography variant="body1">
                        RPS
                    </Typography>
                    <Tooltip
                        title={
                        <>
                            <Typography variant="subtitle2">
                                RPS (Relative Performance Standing) represents the ordinal result (1st, 2nd, etc.) that this user placed with respect to a reviewer for a prompt.
                            </Typography>
                            <Typography variant="subtitle2">
                                RPS is calculated at the conclusion of a round.
                            </Typography>
                        </>
                        }
                    >
                        <HelpOutlineIcon color="disabled" sx={{ml: 2}} fontSize="small" />
                    </Tooltip>
                </Box>
            )
        }
    },
];