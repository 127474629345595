import React, {useState, useEffect} from "react";
import {DataCubeRendering} from "./DataCubeRendering";
import {DemographicSelect} from "./DemographicSelect";
import {FormMultiselect} from "../form-multiselect";
import {Box, Container, Grid, Typography, Paper, Button, Stepper, Step, StepLabel} from "@mui/material";
import {CubeData, DemographicAxis, DemographicOptions} from "../../data-types";

export function DataCube() {

    const sampleAttributes = ["charisma", "intelligence", "confidence", "clarity", "thoughtfulness"];
    const sampleDemographics = {
        continuous: [
            {
                "name": "Age",
                "dataType": "integer",
                "rangeMin": 18,
                "rangeMax": 100,
                "recommendedBinSize": 15,
                "binStep": 1
            },
            {
                "name": "High School GPA",
                "dataType": "float",
                "rangeMin": 0.0,
                "rangeMax": 4.0,
                "recommendedBinSize": 1.0,
                "binStep": 0.1
            }
        ],
        discrete: [
            {
                "name": "Education Level",
                "bins": ["Some High School", "High School Graduate", "Some Undergraduate", "Undergraduate Degree", "Master Degree", "Ph. D"]
            },
            {
                "name": "Employment Status",
                "bins": ["Unemployed", "Part-time", "Full-time"]
            }
        ]
    };

    // data from api
    const [demographicOptions, setDemographicOptions] = useState<DemographicOptions>(sampleDemographics);

    // step-state of constructing cube.  First attributes, then demographic 1,
    // then demographic 2 ... etc.
    const [activeStep, setActiveStep] = useState<number>(0);

    // attributes to measure
    const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);
    // demographics to measure -- retrieved after DemographicSelect is submitted
    const [demographicAxes, setDemographicAxes] = useState<DemographicAxis[]>([]);
    const [data, setData] = useState<CubeData>({matrix: []});

    const handleAttributeSubmit = () => {
        setActiveStep(1);
    }

    const handleDemographicSubmit = (d: DemographicAxis) => {
        if (demographicAxes.length === 0 || d.bins.length === selectedAttributes.length) {
            setDemographicAxes(prev => [...prev, d]);
            setActiveStep(prev => prev + 1);
        } else {
            alert(`For the second demographic, please use the same number of bins as attributes (${selectedAttributes.length})`);
        }
    }

    const handleDemographicUndo = () => {
        setDemographicAxes(prev => prev.slice(0, prev.length - 1));
        setActiveStep(prev => prev - 1);
    }

    // TODO: simulating a data matrix for now
    useEffect(() => {
        let matrix: number[];
        if (demographicAxes.length === 0) {
            matrix = [];
        }
        else {
            let numDataPoints = demographicAxes[0]!.bins.length * selectedAttributes.length;
            // random number in range:  Math.random() * (max - min) + min;
            // need to do .fill(0) because map does not work on empty array items, an annoying
            // javascript feature...
            matrix = Array(numDataPoints).fill(0).map(() => Math.round((Math.random() * (10 - 1) + 1) * 100) / 100);
        }

        setData(prev => {
            return {
                ...prev,
                matrix: matrix
            }
        })
    }, [demographicAxes])

    return (
        <Box component="div">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{height: "600px"}} component="div">
                            <Typography variant="h5">
                                Perception
                            </Typography>
                            <DataCubeRendering
                                attributes={selectedAttributes}
                                demographicAxes={demographicAxes}
                                cubeData={data}
                                color="#28871D"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{height: "600px"}} component="div">
                            <Typography variant="h5">
                               Personality
                            </Typography>
                            <DataCubeRendering
                                attributes={selectedAttributes}
                                demographicAxes={demographicAxes}
                                cubeData={data}
                                color="#F50057"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stepper activeStep={activeStep}>
                            <Step>
                                <StepLabel>Select Attributes</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Select Demographic 1</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Select Demographic 2</StepLabel>
                            </Step>
                        </Stepper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Paper sx={{pt: 3, pb: 3, textAlign: 'center'}}>
                            <Typography variant="h5" sx={{mb: 3}}>
                                Attributes
                            </Typography>
                            <Typography variant="body1" sx={{mb: 3}}>
                                Select Attributes to add to the cube
                            </Typography>
                            <FormMultiselect
                                label={"Select Attributes"}
                                selections={sampleAttributes}
                                parentHandler={setSelectedAttributes}
                            />
                            <Box sx={{mt: 3}} component="div">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{width: "90%"}}
                                    disabled={selectedAttributes.length === 0 || activeStep > 0}
                                    onClick={handleAttributeSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {activeStep < 1 ?
                            <Box
                                sx={{
                                    height: "100%",
                                    border: "3px dashed white",
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                    padding: 3
                                }}
                                component="div"
                            >
                                <Typography variant="h5">
                                    Specify Attributes before Demographic 1
                                </Typography>
                            </Box> :
                            <DemographicSelect
                                demographics={demographicOptions}
                                submit={handleDemographicSubmit}
                                undo={handleDemographicUndo}
                                active={activeStep === 1}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {activeStep < 2 ?
                            <Box
                                sx={{
                                    height: "100%",
                                    border: "3px dashed white",
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                    padding: 3
                                }}
                                component="div"
                            >
                                <Typography variant="h5">
                                    Specify Demographic 1 before Demographic 2
                                </Typography>
                            </Box> :
                            <DemographicSelect
                                demographics={demographicOptions}
                                submit={handleDemographicSubmit}
                                undo={handleDemographicUndo}
                                active={activeStep === 2}
                            />
                        }
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}