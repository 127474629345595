import React from "react";
import {Grid, List, ListItem, ListItemText, Typography} from "@mui/material";
import {UserInvitationListItem} from "./UserInvitationListItem";
import {UserInvitationState} from "../../data-types";

interface InvitationSectionProps {
    invitations: UserInvitationState;
    initAccept: (competitionId: number) => void;
    reject: (competitionId: number) => void;
}

export function InvitationSection({invitations, initAccept, reject}: InvitationSectionProps) {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    Pending
                </Typography>
                <List>
                    {invitations.current.length === 0 ?
                        <ListItem>
                            <ListItemText
                                primary="You have no currently pending invitations."
                            />
                        </ListItem> :
                        null
                    }
                    {invitations.current.map((c, i) =>
                        <UserInvitationListItem
                            key={i}
                            invitation={c}
                            initAccept={initAccept}
                            reject={reject}
                        />
                    )}
                </List>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">
                    Past
                </Typography>
                <List>
                    {invitations.past.length === 0 ?
                        <ListItem>
                            <ListItemText
                                primary="Nothing here yet."
                            />
                        </ListItem> :
                        null
                    }
                    {invitations.past.map((c, i) =>
                        <UserInvitationListItem
                            key={i}
                            invitation={c}
                            initAccept={initAccept}
                            reject={reject}
                        />
                    )}
                </List>
            </Grid>
        </Grid>
    )
}