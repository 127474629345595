import React from "react";
import {Paper, Grid, Typography, Button, Box, Tooltip} from "@mui/material";
import {PromptResponseDetailCardProps} from "../../data-types";
import {VideoJsPlayerOptions} from "video.js";
import {VideoPlayer, VideoPlaceholder} from "../../components";
import {Link} from "react-router-dom";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ErrorIcon from '@mui/icons-material/Error';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import RedoIcon from "@mui/icons-material/Redo";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';

export function PromptResponseDetailCard({roundId, prompt}: PromptResponseDetailCardProps) {

    const videoOptions: VideoJsPlayerOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        sources: prompt.sources,
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    return (
        <Paper elevation={1}>
            <Grid container justifyContent="center" alignItems="center" sx={{mb: 5}}>
                {prompt.isDecisionScriptStep ?
                    <Grid item xs={9} sm={3}>
                        <VideoPlaceholder height={225}>
                            <DynamicFeedIcon fontSize="large" />
                        </VideoPlaceholder>
                    </Grid> :
                    (prompt.videoId === undefined) ?
                        <Tooltip title={"You don't have access to this video."}>
                            <Grid item xs={9} sm={3}>
                                <VideoPlaceholder height={225}>
                                    <DoDisturbIcon fontSize="large" />
                                </VideoPlaceholder>
                            </Grid>
                        </Tooltip> :
                        <Grid item xs={9} sm={3}>
                            <VideoPlayer
                                options={videoOptions}
                                height={225}
                            />
                        </Grid>
                }
                <Grid item xs={12} sm={3} sx={{p: 2}}>
                    <Box component="div" sx={{display: "flex", alignItems: "center", my: 3}}>
                        {prompt.isDecisionScriptStep ?
                            <AccountTreeIcon /> :
                            <VideoLibraryIcon />
                        }
                        <Typography variant="h6" sx={{px: 3}} noWrap>
                            {prompt.promptName ? `${prompt.promptName}` : "Optional Prompt"}
                        </Typography>
                    </Box>
                    <Box component="div" sx={{display: "flex", alignItems: "center", mb: 1}}>
                        <CheckCircleIcon fontSize="small" />
                        <Typography variant="subtitle2" sx={{ml: 2}}>
                            {`Responses Submitted:  ${prompt.numberResponses}`}
                        </Typography>
                    </Box>
                    <Box component="div" sx={{display: "flex", alignItems: "center", mb: 1}}>
                        <RedoIcon fontSize="small" />
                        <Typography variant="subtitle2" sx={{ml: 2}}>
                            {`Skippable:  ${prompt.isSkippable ? "Yes" : "No"}`}
                        </Typography>
                    </Box>
                    {
                        prompt.isDecisionScriptStep ?
                            <Box component="div" sx={{display: "flex", alignItems: "center", mb: 1}}>
                                <BusinessIcon fontSize="small" />
                                <Typography variant="subtitle2" sx={{ml: 2}}>
                                    {`Institution Count:  ${prompt.countInstitutionChoices}`}
                                </Typography>
                            </Box> :
                            null
                    }
                    {
                        (prompt.numberReportedResponses > 0) ?
                            <Box component="div" sx={{display: "flex", alignItems: "center", mt: 2}}>
                                <ErrorIcon color="secondary" fontSize="small" />
                                <Typography variant="subtitle2" sx={{ml: 1}}>
                                    {`Unhandled Reports:  ${prompt.numberReportedResponses}`}
                                </Typography>
                            </Box> :
                            null
                    }
                </Grid>
                <Grid container spacing={2} item xs={12} sm={6} sx={{p: 2, textAlign: 'center'}}>
                    {prompt.isDecisionScriptStep ?
                        <Grid item xs={12}>
                            <Button
                                startIcon={<OpenInNewIcon />}
                                variant="contained"
                                color="primary"
                                disabled={prompt.countInstitutionChoices === 0}
                                component={Link}
                                to={`/admin/round/detail/${roundId}/optionalScriptStep/detail/${prompt.scriptStepId}`}
                            >
                                Show Details
                            </Button>
                        </Grid> :
                        <>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={`/admin/videoPrompts/detail/${prompt.videoId}`}
                                    startIcon={<OpenInNewIcon />}
                                    disabled={prompt.videoId === undefined}
                                >
                                    View Prompt
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={`/admin/round/detail/${roundId}/scriptStep/detail/${prompt.scriptStepId}`}
                                    startIcon={<KeyboardVoiceIcon />}
                                    disabled={prompt.videoId === undefined}
                                >
                                    View Responses
                                </Button>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}
