import React from "react";
import {Grid, Typography, Button, Paper, Tooltip, Box} from "@mui/material";
import {ScriptStepCardProps} from "../../data-types";
import {VideoJsPlayerOptions} from "video.js";
import {VideoPlayer} from "../../components";
import {Link} from "react-router-dom";
import {DataFormatting} from "../../util";
import {VideoPlaceholder} from "../../components";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import RedoIcon from '@mui/icons-material/Redo';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

export function ScriptStepCard({scriptStep}: ScriptStepCardProps) {

    const videoOptions: VideoJsPlayerOptions = {
        autoplay: false,
        controls: true,
        userActions: {hotkeys: true},
        sources: scriptStep.sources,
        fill: true,
        html5: {
            nativeTextTracks: false
        }
    }

    return (
        <Paper elevation={1}>
            <Grid container justifyContent="center" alignItems="center" sx={{mb: 5}}>
                    <Grid item xs={3} sm={1} sx={{textAlign: "center"}}>
                        <Typography variant="h4">
                            {scriptStep.position}
                        </Typography>
                    </Grid>
                    {scriptStep.videoUuid === '---' ?
                        <Tooltip title={"You don't have access to this video."}>
                            <Grid item xs={9} sm={3}>
                                <VideoPlaceholder>
                                    <DoDisturbIcon fontSize="large" />
                                </VideoPlaceholder>
                            </Grid>
                        </Tooltip> :
                        <Grid item xs={9} sm={3}>
                            {scriptStep.isDecisionScriptStep ?
                                <VideoPlaceholder>
                                    <DynamicFeedIcon fontSize="large" />
                                </VideoPlaceholder>
                                :
                                <VideoPlayer
                                    options={videoOptions}
                                    height={175}
                                />
                            }
                        </Grid>
                    }
                    <Grid item xs={12} sm={4}>
                        <Box component="div" sx={{display: "flex", alignItems: "center", my: 3, px: 3}}>
                            {scriptStep.isDecisionScriptStep ?
                                <AccountTreeIcon /> :
                                <VideoLibraryIcon />
                            }
                            <Typography variant="h6" sx={{px: 3}}>
                                {scriptStep.name ? `Prompt:  ${scriptStep.name}` : "Optional Prompt"}
                            </Typography>

                        </Box>
                        {!isNaN(parseInt(scriptStep.size)) ?
                            <Box component="div" sx={{display: "flex", alignItems: "center", mb: 1, px: 3}}>
                                <AccessAlarmIcon />
                                <Typography variant="subtitle2" sx={{ml: 2}}>
                                    {`Length:  ${DataFormatting.secondsToTimeString(parseInt(scriptStep.size))}`}
                                </Typography>
                            </Box> :
                            null
                        }
                        <Box component="div" sx={{display: "flex", alignItems: "center", mb: 1, px: 3}}>
                            <RedoIcon />
                            <Typography variant="subtitle2" sx={{ml: 2}}>
                                {`Skippable:  ${scriptStep.isSkippable ? "Yes" : "No"}`}
                            </Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={4} sx={{textAlign: "center", p: {xs: 3}}}>
                        <Button
                            component={Link}
                            to={`/admin/videoPrompts/detail/${scriptStep.videoId}`}
                            variant="contained"
                            color="primary"
                            startIcon={<OpenInNewIcon />}
                            disabled={scriptStep.videoUuid === '---' || scriptStep.isDecisionScriptStep}
                        >
                            View Prompt
                        </Button>
                    </Grid>
            </Grid>
        </Paper>
    )
}