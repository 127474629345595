import React from "react";
import {Video, VideoLibraryProps} from "../../data-types";
import {Container, Grid, Pagination, Stack, Typography} from "@mui/material";
import {VideoCard} from "../video-card";
import {LoadingSpinner} from "../loading-spinner";

export function VideoLibrary({videosPerPage, totalVideos, pageNumber, videos, changePage, selected, toggleSelection, loading, handleShareClick, handleDeleteClick, isSelected}: VideoLibraryProps) {

    return (
        <Container sx={{mt: 5}}>
            <Grid container spacing={2}>
                {loading ?
                    <Grid item xs={12}>
                        <LoadingSpinner />
                    </Grid> :
                    videos?.map((c, i) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} key={i}>
                                <VideoCard
                                    video={c}
                                    selected={isSelected ? isSelected(c) : false}
                                    toggleSelection={toggleSelection}
                                    handleShareClick={handleShareClick}
                                    handleDeleteClick={handleDeleteClick}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container spacing={2} sx={{mt: 3}}>
                <Grid item xs={12}>
                    <Stack alignItems="center">
                        <Pagination
                            page={pageNumber}
                            count={Math.max(1, Math.ceil(totalVideos / videosPerPage))}
                            color="primary"
                            onChange={async (_, pageNumber) => await changePage(pageNumber)}
                        />
                        <Typography variant="body1" sx={{mt: 3}}>
                            {`Video Count:  ${totalVideos}`}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    )
}