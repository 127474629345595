import {useCallback} from "react";
import {DataAccess, DataValidation} from "../../util";
export const useChangeRoles = () => {

    const inviteAdmin = useCallback(async (toInvite: string) => {
        if (!DataValidation.isValidEmailAddress(toInvite)) {
            throw new Error("Invalid email address.  Please enter a valid email address and try again.");
        }

        await DataAccess.post("/api/user/makeAdmin.json", {data: {emailToUpgrade: toInvite}});
    }, []);

    return {
        inviteAdmin: inviteAdmin
    }
}