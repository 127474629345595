import React from "react";
import {Grid} from "@mui/material";
import {OpenCompetitionResult} from "../../data-types";
import {OpenCompetitionCard} from "./OpenCompetitionCard";
import {NoDataCard} from "../../components";

interface OpenEnrollSectionProps {
    openCompetitions: OpenCompetitionResult[];
    initEnroll: (competitionId: number) => void;
}

export function OpenEnrollSection({openCompetitions, initEnroll}: OpenEnrollSectionProps) {

    return (
        <Grid container spacing={2}>
            {openCompetitions.length === 0 ?
                <Grid item xs={12}>
                    <NoDataCard message="There are no events available for enrollment." />
                </Grid> :
                null
            }
            {openCompetitions.map((c, i) =>
                <OpenCompetitionCard
                    key={i}
                    competition={c}
                    initEnroll={initEnroll}
                />
            )}
        </Grid>
    )
}