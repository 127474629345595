import React from "react";
import {Button,Modal, Grid, Box, Typography, Divider} from "@mui/material";
import {ModalProps} from "../../data-types";

export function RecordingHelpModal({open, setOpen}: ModalProps) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: "100%", sm: "100%", md: "50%"},
        maxHeight: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto'
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={style} component="div">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Video Recording Troubleshooting
                        </Typography>
                        <Divider sx={{mt: 2, mb: 2}} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Make sure to allow your browser access to your webcam and microphone
                        </Typography>
                        <Typography variant="body1" sx={{mb: 2}} >
                            This will appear in the top-left corner of the page for most users.  You need to allow both microphone AND webcam access.
                        </Typography>
                        <Typography variant="body1" sx={{mb: 2}} >
                            If you don't give your browser these permissions, then you will not be able to record video and audio.
                        </Typography>
                        <Divider sx={{mt: 2, mb: 2}} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Check which video and audio source your browser is using.
                        </Typography>
                        <Typography variant="body1" sx={{mb: 2}} >
                            Most browsers allow you to change the microphone or camera you are using when recoding on a website.
                        </Typography>
                        <Typography variant="body1" sx={{mb: 2}} >
                            In Chrome and Firefox, for example, click on the video camera icon in the search bar and adjust your settings there.  You will need to refresh the page to see your changes take affect.
                        </Typography>
                        <Divider sx={{mt: 2, mb: 2}} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{mb: 2}}>
                            Check your browser extensions
                        </Typography>
                        <Typography variant="body1" sx={{mb: 2}} >
                            Some browser extensions could interfere with video recording and playback.  Try disabling them one at a time and retrying until the recording works.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}